import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {
  mutate: { fetchPolicy: 'no-cache' },
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    refetchWritePolicy: 'overwrite',
  },
} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CSRFToken: any;
  CategoryPath: any;
  Code: any;
  GridValue: any;
  IsoCode: any;
  Locale: any;
};

export type Address = {
  __typename?: 'Address';
  phoneDetails?: Maybe<PhoneDetails>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type AddressConfiguration = {
  __typename?: 'AddressConfiguration';
  enabled?: Maybe<Scalars['Boolean']>;
  service?: Maybe<Scalars['String']>;
};

/**
 * A set of UUIDs representing a base template in Amplience
 * to be used as starting point for rendering the PDP
 */
export type AmplienceDeliveryKey = {
  __typename?: 'AmplienceDeliveryKey';
  defaultPDPTemplate?: Maybe<Scalars['String']>;
  ppcPDPTemplate?: Maybe<Scalars['String']>;
};

export type AnalyticsData = {
  __typename?: 'AnalyticsData';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Check = 'CHECK',
  Discontinued = 'DISCONTINUED',
  Unapproved = 'UNAPPROVED',
}

export type BaseProduct = {
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  url?: Maybe<Scalars['String']>;
};

export type BaseSize = {
  formattedValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Shopping bag representation */
export type Cart = {
  __typename?: 'Cart';
  adyenAmount?: Maybe<Scalars['Int']>;
  appliedOrderPromotions?: Maybe<Array<Maybe<Promotion>>>;
  assistedSale?: Maybe<Scalars['Boolean']>;
  billingAddress?: Maybe<Address>;
  code?: Maybe<Scalars['ID']>;
  customer?: Maybe<CartCustomer>;
  deliveryAddress?: Maybe<Address>;
  deliveryCost?: Maybe<Price>;
  deliveryMode?: Maybe<DeliveryMode>;
  deliveryTotalTax?: Maybe<Price>;
  entries?: Maybe<Array<Maybe<CartEntry>>>;
  externalTaxEnabled?: Maybe<Scalars['Boolean']>;
  grandTotalWGCSGross?: Maybe<Price>;
  grandTotalWGCSNet?: Maybe<Price>;
  grandTotalWGCSTax?: Maybe<Price>;
  guid?: Maybe<Scalars['ID']>;
  isGsTaxCalculated?: Maybe<Scalars['Boolean']>;
  isPayPalExpressPayment?: Maybe<Scalars['Boolean']>;
  net?: Maybe<Scalars['Boolean']>;
  orderDiscounts?: Maybe<Price>;
  potentialOrderPromotions?: Maybe<Array<Maybe<Promotion>>>;
  subTotal?: Maybe<Price>;
  totalAfterGiftCards?: Maybe<Price>;
  totalDiscounts?: Maybe<Price>;
  totalItems?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Price>;
  totalPriceNoTaxes?: Maybe<Price>;
  totalPriceWithTax?: Maybe<Price>;
  totalTax?: Maybe<Price>;
  validationStep?: Maybe<Scalars['String']>;
  voucherTotalDiscount?: Maybe<Price>;
};

/** A stripped down customer representation specifically used in the cart responses */
export type CartCustomer = {
  __typename?: 'CartCustomer';
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<CustomerType>;
  email?: Maybe<Scalars['String']>;
  loyaltySubscribed?: Maybe<Scalars['Boolean']>;
};

/** Also called order line: a representation of a product in the cart */
export type CartEntry = {
  __typename?: 'CartEntry';
  basePrice?: Maybe<Price>;
  entryNumber?: Maybe<Scalars['Int']>;
  fromPrice?: Maybe<Price>;
  gift?: Maybe<Scalars['Boolean']>;
  product?: Maybe<CartProduct>;
  quantity?: Maybe<Scalars['Int']>;
  removable?: Maybe<Scalars['Boolean']>;
  taxAmount?: Maybe<Price>;
  totalPrice?: Maybe<Price>;
  totalPriceWithoutDiscount?: Maybe<Price>;
  updateable?: Maybe<Scalars['Boolean']>;
};

/** Properties describing the modifications done to a cart entry */
export type CartModification = {
  __typename?: 'CartModification';
  entry?: Maybe<CartEntry>;
  quantity?: Maybe<Scalars['Int']>;
  quantityAdded?: Maybe<Scalars['Int']>;
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
};

/** Not a Graphql Mutation query, but an object containing mutation information from the changes in the cart */
export type CartMutation = {
  __typename?: 'CartMutation';
  cart?: Maybe<Cart>;
  cartModifications?: Maybe<Array<Maybe<CartModification>>>;
  type?: Maybe<Scalars['String']>;
};

export type CartProduct = BaseProduct & {
  __typename?: 'CartProduct';
  baseProduct?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Code']>;
  color?: Maybe<Color>;
  ean?: Maybe<Scalars['String']>;
  fitEN?: Maybe<Scalars['String']>;
  fromPrice?: Maybe<Price>;
  isGiftCard?: Maybe<Scalars['Boolean']>;
  mainColor?: Maybe<Color>;
  materialGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  oneSize?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Price>;
  programEN?: Maybe<Scalars['String']>;
  shouldUseDarkBackgroundImgs?: Maybe<Scalars['Boolean']>;
  simplifiedImages?: Maybe<Array<Maybe<ImageEntry>>>;
  size?: Maybe<Size>;
  staticCategoryPath?: Maybe<Scalars['String']>;
  staticCategoryPathIds?: Maybe<Scalars['String']>;
  stockNotificationMessageEN?: Maybe<Scalars['String']>;
  styleFamilyEN?: Maybe<Scalars['String']>;
  subTargetAudience?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CategoryDetails = {
  __typename?: 'CategoryDetails';
  algoliaIndexName?: Maybe<Scalars['String']>;
  algoliaQueryId?: Maybe<Scalars['String']>;
  canonicalUrl?: Maybe<Scalars['String']>;
  categoryCode?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  clearAllUrl?: Maybe<Scalars['String']>;
  h1?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageTitle?: Maybe<Scalars['String']>;
  plpAppUrl?: Maybe<Scalars['String']>;
  plpCategoryPath?: Maybe<Scalars['String']>;
  plpCategoryPathIds?: Maybe<Scalars['String']>;
  requestUrl?: Maybe<Scalars['String']>;
  selectedFacets?: Maybe<Array<Maybe<VisibleFacet>>>;
  sorting?: Maybe<Array<Maybe<CategorySorting>>>;
  visibleFacets?: Maybe<Array<Maybe<VisibleFacet>>>;
};

export type CategoryPaths = {
  __typename?: 'CategoryPaths';
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CategorySorting = {
  __typename?: 'CategorySorting';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  description?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  facetColorStyle?: Maybe<Scalars['String']>;
};

export type CommonData = {
  __typename?: 'CommonData';
  accountType?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  ampliencePreviewTime?: Maybe<Scalars['String']>;
  amplienceTSP?: Maybe<Scalars['String']>;
  amplienceVSE?: Maybe<Scalars['String']>;
  asmInstoreLocale?: Maybe<Scalars['String']>;
  assistedSale?: Maybe<Scalars['Boolean']>;
  assistedSaleUserID?: Maybe<Scalars['String']>;
  assistedSaleUserType?: Maybe<Scalars['String']>;
  correlationId?: Maybe<Scalars['String']>;
  csrfToken?: Maybe<Scalars['CSRFToken']>;
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  gsOrigin?: Maybe<Scalars['String']>;
  hashedIp?: Maybe<Scalars['String']>;
  hashedUserId?: Maybe<Scalars['String']>;
  /** True when user is an internal/employee user and should be opted out from analytics reports */
  internal?: Maybe<Scalars['Boolean']>;
  isAmpliencePreview?: Maybe<Scalars['Boolean']>;
  loggedInStatus?: Maybe<Scalars['Boolean']>;
  msdAbTestingEnabled?: Maybe<Scalars['Boolean']>;
  newsletterUser?: Maybe<Scalars['Boolean']>;
  numberOfWishListItems?: Maybe<Scalars['Int']>;
  thresholdCustomerType?: Maybe<Scalars['String']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  activateNewMyAccount?: Maybe<Scalars['Boolean']>;
  activeApp?: Maybe<Scalars['Boolean']>;
  addressAutoCompleteConfiguration?: Maybe<AddressConfiguration>;
  addressSuggestionConfiguration?: Maybe<AddressConfiguration>;
  adyenUseSavedPayment?: Maybe<Scalars['Boolean']>;
  adyenWebDropinEnabled?: Maybe<Scalars['Boolean']>;
  agreeToPrivacyVisibleOnLogin?: Maybe<Scalars['Boolean']>;
  agreeToPrivacyVisibleOnLoginCheckout?: Maybe<Scalars['Boolean']>;
  agreeToPrivacyVisibleOnRegistration?: Maybe<Scalars['Boolean']>;
  algoliaAppId?: Maybe<Scalars['String']>;
  algoliaInsightsApiKey?: Maybe<Scalars['String']>;
  algoliaProductPrimaryIndexName?: Maybe<Scalars['String']>;
  allowGiftCards?: Maybe<Scalars['Boolean']>;
  appStoreId?: Maybe<Scalars['String']>;
  appStoreName?: Maybe<Scalars['String']>;
  applePayEnabled?: Maybe<Scalars['Boolean']>;
  applePaySessionTimeout?: Maybe<Scalars['Int']>;
  applePayVersion?: Maybe<Scalars['Int']>;
  captchaInvisibleSiteKey?: Maybe<Scalars['String']>;
  captchaSiteKey?: Maybe<Scalars['String']>;
  checkoutV2?: Maybe<Scalars['Boolean']>;
  colorStyles?: Maybe<Array<FacetColorStyleItem>>;
  cookieWallVersion?: Maybe<CookieWallVersion>;
  currentCurrencyDigits?: Maybe<Scalars['Int']>;
  currentCurrencyIsoCode?: Maybe<Scalars['String']>;
  currentCurrencyNumberFormat?: Maybe<Scalars['String']>;
  currentCurrencySymbol?: Maybe<Scalars['String']>;
  debounceTimeout?: Maybe<Scalars['Int']>;
  defaultCurrency?: Maybe<Scalars['String']>;
  displayFiberRankingBadgeOnPDP?: Maybe<Scalars['Boolean']>;
  displayFiberRankingInfoOnPDP?: Maybe<Scalars['Boolean']>;
  displayFiberRankingOnProductTiles?: Maybe<Scalars['Boolean']>;
  emptyPageLayoutSlotDeliveryKey?: Maybe<Scalars['String']>;
  enableAudioEye?: Maybe<Scalars['Boolean']>;
  enableBodyOnlyWebview?: Maybe<Scalars['Boolean']>;
  enableCLTAnalytics?: Maybe<Scalars['Boolean']>;
  enableCartItemsNotReservedMessage?: Maybe<Scalars['Boolean']>;
  enableCookieSettings?: Maybe<Scalars['Boolean']>;
  enableCookieWall?: Maybe<Scalars['Boolean']>;
  enableCookieWallV2?: Maybe<Scalars['Boolean']>;
  enableDarkBgImages?: Maybe<Scalars['Boolean']>;
  enableExponea?: Maybe<Scalars['Boolean']>;
  enableFitAnalytics?: Maybe<Scalars['Boolean']>;
  enableHuman?: Maybe<Scalars['Boolean']>;
  enableKidswearNewsletter?: Maybe<Scalars['Boolean']>;
  enableNewsletterSubscrOverlayABTest?: Maybe<Scalars['Boolean']>;
  enablePDPBreadcrumbs?: Maybe<Scalars['Boolean']>;
  enablePLPLoadMore?: Maybe<Scalars['Boolean']>;
  enablePdpChargedReturnsMessage?: Maybe<Scalars['Boolean']>;
  enablePdpClosestStoreDeliveryOption?: Maybe<Scalars['Boolean']>;
  enablePdpDeliveryOptions?: Maybe<Scalars['Boolean']>;
  enablePdpFreeReturnsMessage?: Maybe<Scalars['Boolean']>;
  enablePdpLocalStoreStockSizeAvailability?: Maybe<Scalars['Boolean']>;
  enablePdpShippingEstimate?: Maybe<Scalars['Boolean']>;
  enablePdpShippingEstimateDisclaimer?: Maybe<Scalars['Boolean']>;
  enableQubit?: Maybe<Scalars['Boolean']>;
  enableQubitPDC?: Maybe<Scalars['Boolean']>;
  enableReturnFeeMDDC?: Maybe<Scalars['Boolean']>;
  enableSizeGuideProductMeasurements?: Maybe<Scalars['Boolean']>;
  enableUPSMyChoiceSite?: Maybe<Scalars['Boolean']>;
  enableUniversalLinks?: Maybe<Scalars['Boolean']>;
  enableUspScroll?: Maybe<Scalars['Boolean']>;
  enableWishlist?: Maybe<Scalars['Boolean']>;
  errorPageLayoutSlotDeliveryKey?: Maybe<Scalars['String']>;
  exponeaPushId?: Maybe<Scalars['String']>;
  exponeaPushNotificationDelay?: Maybe<Scalars['Int']>;
  exponeaPushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  exponeaPushNotificationPageViewNumber?: Maybe<Scalars['Int']>;
  exponeaPushNotificationRequestPerSession?: Maybe<Scalars['Int']>;
  exponeaSpaBanners?: Maybe<Scalars['Boolean']>;
  exponeaSpaOnHashChange?: Maybe<Scalars['Boolean']>;
  exponeaSpaTags?: Maybe<Scalars['Boolean']>;
  exponeaSubDomain?: Maybe<Scalars['String']>;
  exponeaTagExecution?: Maybe<Scalars['String']>;
  exponeaTokenId?: Maybe<Scalars['String']>;
  externalTaxEnabled?: Maybe<Scalars['Boolean']>;
  facetDisplayDataList?: Maybe<Array<Maybe<FacetDisplayDataList>>>;
  fiberRanking?: Maybe<Scalars['Boolean']>;
  forterSiteId?: Maybe<Scalars['String']>;
  giftCardGroupLength?: Maybe<Scalars['Int']>;
  giftCardGroupSeparator?: Maybe<Scalars['String']>;
  giftCardPrefixes?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasDoubleNewsletterOptIn?: Maybe<Scalars['Boolean']>;
  hasImplicitTermsAndConditions?: Maybe<Scalars['Boolean']>;
  hasMyAccount?: Maybe<Scalars['Boolean']>;
  homepageLayoutSlotDeliveryKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isBodyOnlyView?: Maybe<Scalars['Boolean']>;
  isChangeCountryInBillingAddress?: Maybe<Scalars['Boolean']>;
  isEnableQrCode?: Maybe<Scalars['Boolean']>;
  isEnableShareCartEmail?: Maybe<Scalars['Boolean']>;
  isLineSession?: Maybe<Scalars['Boolean']>;
  klarnaHighOnlineReturnsSegment?: Maybe<Scalars['String']>;
  localeFallback?: Maybe<Scalars['String']>;
  lowestPriceDisplay?: Maybe<LowestPriceDisplay>;
  loyaltyPointSystem?: Maybe<Scalars['String']>;
  masterLayoutSlotDeliveryKey?: Maybe<Scalars['String']>;
  myAccountPointHistoryCount?: Maybe<Scalars['Int']>;
  navigationType?: Maybe<NavigationType>;
  net?: Maybe<Scalars['Boolean']>;
  payPalExpressEnabled?: Maybe<Scalars['Boolean']>;
  paymentMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  plpLayoutSlotDeliveryKey?: Maybe<Scalars['String']>;
  plpSystemTextsDeliveryKey?: Maybe<Scalars['String']>;
  plpV2?: Maybe<Scalars['Boolean']>;
  productBasePriceColor?: Maybe<Scalars['String']>;
  productTileConfiguration?: Maybe<ProductTileConfiguration>;
  qubitEventsEnabled?: Maybe<Scalars['Boolean']>;
  qubitSmartServeUrl?: Maybe<Scalars['String']>;
  qubitTagExecution?: Maybe<Scalars['String']>;
  qubitTrackingId?: Maybe<Scalars['String']>;
  quickViewOverlayDeliveryKey?: Maybe<Scalars['String']>;
  repoId?: Maybe<Scalars['String']>;
  requestPdpUspsSFCSizeLevel?: Maybe<Scalars['Boolean']>;
  salesDiscountsEndDate?: Maybe<Scalars['String']>;
  searchV2?: Maybe<Scalars['Boolean']>;
  shopType?: Maybe<Scalars['String']>;
  showLowestPriceText?: Maybe<Scalars['Boolean']>;
  showMessageBoxNotifications?: Maybe<Scalars['Boolean']>;
  showStrikethroughPrices?: Maybe<Scalars['Boolean']>;
  siteHasPhysicalStores?: Maybe<Scalars['Boolean']>;
  siteNameDisplay?: Maybe<Scalars['String']>;
  sizeGuideUnit?: Maybe<SizeGuideUnit>;
  slice?: Maybe<Scalars['String']>;
  sortingOptions?: Maybe<Array<SortingOptions>>;
  srpLayoutSlotDeliveryKey?: Maybe<Scalars['String']>;
  staticFiltersQuery?: Maybe<Scalars['String']>;
  supportedFitType?: Maybe<SupportedFitType>;
  upsMyChoiceDefaultChecked?: Maybe<Scalars['Boolean']>;
  verticalFacetNavigation?: Maybe<Scalars['Boolean']>;
  voucherFormDefaultShown?: Maybe<Scalars['Boolean']>;
};

export enum CookieWallVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

/** Object describing a country's basic information including its configured languages */
export type Country = {
  __typename?: 'Country';
  addressStreetFormat?: Maybe<Scalars['String']>;
  isocode: Scalars['IsoCode'];
  name: Scalars['String'];
  phoneCode?: Maybe<Scalars['String']>;
  supportedLanguages?: Maybe<Array<Maybe<Language>>>;
};

export type Currency = {
  __typename?: 'Currency';
  active?: Maybe<Scalars['Boolean']>;
  digits?: Maybe<Scalars['Int']>;
  isocode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export enum CustomerType {
  Anonymous = 'ANONYMOUS',
  Guest = 'GUEST',
  Newsletter = 'NEWSLETTER',
  Pending = 'PENDING',
  Registered = 'REGISTERED',
  SocialFriend = 'SOCIAL_FRIEND',
}

export type DeliveryMode = {
  __typename?: 'DeliveryMode';
  code?: Maybe<Scalars['String']>;
  deliveryCost?: Maybe<Price>;
  deliveryMethod?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  omsDeliveryMethod?: Maybe<Scalars['String']>;
  requestCarrierCustomerId?: Maybe<Scalars['Boolean']>;
  shipper?: Maybe<Scalars['String']>;
  transitDays?: Maybe<TransitDays>;
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  deliveryCost?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DeliveryOptions = {
  __typename?: 'DeliveryOptions';
  deliveryOptions?: Maybe<Array<Maybe<DeliveryOption>>>;
};

export enum EntryPoint {
  CreateAccountOffline = 'CREATE_ACCOUNT_OFFLINE',
  CreateAccountOnline = 'CREATE_ACCOUNT_ONLINE',
  Footer = 'FOOTER',
  Myaccount = 'MYACCOUNT',
  NewsletterPopup = 'NEWSLETTER_POPUP',
  NewsletterPreferences = 'NEWSLETTER_PREFERENCES',
  NewsletterReply = 'NEWSLETTER_REPLY',
  ProductComingSoon = 'PRODUCT_COMING_SOON',
  SubscriptionComponent = 'SUBSCRIPTION_COMPONENT',
  VariantBackInStock = 'VARIANT_BACK_IN_STOCK',
}

export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  message: Scalars['String'];
};

export type ExplodingView = {
  __typename?: 'ExplodingView';
  explodingViewDescription?: Maybe<Scalars['String']>;
  explodingViewSvgUrl?: Maybe<Scalars['String']>;
  explodingViewTitle?: Maybe<Scalars['String']>;
};

export enum ExponeaCategoryAttribute {
  Webpush = 'webpush',
}

export enum ExponeaConsentAction {
  Accept = 'accept',
  Reject = 'reject',
}

export type ExponeaCustomerAttributes = {
  __typename?: 'ExponeaCustomerAttributes';
  success?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Boolean']>;
};

export type ExponeaCustomerAttributesResponse = {
  __typename?: 'ExponeaCustomerAttributesResponse';
  results?: Maybe<Array<Maybe<ExponeaCustomerAttributes>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ExponeaTypeAttribute {
  Consent = 'consent',
}

export type FacetColorStyleItem = {
  __typename?: 'FacetColorStyleItem';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FacetDisplayDataList = {
  __typename?: 'FacetDisplayDataList';
  code?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  translated?: Maybe<Scalars['Boolean']>;
};

export type FacetValue = {
  __typename?: 'FacetValue';
  code?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  defaultValue?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type Factory = {
  __typename?: 'Factory';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Fiber = {
  __typename?: 'Fiber';
  code?: Maybe<Scalars['String']>;
  descriptionLong?: Maybe<Scalars['String']>;
  descriptionShort?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type FiberRanking = {
  __typename?: 'FiberRanking';
  fiberRanking?: Maybe<FiberRankingData>;
  productAudience?: Maybe<Scalars['String']>;
};

export type FiberRankingData = {
  __typename?: 'FiberRankingData';
  genericFiberRankingData?: Maybe<GenericFibers>;
  productFiberRankingData?: Maybe<ProductFibers>;
};

export enum FormType {
  GuestForm = 'GUEST_FORM',
  HomeBillingForm = 'HOME_BILLING_FORM',
  HomeShippingForm = 'HOME_SHIPPING_FORM',
  LoginForm = 'LOGIN_FORM',
  PasswordResetRequestForm = 'PASSWORD_RESET_REQUEST_FORM',
  PickupBillingForm = 'PICKUP_BILLING_FORM',
  PickupShippingForm = 'PICKUP_SHIPPING_FORM',
}

export type GenericFibers = {
  __typename?: 'GenericFibers';
  fibers?: Maybe<Array<Maybe<Fiber>>>;
};

export type GroupedSize = {
  __typename?: 'GroupedSize';
  formattedValue?: Maybe<Scalars['String']>;
  purchasable?: Maybe<Scalars['Boolean']>;
  sizes?: Maybe<Array<Maybe<Size>>>;
  value?: Maybe<Scalars['String']>;
};

export type Image = {
  url?: Maybe<Scalars['String']>;
};

export type ImageData = Image & {
  __typename?: 'ImageData';
  altText?: Maybe<Scalars['String']>;
  base64BackgroundImage?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageEntry = {
  __typename?: 'ImageEntry';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<ImageData>;
};

export type Language = {
  __typename?: 'Language';
  active?: Maybe<Scalars['Boolean']>;
  isocode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
};

export enum LinkTarget {
  Newwindow = 'NEWWINDOW',
  Samewindow = 'SAMEWINDOW',
}

export enum LowestPriceDisplay {
  Always = 'ALWAYS',
  AlwaysDiscountsOnly = 'ALWAYS_DISCOUNTS_ONLY',
  DiscountsOnly = 'DISCOUNTS_ONLY',
  Never = 'NEVER',
  OnlyLowestPrice = 'ONLY_LOWEST_PRICE',
}

export type Mannequin = {
  __typename?: 'Mannequin';
  additionalSizeInfo?: Maybe<Scalars['String']>;
  imperialMessage?: Maybe<Scalars['String']>;
  metricMessage?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutate the cart by adding a product */
  addToCart?: Maybe<CartMutation>;
  /** Mutate the wishlist by adding a product */
  addToWishlist?: Maybe<Scalars['Boolean']>;
  /** Apply voucher from the query params if present */
  applyVoucher?: Maybe<VoucherApplyResult>;
  /** Set exponea push notification consent action */
  exponeaConsentEvent?: Maybe<Scalars['String']>;
  /** Set exponea push notification customer attributes */
  exponeaCustomerAttributes?: Maybe<ExponeaCustomerAttributesResponse>;
  /** Mutate the cart by removing a product by cart entry number */
  removeFromCart?: Maybe<CartMutation>;
  /** Mutate the cart by removing a product by cart entry number */
  removeFromWishlist?: Maybe<Scalars['Boolean']>;
};

export type MutationAddToCartArgs = {
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type MutationAddToWishlistArgs = {
  code: Scalars['Code'];
  csrfToken: Scalars['CSRFToken'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type MutationApplyVoucherArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
  utmSource?: InputMaybe<Scalars['String']>;
  voucher: Scalars['String'];
};

export type MutationExponeaConsentEventArgs = {
  action: ExponeaConsentAction;
  cookie: Scalars['String'];
  locale: Scalars['Locale'];
  message: Scalars['String'];
  siteType: SiteType;
  subscriberKey?: InputMaybe<Scalars['String']>;
};

export type MutationExponeaCustomerAttributesArgs = {
  category: ExponeaCategoryAttribute;
  cookie: Scalars['String'];
  locale: Scalars['Locale'];
  siteType: SiteType;
  subscriberKey?: InputMaybe<Scalars['String']>;
  type: ExponeaTypeAttribute;
};

export type MutationRemoveFromCartArgs = {
  entryNumber: Scalars['Int'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type MutationRemoveFromWishlistArgs = {
  code: Scalars['Code'];
  csrfToken: Scalars['CSRFToken'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type Navigation = {
  __typename?: 'Navigation';
  id?: Maybe<Scalars['ID']>;
  navigation?: Maybe<Array<Maybe<NavigationItem>>>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  children?: Maybe<Array<Maybe<NavigationItem>>>;
  hideForLoggedInVisitors?: Maybe<Scalars['Boolean']>;
  hideForNonLoggedInVisitors?: Maybe<Scalars['Boolean']>;
  highlightWhenDuplicates?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  labelAria?: Maybe<Scalars['String']>;
  styleModifier?: Maybe<Scalars['String']>;
  target?: Maybe<LinkTarget>;
  uid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum NavigationType {
  HorizontalMainNavigationFlyOut = 'HORIZONTAL_MAIN_NAVIGATION_FLY_OUT',
  LeftMainNavigation = 'LEFT_MAIN_NAVIGATION',
}

export enum NavigationTypeParam {
  FlyoutMyAccountNavigation = 'flyoutMyAccountNavigation',
  FlyoutNavigation = 'flyoutNavigation',
  FooterNavigation = 'footerNavigation',
  InpageNavigation = 'inpageNavigation',
  LeftNavigation = 'leftNavigation',
  OutletFlyoutNavigation = 'outletFlyoutNavigation',
}

export enum NewsletterSubscribeSuccessType {
  ExistingCustomer = 'EXISTING_CUSTOMER',
  NewCustomer = 'NEW_CUSTOMER',
}

/** A page of visible products */
export type Page = {
  __typename?: 'Page';
  currentPage?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<PagedProduct>>>;
  totalPageCount?: Maybe<Scalars['Int']>;
  totalProductCount?: Maybe<Scalars['Int']>;
};

export type PagedProduct = {
  __typename?: 'PagedProduct';
  code: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  code?: Maybe<Scalars['String']>;
  imageCssClass?: Maybe<Scalars['String']>;
  imageFooterCssClass?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<LinkTarget>;
  url?: Maybe<Scalars['String']>;
};

export type PersonalisedDiscount = {
  __typename?: 'PersonalisedDiscount';
  applicableToDiscountedProducts?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['String']>;
  formattedValue?: Maybe<Scalars['String']>;
  trafficSource?: Maybe<Scalars['String']>;
};

export type PhoneDetails = {
  __typename?: 'PhoneDetails';
  countryCodeIso?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type PostalCode = {
  __typename?: 'PostalCode';
  description?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type PostalCodeRange = {
  __typename?: 'PostalCodeRange';
  description?: Maybe<Scalars['String']>;
  postalCodeRangeHigh?: Maybe<Scalars['Int']>;
  postalCodeRangeLow?: Maybe<Scalars['Int']>;
};

/** Price representation */
export type Price = {
  __typename?: 'Price';
  currencyIso?: Maybe<Scalars['String']>;
  formattedValue?: Maybe<Scalars['String']>;
  formattedValueWithoutCurrency?: Maybe<Scalars['String']>;
  nrDigits?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type Product = BaseProduct & {
  __typename?: 'Product';
  algoliaObjectId?: Maybe<Scalars['String']>;
  analyticsCategory?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  availableStyleVariantsSorted?: Maybe<Array<Maybe<StyleVariant>>>;
  baseProduct?: Maybe<Scalars['String']>;
  baseProductStockNotificationMessageEN?: Maybe<Scalars['String']>;
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  canonicalUrl?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Code']>;
  color?: Maybe<Color>;
  colorFinishDescription?: Maybe<Scalars['String']>;
  colorFinishTitle?: Maybe<Scalars['String']>;
  colorPLPUrl?: Maybe<Scalars['String']>;
  comingSoon?: Maybe<Scalars['Boolean']>;
  defaultPDPTemplate?: Maybe<Scalars['String']>;
  deliveryOptions?: Maybe<DeliveryOptions>;
  detailsBullets?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsTitle?: Maybe<Scalars['String']>;
  dimensionsBullets?: Maybe<Array<Maybe<Scalars['String']>>>;
  ean?: Maybe<Scalars['String']>;
  explodingView?: Maybe<ExplodingView>;
  fabricBullets?: Maybe<Array<Maybe<Scalars['String']>>>;
  fabricDescription?: Maybe<Scalars['String']>;
  fabricHeader?: Maybe<Scalars['String']>;
  fabricTitle?: Maybe<Scalars['String']>;
  factoryId?: Maybe<Scalars['String']>;
  featuresIntro?: Maybe<Scalars['String']>;
  featuresTitle?: Maybe<Scalars['String']>;
  fiberRanking?: Maybe<FiberRanking>;
  fit?: Maybe<Scalars['String']>;
  fitEN?: Maybe<Scalars['String']>;
  formattedLowestPrice?: Maybe<Scalars['String']>;
  fromPrice?: Maybe<Price>;
  hasLocalStoreStock?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  intermediaryPrice?: Maybe<Price>;
  isGiftCard?: Maybe<Scalars['Boolean']>;
  isProductHasReview?: Maybe<Scalars['Boolean']>;
  mainColor?: Maybe<Color>;
  mannequin?: Maybe<Mannequin>;
  materialGroup?: Maybe<Scalars['String']>;
  mccValue?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  oneSize?: Maybe<Scalars['Boolean']>;
  ppcPDPTemplate?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  programDescription?: Maybe<Scalars['String']>;
  programEN?: Maybe<Scalars['String']>;
  programTitle?: Maybe<Scalars['String']>;
  purchasable?: Maybe<Scalars['Boolean']>;
  returnFee?: Maybe<ReturnFee>;
  salesDiscountsEndDate?: Maybe<Scalars['String']>;
  seoName?: Maybe<Scalars['String']>;
  shouldRequestSizeMeasurements?: Maybe<Scalars['Boolean']>;
  shouldUseDarkBackgroundImgs?: Maybe<Scalars['Boolean']>;
  showStoreStockLocator?: Maybe<Scalars['Boolean']>;
  signings?: Maybe<Array<Maybe<Signing>>>;
  simplifiedImages?: Maybe<SimplifiedImageEntry>;
  size?: Maybe<VariantSize>;
  sizeChartUrl?: Maybe<Scalars['String']>;
  sizeInformation?: Maybe<SizeInformation>;
  staticCategoryPath?: Maybe<Scalars['String']>;
  staticCategoryPathIds?: Maybe<Scalars['String']>;
  staticCategoryPathSeo?: Maybe<Scalars['String']>;
  stockInformation?: Maybe<StockLevel>;
  stockNotificationLink?: Maybe<Scalars['String']>;
  stockNotificationMessageEN?: Maybe<Scalars['String']>;
  styleDescription?: Maybe<Scalars['String']>;
  styleFamilyEN?: Maybe<Scalars['String']>;
  subTargetAudience?: Maybe<Scalars['String']>;
  supplierReference?: Maybe<Scalars['String']>;
  sustainability?: Maybe<Sustainability>;
  traceability?: Maybe<Traceability>;
  url?: Maybe<Scalars['String']>;
  useExternalUrl?: Maybe<Scalars['Boolean']>;
  videos?: Maybe<Array<Maybe<Video>>>;
  washingLabels?: Maybe<Array<Maybe<WashingLabel>>>;
};

export type ProductDynamicData = {
  __typename?: 'ProductDynamicData';
  comingSoon?: Maybe<Scalars['Boolean']>;
  formattedLowestPrice?: Maybe<Scalars['String']>;
  fromPrice?: Maybe<Price>;
  intermediaryPrice?: Maybe<Price>;
  price?: Maybe<Price>;
  purchasable?: Maybe<Scalars['Boolean']>;
  showStoreStockLocator?: Maybe<Scalars['Boolean']>;
  sizeInformation?: Maybe<SizeInformation>;
  stockInformation?: Maybe<StockLevel>;
};

export type ProductFibers = {
  __typename?: 'ProductFibers';
  fibers?: Maybe<Array<Maybe<Fiber>>>;
  ranking?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

/**
 * Configuration flags concerning layout elements on a product tile.
 * A product tile is a simple visual representation of a product's main information
 * used in carousels and on the Product Lister Page
 */
export type ProductTileConfiguration = {
  __typename?: 'ProductTileConfiguration';
  showHoverImageOnPlp?: Maybe<Scalars['Boolean']>;
  showHoverImageOnRecommendations?: Maybe<Scalars['Boolean']>;
  showHoverImageOnRvp?: Maybe<Scalars['Boolean']>;
  showQuickViewOnPlp?: Maybe<Scalars['Boolean']>;
  showQuickViewOnRecommendations?: Maybe<Scalars['Boolean']>;
  showQuickViewOnRvp?: Maybe<Scalars['Boolean']>;
  showStyleVariantsOnPlp?: Maybe<Scalars['Boolean']>;
  showStyleVariantsOnRecommendations?: Maybe<Scalars['Boolean']>;
  showStyleVariantsOnRvp?: Maybe<Scalars['Boolean']>;
};

export type ProductsInput = {
  codes?: InputMaybe<Array<Scalars['Code']>>;
  locale: Scalars['Locale'];
  siteType?: InputMaybe<SiteType>;
};

export type ProductsProduct = BaseProduct & {
  __typename?: 'ProductsProduct';
  algoliaObjectId?: Maybe<Scalars['String']>;
  availableStyleVariantsSorted?: Maybe<Array<Maybe<StyleVariant>>>;
  baseProduct?: Maybe<Scalars['String']>;
  baseProductStockNotificationMessageEN?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Color>;
  ean?: Maybe<Scalars['String']>;
  featuresIntro?: Maybe<Scalars['String']>;
  fiberRanking?: Maybe<FiberRanking>;
  fitEN?: Maybe<Scalars['String']>;
  formattedLowestPrice?: Maybe<Scalars['String']>;
  fromPrice?: Maybe<Price>;
  intermediaryPrice?: Maybe<Price>;
  isGiftCard?: Maybe<Scalars['Boolean']>;
  mainColor?: Maybe<Color>;
  materialGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  oneSize?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Price>;
  programEN?: Maybe<Scalars['String']>;
  signings?: Maybe<Array<Maybe<Signing>>>;
  simplifiedImages?: Maybe<SimplifiedImageEntry>;
  size?: Maybe<Size>;
  sizeInformation?: Maybe<SizeInformation>;
  staticCategoryPath?: Maybe<Scalars['String']>;
  staticCategoryPathIds?: Maybe<Scalars['String']>;
  styleFamilyEN?: Maybe<Scalars['String']>;
  subTargetAudience?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  description?: Maybe<Scalars['String']>;
  discountPrice?: Maybe<Price>;
  promotion?: Maybe<PromotionDetails>;
};

export type PromotionDetails = {
  __typename?: 'PromotionDetails';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  promotionType?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Query the Amplience Delivery Key for a specific product */
  amplienceDeliveryKey?: Maybe<AmplienceDeliveryKey>;
  /** Query JSESSIONID based cart information */
  cart?: Maybe<Cart>;
  /** Query categoryDetails for PLP page */
  categoryDetails: CategoryDetails;
  /** Query categoryPaths for PLP pre-rendering */
  categoryPaths: CategoryPaths;
  /** Query userInfo common data */
  commonData: CommonData;
  /** Query global configuration for the base site */
  configuration: Configuration;
  /** Query a list of available countries */
  countries: Array<Maybe<Country>>;
  /** Query specific country information */
  country: Country;
  localStoreStock?: Maybe<Array<Maybe<Store>>>;
  /** Query navigation information */
  navigation?: Maybe<Navigation>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethods>>>;
  /** Query product data by supplying a base site id and a product code */
  product: Product;
  /** Query stateful up-to-date product data being information about price, signings and available sizes */
  productDynamicData: ProductDynamicData;
  /** Query product data for multiple product codes */
  products?: Maybe<Array<Maybe<ProductsProduct>>>;
  /** Query product recommendations */
  recommendations?: Maybe<Recommendations>;
  /** Query shipping estimates */
  shippingEstimates: ShippingEstimates;
  /** Query size guides v1 data by supplying a base site id */
  sizeGuides: SizeGuides;
  /** Query size guide Japanese data by supplying a base site id and a product code */
  sizeMeasurements: SizeMeasurements;
  socialMediaChannels?: Maybe<Array<Maybe<SocialMediaChannel>>>;
  /** Query JSESSIONID based user information */
  user?: Maybe<User>;
  /** Query unique USPs for a product */
  usps?: Maybe<Usp>;
  /** Dynamic form validation rules */
  validationRules?: Maybe<Validations>;
  /** Query product data for a base site */
  visibleProducts?: Maybe<Page>;
  /** Query JSESSIONID based wishlist information */
  wishlist?: Maybe<Wishlist>;
};

export type QueryAmplienceDeliveryKeyArgs = {
  code: Scalars['Code'];
  siteType: SiteType;
};

export type QueryCartArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryCategoryDetailsArgs = {
  locale: Scalars['Locale'];
  path: Scalars['CategoryPath'];
  siteType: SiteType;
};

export type QueryCategoryPathsArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryCommonDataArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryConfigurationArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryCountriesArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryCountryArgs = {
  isocode: Scalars['IsoCode'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryLocalStoreStockArgs = {
  code: Scalars['Code'];
  isocode: Scalars['IsoCode'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryNavigationArgs = {
  locale: Scalars['Locale'];
  navigationType: NavigationTypeParam;
  siteType: SiteType;
};

export type QueryPaymentMethodsArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryProductArgs = {
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryProductDynamicDataArgs = {
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryProductsArgs = {
  input?: InputMaybe<ProductsInput>;
};

export type QueryRecommendationsArgs = {
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryShippingEstimatesArgs = {
  code: Scalars['Code'];
  gridValue1?: InputMaybe<Scalars['GridValue']>;
  gridValue2?: InputMaybe<Scalars['GridValue']>;
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QuerySizeGuidesArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QuerySizeMeasurementsArgs = {
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QuerySocialMediaChannelsArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryUserArgs = {
  siteType: SiteType;
};

export type QueryUspsArgs = {
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryValidationRulesArgs = {
  formType?: InputMaybe<FormType>;
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type QueryVisibleProductsArgs = {
  locale: Scalars['Locale'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  siteType: SiteType;
};

export type QueryWishlistArgs = {
  locale: Scalars['Locale'];
  siteType: SiteType;
};

export type Recommendations = {
  __typename?: 'Recommendations';
  analytics?: Maybe<Array<Maybe<AnalyticsData>>>;
  productCodes?: Maybe<Scalars['String']>;
  recommendedProducts?: Maybe<RecommendedProducts>;
};

export type RecommendedProduct = BaseProduct & {
  __typename?: 'RecommendedProduct';
  baseProduct?: Maybe<Scalars['String']>;
  code: Scalars['Code'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  url?: Maybe<Scalars['String']>;
};

export type RecommendedProducts = {
  __typename?: 'RecommendedProducts';
  maxMobileProducts?: Maybe<Scalars['Int']>;
  maxProducts?: Maybe<Scalars['Int']>;
  maxTabletProducts?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<RecommendedProduct>>>;
  title?: Maybe<Scalars['String']>;
};

export type ReturnFee = {
  __typename?: 'ReturnFee';
  amount?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  returnType?: Maybe<ReturnFeeType>;
};

export enum ReturnFeeType {
  CategoryChargedReturns = 'categoryChargedReturns',
  ChargedReturns = 'chargedReturns',
  FreeReturns = 'freeReturns',
}

export type ShippingEstimates = {
  __typename?: 'ShippingEstimates';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Signing = {
  __typename?: 'Signing';
  code?: Maybe<Scalars['String']>;
  discountSigning?: Maybe<Scalars['Boolean']>;
  image?: Maybe<SigningImage>;
  label?: Maybe<Scalars['String']>;
  labelCssClasses?: Maybe<Scalars['String']>;
  labelHtmlSafe?: Maybe<Scalars['String']>;
  plpPositionClass?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  productSigningBackgroundColour?: Maybe<Scalars['String']>;
  productSigningTextColour?: Maybe<Scalars['String']>;
  renderAsText?: Maybe<Scalars['Boolean']>;
  showOnPdp?: Maybe<Scalars['Boolean']>;
  showOnPlp?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type SigningImage = Image & {
  __typename?: 'SigningImage';
  url?: Maybe<Scalars['String']>;
};

export type SimplifiedImageEntry = {
  __typename?: 'SimplifiedImageEntry';
  entry?: Maybe<Array<Maybe<ImageEntry>>>;
};

export enum SiteType {
  Aaf = 'aaf',
  Employeeshop = 'employeeshop',
  Gstar = 'gstar',
  Outlet = 'outlet',
}

export type Size = BaseSize & {
  __typename?: 'Size';
  code?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  formattedValue?: Maybe<Scalars['String']>;
  gridValue1?: Maybe<Scalars['GridValue']>;
  gridValue2?: Maybe<Scalars['GridValue']>;
  gridValue3?: Maybe<Scalars['GridValue']>;
  notPurchasableDueToStock?: Maybe<Scalars['Boolean']>;
  purchasable?: Maybe<Scalars['Boolean']>;
  stockNotificationMessageEN?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  willBeAvailable?: Maybe<Scalars['Boolean']>;
};

export type SizeGuide = {
  __typename?: 'SizeGuide';
  categories?: Maybe<Array<Maybe<SizeGuideCategory>>>;
  code?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SizeGuideCategory = {
  __typename?: 'SizeGuideCategory';
  code?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  measuresystems?: Maybe<Array<Maybe<SizeGuideMeasuresystem>>>;
  name?: Maybe<Scalars['String']>;
};

export type SizeGuideMeasuresystem = {
  __typename?: 'SizeGuideMeasuresystem';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<Maybe<SizeGuideMeasuresystemTableItem>>>;
};

export type SizeGuideMeasuresystemTableItem = {
  __typename?: 'SizeGuideMeasuresystemTableItem';
  tableHtml?: Maybe<Scalars['String']>;
};

export enum SizeGuideUnit {
  Cm = 'cm',
  Inch = 'inch',
}

export type SizeGuides = {
  __typename?: 'SizeGuides';
  id?: Maybe<Scalars['ID']>;
  labelTranslations?: Maybe<SizeGuidesLabelTranslations>;
  sizeGuides?: Maybe<Array<Maybe<SizeGuide>>>;
  sizeUnit?: Maybe<SizeGuideUnit>;
};

export type SizeGuidesLabelTranslations = {
  __typename?: 'SizeGuidesLabelTranslations';
  arm?: Maybe<Scalars['String']>;
  chest?: Maybe<Scalars['String']>;
  foot?: Maybe<Scalars['String']>;
  hip?: Maybe<Scalars['String']>;
  inseam?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  waist?: Maybe<Scalars['String']>;
};

export type SizeInformation = {
  __typename?: 'SizeInformation';
  groupedSizes?: Maybe<Array<Maybe<GroupedSize>>>;
  sizeType?: Maybe<SizeType>;
  sizes?: Maybe<Array<Maybe<Size>>>;
};

export type SizeMeasurements = {
  __typename?: 'SizeMeasurements';
  id?: Maybe<Scalars['ID']>;
  sizeMeasurements?: Maybe<SizeMeasurementsObj>;
};

export type SizeMeasurementsItem = {
  __typename?: 'SizeMeasurementsItem';
  acrossShoulder?: Maybe<Scalars['String']>;
  back?: Maybe<Scalars['String']>;
  centerBackLength?: Maybe<Scalars['String']>;
  chest?: Maybe<Scalars['String']>;
  hip?: Maybe<Scalars['String']>;
  inseam?: Maybe<Scalars['String']>;
  legOpening?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  thigh?: Maybe<Scalars['String']>;
  totalLength?: Maybe<Scalars['String']>;
  waist?: Maybe<Scalars['String']>;
};

export type SizeMeasurementsObj = {
  __typename?: 'SizeMeasurementsObj';
  category?: Maybe<Scalars['String']>;
  sizeMeasurements?: Maybe<Array<Maybe<SizeMeasurementsItem>>>;
};

export enum SizeType {
  Letters = 'LETTERS',
  NumericDouble = 'NUMERIC_DOUBLE',
  NumericSingle = 'NUMERIC_SINGLE',
}

export type SocialMediaChannel = {
  __typename?: 'SocialMediaChannel';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  target?: Maybe<LinkTarget>;
  url?: Maybe<Scalars['String']>;
};

export type SortingOptions = {
  __typename?: 'SortingOptions';
  code: Scalars['String'];
  name: Scalars['String'];
  nameEN: Scalars['String'];
};

export type StockLevel = {
  __typename?: 'StockLevel';
  stockLevel?: Maybe<StockLevelMessageKey>;
  stockLevelMessages?: Maybe<Array<Maybe<StockLevelMessage>>>;
  stockMapping?: Maybe<Array<Maybe<StockMapping>>>;
  stockMessage?: Maybe<Scalars['String']>;
  stockQuantity?: Maybe<Scalars['Int']>;
};

export type StockLevelMessage = {
  __typename?: 'StockLevelMessage';
  key?: Maybe<StockLevelMessageKey>;
  value?: Maybe<Scalars['String']>;
};

export enum StockLevelMessageKey {
  Last = 'LAST',
  Low = 'LOW',
  None = 'NONE',
  Plenty = 'PLENTY',
  Scarce = 'SCARCE',
}

export type StockMapping = {
  __typename?: 'StockMapping';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<StockMappingValue>;
};

export type StockMappingValue = {
  __typename?: 'StockMappingValue';
  thresholdsLeft?: Maybe<Array<Maybe<StockValue>>>;
  thresholdsRight?: Maybe<Array<Maybe<StockValues>>>;
};

export type StockValue = {
  __typename?: 'StockValue';
  stockLevel?: Maybe<StockLevelMessageKey>;
  stockQuantity?: Maybe<Scalars['Int']>;
};

export type StockValues = {
  __typename?: 'StockValues';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<StockValue>;
};

export type Store = {
  __typename?: 'Store';
  address?: Maybe<StoreAddress>;
  contentPageUrl?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  monday?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sapStoreNr?: Maybe<Scalars['String']>;
  saturday?: Maybe<Scalars['String']>;
  stockIndication?: Maybe<Scalars['String']>;
  storeType?: Maybe<Scalars['String']>;
  sunday?: Maybe<Scalars['String']>;
  thursday?: Maybe<Scalars['String']>;
  tuesday?: Maybe<Scalars['String']>;
  wednesday?: Maybe<Scalars['String']>;
};

export type StoreAddress = {
  __typename?: 'StoreAddress';
  address?: Maybe<Scalars['String']>;
  address1Local?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address2Local?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  multiLineAddress?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  singleLineAddress?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetname?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
};

export type StyleVariant = {
  __typename?: 'StyleVariant';
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dataProductJson?: Maybe<Scalars['String']>;
  fabric?: Maybe<Scalars['String']>;
  facetColorStyle?: Maybe<Scalars['String']>;
  formattedBasePrice?: Maybe<Scalars['String']>;
  formattedFromPrice?: Maybe<Scalars['String']>;
  formattedIntermediaryPrice?: Maybe<Scalars['String']>;
  formattedLowestPrice?: Maybe<Scalars['String']>;
  images?: Maybe<StyleVariantImages>;
  isProductHasReviews?: Maybe<Scalars['Boolean']>;
  mainColor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productAppUrl?: Maybe<Scalars['String']>;
  productFiberRankingJson?: Maybe<Scalars['String']>;
  productUrl?: Maybe<Scalars['String']>;
  signing?: Maybe<StyleVariantSigning>;
  unformattedBasePrice?: Maybe<Scalars['String']>;
  unformattedFromPrice?: Maybe<Scalars['String']>;
  unformattedIntermediaryPrice?: Maybe<Scalars['String']>;
};

export type StyleVariantImages = {
  __typename?: 'StyleVariantImages';
  large?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  primaryImage?: Maybe<Scalars['String']>;
  swatch?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnailAltText?: Maybe<Scalars['String']>;
  thumbnailBase64BackgroundImage?: Maybe<Scalars['String']>;
  xlarge?: Maybe<Scalars['String']>;
  xsmall?: Maybe<Scalars['String']>;
};

export type StyleVariantSigning = {
  __typename?: 'StyleVariantSigning';
  backgroundColour?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cssClasses?: Maybe<Scalars['String']>;
  discountSigning?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  positionClass?: Maybe<Scalars['String']>;
  productSigningBackgroundColour?: Maybe<Scalars['String']>;
  productSigningTextColour?: Maybe<Scalars['String']>;
  renderAsText?: Maybe<Scalars['Boolean']>;
  showOnPdp?: Maybe<Scalars['Boolean']>;
  textColour?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum SubscribeStatusCode {
  Error = 'ERROR',
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export type Subscription = {
  __typename?: 'Subscription';
  errors?: Maybe<Array<Maybe<SubscriptionError>>>;
  newsletterSubscribeSuccessType: NewsletterSubscribeSuccessType;
  subscribeStatusCode?: Maybe<SubscribeStatusCode>;
  type: Scalars['String'];
};

export type SubscriptionError = {
  __typename?: 'SubscriptionError';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  subjectType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum SupportedFitType {
  AsianFitType = 'ASIAN_FIT_TYPE',
  WesternFitType = 'WESTERN_FIT_TYPE',
}

export type Sustainability = {
  __typename?: 'Sustainability';
  cradleToCradle?: Maybe<Scalars['String']>;
  madeToLast?: Maybe<Scalars['Boolean']>;
  presenceOfPlasticMicroFiber?: Maybe<Scalars['Boolean']>;
};

export type Traceability = {
  __typename?: 'Traceability';
  fabricManufacturingWeavingKnitting?: Maybe<Factory>;
  fabricProcessingDye?: Maybe<Factory>;
  fabricProcessingEmbroidery?: Maybe<Factory>;
  fabricProcessingPrint?: Maybe<Factory>;
  fabricSupplierID?: Maybe<Scalars['Int']>;
  garmentManufacturing?: Maybe<Factory>;
  garmentProcessingDye?: Maybe<Factory>;
  garmentProcessingEmbroidery?: Maybe<Factory>;
  garmentProcessingPrint?: Maybe<Factory>;
  garmentProcessingWash?: Maybe<Factory>;
  garmentSupplierID?: Maybe<Scalars['Int']>;
  supplierName?: Maybe<Scalars['String']>;
};

export type TransitDays = {
  __typename?: 'TransitDays';
  highestDeliveryDate?: Maybe<Scalars['String']>;
  lowerBound?: Maybe<Scalars['Int']>;
  lowestDeliveryDate?: Maybe<Scalars['String']>;
  maxDeliveryDateFormatted?: Maybe<Scalars['String']>;
  minDeliveryDateFormatted?: Maybe<Scalars['String']>;
  upperBound?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  analyticsCustomerType?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<CustomerType>;
  displayUid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hashedUserId?: Maybe<Scalars['String']>;
  hashedUserIp?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Language>;
  lastName?: Maybe<Scalars['String']>;
  loyaltySubscribed?: Maybe<Scalars['Boolean']>;
  memberCard?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personalisedDiscount?: Maybe<PersonalisedDiscount>;
  thresholdCustomerType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
};

export type Usp = {
  __typename?: 'Usp';
  html?: Maybe<Scalars['String']>;
};

export type ValidationRule = {
  __typename?: 'ValidationRule';
  blockedPostalCodeRanges?: Maybe<PostalCodeRange>;
  blockedPostalCodes?: Maybe<PostalCode>;
  field?: Maybe<Scalars['String']>;
  inputRestriction?: Maybe<Scalars['Boolean']>;
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  phoneNumberEnforceInternational?: Maybe<Scalars['Boolean']>;
  phoneNumberEnforceSameRegion?: Maybe<Scalars['Boolean']>;
  regex?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  validationType?: Maybe<Scalars['String']>;
};

export type Validations = {
  __typename?: 'Validations';
  validations?: Maybe<Array<Maybe<ValidationRule>>>;
};

export type VariantSize = BaseSize & {
  __typename?: 'VariantSize';
  formattedValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  id?: Maybe<Scalars['String']>;
  mode?: Maybe<VideoMode>;
  type?: Maybe<Scalars['String']>;
};

export enum VideoMode {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export type VisibleFacet = {
  __typename?: 'VisibleFacet';
  code?: Maybe<Scalars['String']>;
  facetValues?: Maybe<Array<Maybe<FacetValue>>>;
  name?: Maybe<Scalars['String']>;
  removeUrl?: Maybe<Scalars['String']>;
};

export type VoucherApplyResult = {
  __typename?: 'VoucherApplyResult';
  cart?: Maybe<Cart>;
  errors?: Maybe<Array<Maybe<ErrorMessage>>>;
  type?: Maybe<Scalars['String']>;
};

export type WashingLabel = {
  __typename?: 'WashingLabel';
  iconCssClass?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type Wishlist = {
  __typename?: 'Wishlist';
  pagination?: Maybe<WishlistPage>;
  results?: Maybe<Array<Maybe<WishlistProduct>>>;
};

export type WishlistImageData = Image & {
  __typename?: 'WishlistImageData';
  base64BackgroundImage?: Maybe<Scalars['String']>;
  productImageMediaTypeEnum?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type WishlistPage = {
  __typename?: 'WishlistPage';
  currentPage?: Maybe<Scalars['Int']>;
  numberOfPages?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalNumberOfResults?: Maybe<Scalars['Int']>;
};

export type WishlistPrice = {
  __typename?: 'WishlistPrice';
  currencyData?: Maybe<Currency>;
  currencyIso?: Maybe<Scalars['String']>;
  formattedValue?: Maybe<Scalars['String']>;
  formattedValueWithoutCurrency?: Maybe<Scalars['String']>;
  nrDigits?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type WishlistProduct = {
  __typename?: 'WishlistProduct';
  baseProductStockLevelStatus?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Color>;
  discountPercentage?: Maybe<Scalars['Int']>;
  displayAlternateHoverImage?: Maybe<Scalars['Boolean']>;
  fitEN?: Maybe<Scalars['String']>;
  formattedLowestPrice?: Maybe<Scalars['String']>;
  formattedLowestPriceDiscountPercentage?: Maybe<Scalars['String']>;
  formattedOriginalPriceDiscountPercentage?: Maybe<Scalars['String']>;
  fromPrice?: Maybe<WishlistPrice>;
  isDiscontinued?: Maybe<Scalars['Boolean']>;
  mainColor?: Maybe<Color>;
  materialGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<WishlistPrice>;
  productFiberRanking?: Maybe<ProductFibers>;
  signings?: Maybe<Array<Maybe<Signing>>>;
  simplifiedImageData?: Maybe<WishlistSimplifiedImageData>;
  staticCategoryPath?: Maybe<Scalars['String']>;
  staticCategoryPathIds?: Maybe<Scalars['String']>;
  subTargetAudience?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type WishlistSimplifiedImageData = {
  __typename?: 'WishlistSimplifiedImageData';
  E01?: Maybe<WishlistImageData>;
  E02?: Maybe<WishlistImageData>;
  F01?: Maybe<WishlistImageData>;
  F02?: Maybe<WishlistImageData>;
  M01?: Maybe<WishlistImageData>;
  M02?: Maybe<WishlistImageData>;
  M03?: Maybe<WishlistImageData>;
  M04?: Maybe<WishlistImageData>;
  M05?: Maybe<WishlistImageData>;
  M06?: Maybe<WishlistImageData>;
  M07?: Maybe<WishlistImageData>;
  M08?: Maybe<WishlistImageData>;
  Z01?: Maybe<WishlistImageData>;
  Z02?: Maybe<WishlistImageData>;
  Z03?: Maybe<WishlistImageData>;
  Z04?: Maybe<WishlistImageData>;
  Z05?: Maybe<WishlistImageData>;
  Z06?: Maybe<WishlistImageData>;
  Z07?: Maybe<WishlistImageData>;
  Z08?: Maybe<WishlistImageData>;
};

export type LookbookProductsProductFieldsFragment = {
  __typename?: 'ProductsProduct';
  name?: string | null;
  code?: string | null;
  url?: string | null;
  baseProduct?: string | null;
  formattedLowestPrice?: string | null;
  fromPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  mainColor?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  signings?: Array<{
    __typename?: 'Signing';
    code?: string | null;
    discountSigning?: boolean | null;
    label?: string | null;
    labelCssClasses?: string | null;
    labelHtmlSafe?: string | null;
    plpPositionClass?: string | null;
    priority?: number | null;
    productSigningBackgroundColour?: string | null;
    productSigningTextColour?: string | null;
    renderAsText?: boolean | null;
    showOnPdp?: boolean | null;
    showOnPlp?: boolean | null;
    image?: { __typename?: 'SigningImage'; url?: string | null } | null;
  } | null> | null;
  simplifiedImages?: {
    __typename?: 'SimplifiedImageEntry';
    entry?: Array<{
      __typename?: 'ImageEntry';
      key?: string | null;
      value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
    } | null> | null;
  } | null;
  size?: {
    __typename?: 'Size';
    formattedValue?: string | null;
    purchasable?: boolean | null;
    value?: string | null;
    code?: string | null;
    ean?: string | null;
    gridValue1?: any | null;
    gridValue2?: any | null;
    notPurchasableDueToStock?: boolean | null;
    stockNotificationMessageEN?: string | null;
    willBeAvailable?: boolean | null;
  } | null;
  sizeInformation?: {
    __typename?: 'SizeInformation';
    sizeType?: SizeType | null;
    groupedSizes?: Array<{
      __typename?: 'GroupedSize';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null> | null;
    sizes?: Array<{
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null> | null;
  } | null;
};

export type LookbookProductFieldsFragment = {
  __typename?: 'Product';
  name?: string | null;
  code?: any | null;
  url?: string | null;
  baseProduct?: string | null;
  formattedLowestPrice?: string | null;
  fromPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  mainColor?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  signings?: Array<{
    __typename?: 'Signing';
    code?: string | null;
    discountSigning?: boolean | null;
    label?: string | null;
    labelCssClasses?: string | null;
    labelHtmlSafe?: string | null;
    plpPositionClass?: string | null;
    priority?: number | null;
    productSigningBackgroundColour?: string | null;
    productSigningTextColour?: string | null;
    renderAsText?: boolean | null;
    showOnPdp?: boolean | null;
    showOnPlp?: boolean | null;
    image?: { __typename?: 'SigningImage'; url?: string | null } | null;
  } | null> | null;
  simplifiedImages?: {
    __typename?: 'SimplifiedImageEntry';
    entry?: Array<{
      __typename?: 'ImageEntry';
      key?: string | null;
      value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
    } | null> | null;
  } | null;
  size?: {
    __typename?: 'VariantSize';
    formattedValue?: string | null;
    value?: string | null;
  } | null;
  sizeInformation?: {
    __typename?: 'SizeInformation';
    sizeType?: SizeType | null;
    groupedSizes?: Array<{
      __typename?: 'GroupedSize';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null> | null;
    sizes?: Array<{
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null> | null;
  } | null;
  availableStyleVariantsSorted?: Array<{
    __typename?: 'StyleVariant';
    code?: string | null;
    productAppUrl?: string | null;
  } | null> | null;
};

export type LookbookProductsQueryVariables = Exact<{
  input?: InputMaybe<ProductsInput>;
}>;

export type LookbookProductsQuery = {
  __typename?: 'Query';
  products?: Array<{
    __typename?: 'ProductsProduct';
    name?: string | null;
    code?: string | null;
    url?: string | null;
    baseProduct?: string | null;
    formattedLowestPrice?: string | null;
    availableStyleVariantsSorted?: Array<{
      __typename?: 'StyleVariant';
      code?: string | null;
      productAppUrl?: string | null;
    } | null> | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    mainColor?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    signings?: Array<{
      __typename?: 'Signing';
      code?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      labelCssClasses?: string | null;
      labelHtmlSafe?: string | null;
      plpPositionClass?: string | null;
      priority?: number | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      showOnPlp?: boolean | null;
      image?: { __typename?: 'SigningImage'; url?: string | null } | null;
    } | null> | null;
    simplifiedImages?: {
      __typename?: 'SimplifiedImageEntry';
      entry?: Array<{
        __typename?: 'ImageEntry';
        key?: string | null;
        value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
      } | null> | null;
    } | null;
    size?: {
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null;
    sizeInformation?: {
      __typename?: 'SizeInformation';
      sizeType?: SizeType | null;
      groupedSizes?: Array<{
        __typename?: 'GroupedSize';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        sizes?: Array<{
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null> | null;
      } | null> | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type LookbookProductQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type LookbookProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    name?: string | null;
    code?: any | null;
    url?: string | null;
    baseProduct?: string | null;
    formattedLowestPrice?: string | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    mainColor?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    signings?: Array<{
      __typename?: 'Signing';
      code?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      labelCssClasses?: string | null;
      labelHtmlSafe?: string | null;
      plpPositionClass?: string | null;
      priority?: number | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      showOnPlp?: boolean | null;
      image?: { __typename?: 'SigningImage'; url?: string | null } | null;
    } | null> | null;
    simplifiedImages?: {
      __typename?: 'SimplifiedImageEntry';
      entry?: Array<{
        __typename?: 'ImageEntry';
        key?: string | null;
        value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
      } | null> | null;
    } | null;
    size?: {
      __typename?: 'VariantSize';
      formattedValue?: string | null;
      value?: string | null;
    } | null;
    sizeInformation?: {
      __typename?: 'SizeInformation';
      sizeType?: SizeType | null;
      groupedSizes?: Array<{
        __typename?: 'GroupedSize';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        sizes?: Array<{
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null> | null;
      } | null> | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null;
    availableStyleVariantsSorted?: Array<{
      __typename?: 'StyleVariant';
      code?: string | null;
      productAppUrl?: string | null;
    } | null> | null;
  };
};

type ImageFields_ImageData_Fragment = { __typename?: 'ImageData'; url?: string | null };

type ImageFields_SigningImage_Fragment = { __typename?: 'SigningImage'; url?: string | null };

type ImageFields_WishlistImageData_Fragment = {
  __typename?: 'WishlistImageData';
  url?: string | null;
};

export type ImageFieldsFragment =
  | ImageFields_ImageData_Fragment
  | ImageFields_SigningImage_Fragment
  | ImageFields_WishlistImageData_Fragment;

export type PriceFieldsFragment = {
  __typename?: 'Price';
  currencyIso?: string | null;
  formattedValue?: string | null;
  formattedValueWithoutCurrency?: string | null;
  nrDigits?: number | null;
  value?: number | null;
};

export type CurrencyFieldsFragment = {
  __typename?: 'Currency';
  active?: boolean | null;
  isocode?: string | null;
  name?: string | null;
  symbol?: string | null;
  digits?: number | null;
};

export type WishlistPriceFieldsFragment = {
  __typename?: 'WishlistPrice';
  currencyIso?: string | null;
  formattedValue?: string | null;
  formattedValueWithoutCurrency?: string | null;
  nrDigits?: number | null;
  value?: number | null;
  currencyData?: {
    __typename?: 'Currency';
    active?: boolean | null;
    isocode?: string | null;
    name?: string | null;
    symbol?: string | null;
    digits?: number | null;
  } | null;
};

export type ColorFieldsFragment = {
  __typename?: 'Color';
  description?: string | null;
  descriptionEN?: string | null;
  facetColorStyle?: string | null;
};

export type ExplodingViewFieldsFragment = {
  __typename?: 'ExplodingView';
  explodingViewDescription?: string | null;
  explodingViewSvgUrl?: string | null;
  explodingViewTitle?: string | null;
};

export type MannequinFieldsFragment = {
  __typename?: 'Mannequin';
  imperialMessage?: string | null;
  metricMessage?: string | null;
  additionalSizeInfo?: string | null;
};

export type ReturnFeeFieldsFragment = {
  __typename?: 'ReturnFee';
  amount?: string | null;
  color?: string | null;
  message?: string | null;
  returnType?: ReturnFeeType | null;
};

export type SigningImageFieldsFragment = { __typename?: 'SigningImage'; url?: string | null };

export type ImageDataFieldsFragment = {
  __typename?: 'ImageData';
  altText?: string | null;
  url?: string | null;
};

export type WishlistImageDataFieldsFragment = {
  __typename?: 'WishlistImageData';
  url?: string | null;
  seoDescription?: string | null;
  productImageMediaTypeEnum?: string | null;
  version?: string | null;
};

export type ImageEntryFieldsFragment = {
  __typename?: 'ImageEntry';
  key?: string | null;
  value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
};

export type SimplifiedImageEntryFieldsFragment = {
  __typename?: 'SimplifiedImageEntry';
  entry?: Array<{
    __typename?: 'ImageEntry';
    key?: string | null;
    value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
  } | null> | null;
};

type BaseSizeFields_Size_Fragment = {
  __typename?: 'Size';
  formattedValue?: string | null;
  value?: string | null;
};

type BaseSizeFields_VariantSize_Fragment = {
  __typename?: 'VariantSize';
  formattedValue?: string | null;
  value?: string | null;
};

export type BaseSizeFieldsFragment =
  | BaseSizeFields_Size_Fragment
  | BaseSizeFields_VariantSize_Fragment;

export type SizeFieldsFragment = {
  __typename?: 'Size';
  formattedValue?: string | null;
  purchasable?: boolean | null;
  value?: string | null;
  code?: string | null;
  ean?: string | null;
  gridValue1?: any | null;
  gridValue2?: any | null;
  notPurchasableDueToStock?: boolean | null;
  stockNotificationMessageEN?: string | null;
  willBeAvailable?: boolean | null;
};

export type VariantSizeFieldsFragment = {
  __typename?: 'VariantSize';
  formattedValue?: string | null;
  value?: string | null;
};

export type GroupedSizeFieldsFragment = {
  __typename?: 'GroupedSize';
  formattedValue?: string | null;
  purchasable?: boolean | null;
  value?: string | null;
  sizes?: Array<{
    __typename?: 'Size';
    formattedValue?: string | null;
    purchasable?: boolean | null;
    value?: string | null;
    code?: string | null;
    ean?: string | null;
    gridValue1?: any | null;
    gridValue2?: any | null;
    notPurchasableDueToStock?: boolean | null;
    stockNotificationMessageEN?: string | null;
    willBeAvailable?: boolean | null;
  } | null> | null;
};

export type SizeInformationFieldsFragment = {
  __typename?: 'SizeInformation';
  sizeType?: SizeType | null;
  groupedSizes?: Array<{
    __typename?: 'GroupedSize';
    formattedValue?: string | null;
    purchasable?: boolean | null;
    value?: string | null;
    sizes?: Array<{
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null> | null;
  } | null> | null;
  sizes?: Array<{
    __typename?: 'Size';
    formattedValue?: string | null;
    purchasable?: boolean | null;
    value?: string | null;
    code?: string | null;
    ean?: string | null;
    gridValue1?: any | null;
    gridValue2?: any | null;
    notPurchasableDueToStock?: boolean | null;
    stockNotificationMessageEN?: string | null;
    willBeAvailable?: boolean | null;
  } | null> | null;
};

export type StockValueFieldsFragment = {
  __typename?: 'StockValue';
  stockLevel?: StockLevelMessageKey | null;
  stockQuantity?: number | null;
};

export type StockValuesFieldsFragment = {
  __typename?: 'StockValues';
  key?: string | null;
  value?: {
    __typename?: 'StockValue';
    stockLevel?: StockLevelMessageKey | null;
    stockQuantity?: number | null;
  } | null;
};

export type StockLevelMessageFieldsFragment = {
  __typename?: 'StockLevelMessage';
  key?: StockLevelMessageKey | null;
  value?: string | null;
};

export type StockMappingValueFieldsFragment = {
  __typename?: 'StockMappingValue';
  thresholdsLeft?: Array<{
    __typename?: 'StockValue';
    stockLevel?: StockLevelMessageKey | null;
    stockQuantity?: number | null;
  } | null> | null;
  thresholdsRight?: Array<{
    __typename?: 'StockValues';
    key?: string | null;
    value?: {
      __typename?: 'StockValue';
      stockLevel?: StockLevelMessageKey | null;
      stockQuantity?: number | null;
    } | null;
  } | null> | null;
};

export type StockMappingFieldsFragment = {
  __typename?: 'StockMapping';
  key?: string | null;
  value?: {
    __typename?: 'StockMappingValue';
    thresholdsLeft?: Array<{
      __typename?: 'StockValue';
      stockLevel?: StockLevelMessageKey | null;
      stockQuantity?: number | null;
    } | null> | null;
    thresholdsRight?: Array<{
      __typename?: 'StockValues';
      key?: string | null;
      value?: {
        __typename?: 'StockValue';
        stockLevel?: StockLevelMessageKey | null;
        stockQuantity?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type StockLevelFieldsFragment = {
  __typename?: 'StockLevel';
  stockLevel?: StockLevelMessageKey | null;
  stockMessage?: string | null;
  stockQuantity?: number | null;
  stockLevelMessages?: Array<{
    __typename?: 'StockLevelMessage';
    key?: StockLevelMessageKey | null;
    value?: string | null;
  } | null> | null;
  stockMapping?: Array<{
    __typename?: 'StockMapping';
    key?: string | null;
    value?: {
      __typename?: 'StockMappingValue';
      thresholdsLeft?: Array<{
        __typename?: 'StockValue';
        stockLevel?: StockLevelMessageKey | null;
        stockQuantity?: number | null;
      } | null> | null;
      thresholdsRight?: Array<{
        __typename?: 'StockValues';
        key?: string | null;
        value?: {
          __typename?: 'StockValue';
          stockLevel?: StockLevelMessageKey | null;
          stockQuantity?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type WashingLabelFieldsFragment = {
  __typename?: 'WashingLabel';
  iconCssClass?: string | null;
  label?: string | null;
};

export type AnalyticsDataFieldsFragment = {
  __typename?: 'AnalyticsData';
  key?: string | null;
  value?: string | null;
};

export type SizeMeasurementFieldsFragment = {
  __typename?: 'SizeMeasurementsItem';
  hip?: string | null;
  inseam?: string | null;
  legOpening?: string | null;
  size?: string | null;
  thigh?: string | null;
  totalLength?: string | null;
  waist?: string | null;
  acrossShoulder?: string | null;
  back?: string | null;
  sleeve?: string | null;
  centerBackLength?: string | null;
  chest?: string | null;
};

export type SizeMeasurementsObjFieldsFragment = {
  __typename?: 'SizeMeasurementsObj';
  category?: string | null;
  sizeMeasurements?: Array<{
    __typename?: 'SizeMeasurementsItem';
    hip?: string | null;
    inseam?: string | null;
    legOpening?: string | null;
    size?: string | null;
    thigh?: string | null;
    totalLength?: string | null;
    waist?: string | null;
    acrossShoulder?: string | null;
    back?: string | null;
    sleeve?: string | null;
    centerBackLength?: string | null;
    chest?: string | null;
  } | null> | null;
};

export type SizeMeasurementsFieldsFragment = {
  __typename?: 'SizeMeasurements';
  id?: string | null;
  sizeMeasurements?: {
    __typename?: 'SizeMeasurementsObj';
    category?: string | null;
    sizeMeasurements?: Array<{
      __typename?: 'SizeMeasurementsItem';
      hip?: string | null;
      inseam?: string | null;
      legOpening?: string | null;
      size?: string | null;
      thigh?: string | null;
      totalLength?: string | null;
      waist?: string | null;
      acrossShoulder?: string | null;
      back?: string | null;
      sleeve?: string | null;
      centerBackLength?: string | null;
      chest?: string | null;
    } | null> | null;
  } | null;
};

export type UspsFieldsFragment = { __typename?: 'Usp'; html?: string | null };

export type BreadCrumbFieldsFragment = {
  __typename?: 'Breadcrumb';
  name?: string | null;
  url?: string | null;
};

export type FiberFieldsFragment = {
  __typename?: 'Fiber';
  code?: string | null;
  descriptionLong?: string | null;
  descriptionShort?: string | null;
  percentage?: number | null;
  ranking?: string | null;
  score?: number | null;
  type?: string | null;
};

export type ProductFibersFieldsFragment = {
  __typename?: 'ProductFibers';
  ranking?: string | null;
  score?: number | null;
  fibers?: Array<{
    __typename?: 'Fiber';
    code?: string | null;
    descriptionLong?: string | null;
    descriptionShort?: string | null;
    percentage?: number | null;
    ranking?: string | null;
    score?: number | null;
    type?: string | null;
  } | null> | null;
};

export type GenericFibersFieldsFragment = {
  __typename?: 'GenericFibers';
  fibers?: Array<{
    __typename?: 'Fiber';
    code?: string | null;
    descriptionLong?: string | null;
    descriptionShort?: string | null;
    percentage?: number | null;
    ranking?: string | null;
    score?: number | null;
    type?: string | null;
  } | null> | null;
};

export type FiberRankingsFragment = {
  __typename?: 'FiberRankingData';
  genericFiberRankingData?: {
    __typename?: 'GenericFibers';
    fibers?: Array<{
      __typename?: 'Fiber';
      code?: string | null;
      descriptionLong?: string | null;
      descriptionShort?: string | null;
      percentage?: number | null;
      ranking?: string | null;
      score?: number | null;
      type?: string | null;
    } | null> | null;
  } | null;
  productFiberRankingData?: {
    __typename?: 'ProductFibers';
    ranking?: string | null;
    score?: number | null;
    fibers?: Array<{
      __typename?: 'Fiber';
      code?: string | null;
      descriptionLong?: string | null;
      descriptionShort?: string | null;
      percentage?: number | null;
      ranking?: string | null;
      score?: number | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export type FiberRankingFieldsFragment = {
  __typename?: 'FiberRanking';
  productAudience?: string | null;
  fiberRanking?: {
    __typename?: 'FiberRankingData';
    genericFiberRankingData?: {
      __typename?: 'GenericFibers';
      fibers?: Array<{
        __typename?: 'Fiber';
        code?: string | null;
        descriptionLong?: string | null;
        descriptionShort?: string | null;
        percentage?: number | null;
        ranking?: string | null;
        score?: number | null;
        type?: string | null;
      } | null> | null;
    } | null;
    productFiberRankingData?: {
      __typename?: 'ProductFibers';
      ranking?: string | null;
      score?: number | null;
      fibers?: Array<{
        __typename?: 'Fiber';
        code?: string | null;
        descriptionLong?: string | null;
        descriptionShort?: string | null;
        percentage?: number | null;
        ranking?: string | null;
        score?: number | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeliveryOptionFieldsFragment = {
  __typename?: 'DeliveryOption';
  deliveryCost?: number | null;
  icon?: string | null;
  name?: string | null;
};

export type DeliveryOptionsFieldsFragment = {
  __typename?: 'DeliveryOptions';
  deliveryOptions?: Array<{
    __typename?: 'DeliveryOption';
    deliveryCost?: number | null;
    icon?: string | null;
    name?: string | null;
  } | null> | null;
};

export type SocialMediaChannelFieldsFragment = {
  __typename?: 'SocialMediaChannel';
  code?: string | null;
  name?: string | null;
  target?: LinkTarget | null;
  url?: string | null;
};

export type PaymentMethodsFieldsFragment = {
  __typename?: 'PaymentMethods';
  code?: string | null;
  imageCssClass?: string | null;
  imageFooterCssClass?: string | null;
  name?: string | null;
  target?: LinkTarget | null;
  url?: string | null;
};

export type StoreFieldsFragment = {
  __typename?: 'Store';
  contentPageUrl?: string | null;
  saturday?: string | null;
  storeType?: string | null;
  latitude?: string | null;
  thursday?: string | null;
  stockIndication?: string | null;
  sunday?: string | null;
  tuesday?: string | null;
  name?: string | null;
  wednesday?: string | null;
  friday?: string | null;
  sapStoreNr?: string | null;
  longitude?: string | null;
  monday?: string | null;
  address?: {
    __typename?: 'StoreAddress';
    country?: string | null;
    address?: string | null;
    address2?: string | null;
    city?: string | null;
    streetname?: string | null;
    multiLineAddress?: string | null;
    address1Local?: string | null;
    postalCode?: string | null;
    streetnumber?: string | null;
    singleLineAddress?: string | null;
    address2Local?: string | null;
    phone?: string | null;
    state?: string | null;
  } | null;
};

export type StoreAddressFieldsFragment = {
  __typename?: 'StoreAddress';
  country?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  streetname?: string | null;
  multiLineAddress?: string | null;
  address1Local?: string | null;
  postalCode?: string | null;
  streetnumber?: string | null;
  singleLineAddress?: string | null;
  address2Local?: string | null;
  phone?: string | null;
  state?: string | null;
};

export type RecommendedProductFieldsFragment = {
  __typename?: 'RecommendedProduct';
  baseProduct?: string | null;
  code: any;
  name?: string | null;
  url?: string | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

export type RecommendedProductsFieldsFragment = {
  __typename?: 'RecommendedProducts';
  maxMobileProducts?: number | null;
  maxProducts?: number | null;
  maxTabletProducts?: number | null;
  title?: string | null;
  products?: Array<{
    __typename?: 'RecommendedProduct';
    baseProduct?: string | null;
    code: any;
    name?: string | null;
    url?: string | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
  } | null> | null;
};

export type RecommendationsFieldsFragment = {
  __typename?: 'Recommendations';
  productCodes?: string | null;
  analytics?: Array<{
    __typename?: 'AnalyticsData';
    key?: string | null;
    value?: string | null;
  } | null> | null;
  recommendedProducts?: {
    __typename?: 'RecommendedProducts';
    maxMobileProducts?: number | null;
    maxProducts?: number | null;
    maxTabletProducts?: number | null;
    title?: string | null;
    products?: Array<{
      __typename?: 'RecommendedProduct';
      baseProduct?: string | null;
      code: any;
      name?: string | null;
      url?: string | null;
      price?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

type BaseProductFields_CartProduct_Fragment = {
  __typename?: 'CartProduct';
  name?: string | null;
  url?: string | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

type BaseProductFields_Product_Fragment = {
  __typename?: 'Product';
  name?: string | null;
  url?: string | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

type BaseProductFields_ProductsProduct_Fragment = {
  __typename?: 'ProductsProduct';
  name?: string | null;
  url?: string | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

type BaseProductFields_RecommendedProduct_Fragment = {
  __typename?: 'RecommendedProduct';
  name?: string | null;
  url?: string | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

export type BaseProductFieldsFragment =
  | BaseProductFields_CartProduct_Fragment
  | BaseProductFields_Product_Fragment
  | BaseProductFields_ProductsProduct_Fragment
  | BaseProductFields_RecommendedProduct_Fragment;

export type PageFieldsFragment = {
  __typename?: 'Page';
  currentPage?: number | null;
  totalPageCount?: number | null;
  totalProductCount?: number | null;
  products?: Array<{
    __typename?: 'PagedProduct';
    code: string;
    url?: string | null;
  } | null> | null;
};

export type CartProductFieldsFragment = {
  __typename?: 'CartProduct';
  code?: any | null;
  baseProduct?: string | null;
  ean?: string | null;
  fitEN?: string | null;
  isGiftCard?: boolean | null;
  materialGroup?: string | null;
  name?: string | null;
  nameEN?: string | null;
  oneSize?: boolean | null;
  programEN?: string | null;
  shouldUseDarkBackgroundImgs?: boolean | null;
  staticCategoryPath?: string | null;
  staticCategoryPathIds?: string | null;
  stockNotificationMessageEN?: string | null;
  styleFamilyEN?: string | null;
  subTargetAudience?: string | null;
  url?: string | null;
  simplifiedImages?: Array<{
    __typename?: 'ImageEntry';
    key?: string | null;
    value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
  } | null> | null;
  size?: {
    __typename?: 'Size';
    formattedValue?: string | null;
    purchasable?: boolean | null;
    value?: string | null;
    code?: string | null;
    ean?: string | null;
    gridValue1?: any | null;
    gridValue2?: any | null;
    notPurchasableDueToStock?: boolean | null;
    stockNotificationMessageEN?: string | null;
    willBeAvailable?: boolean | null;
  } | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  mainColor?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  fromPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  color?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
};

export type StyleVariantSigningFieldsFragment = {
  __typename?: 'StyleVariantSigning';
  backgroundColour?: string | null;
  cssClasses?: string | null;
  discountSigning?: boolean | null;
  label?: string | null;
  productSigningBackgroundColour?: string | null;
  productSigningTextColour?: string | null;
  renderAsText?: boolean | null;
  showOnPdp?: boolean | null;
  textColour?: string | null;
};

export type MinimumStyleVariantImagesFieldsFragment = {
  __typename?: 'StyleVariantImages';
  large?: string | null;
  primaryImage?: string | null;
  swatch?: string | null;
  thumbnail?: string | null;
  thumbnailAltText?: string | null;
};

export type StyleVariantFieldsFragment = {
  __typename?: 'StyleVariant';
  code?: string | null;
  color?: string | null;
  facetColorStyle?: string | null;
  formattedBasePrice?: string | null;
  formattedFromPrice?: string | null;
  formattedIntermediaryPrice?: string | null;
  formattedLowestPrice?: string | null;
  isProductHasReviews?: boolean | null;
  mainColor?: string | null;
  name?: string | null;
  productAppUrl?: string | null;
  productFiberRankingJson?: string | null;
  productUrl?: string | null;
  unformattedBasePrice?: string | null;
  unformattedFromPrice?: string | null;
  unformattedIntermediaryPrice?: string | null;
  images?: {
    __typename?: 'StyleVariantImages';
    large?: string | null;
    primaryImage?: string | null;
    swatch?: string | null;
    thumbnail?: string | null;
    thumbnailAltText?: string | null;
  } | null;
  signing?: {
    __typename?: 'StyleVariantSigning';
    backgroundColour?: string | null;
    cssClasses?: string | null;
    discountSigning?: boolean | null;
    label?: string | null;
    productSigningBackgroundColour?: string | null;
    productSigningTextColour?: string | null;
    renderAsText?: boolean | null;
    showOnPdp?: boolean | null;
    textColour?: string | null;
  } | null;
};

export type PagedProductFieldsFragment = {
  __typename?: 'PagedProduct';
  code: string;
  url?: string | null;
};

export type ProductFieldsFragment = {
  __typename?: 'Product';
  algoliaObjectId?: string | null;
  id?: string | null;
  analyticsCategory?: string | null;
  approvalStatus?: ApprovalStatus | null;
  appUrl?: string | null;
  baseProduct?: string | null;
  baseProductStockNotificationMessageEN?: string | null;
  canonicalUrl?: string | null;
  code?: any | null;
  colorFinishDescription?: string | null;
  colorFinishTitle?: string | null;
  colorPLPUrl?: string | null;
  comingSoon?: boolean | null;
  defaultPDPTemplate?: string | null;
  detailsBullets?: Array<string | null> | null;
  detailsTitle?: string | null;
  dimensionsBullets?: Array<string | null> | null;
  ean?: string | null;
  fabricBullets?: Array<string | null> | null;
  fabricDescription?: string | null;
  fabricHeader?: string | null;
  fabricTitle?: string | null;
  factoryId?: string | null;
  featuresIntro?: string | null;
  featuresTitle?: string | null;
  fit?: string | null;
  fitEN?: string | null;
  formattedLowestPrice?: string | null;
  hasLocalStoreStock?: boolean | null;
  isGiftCard?: boolean | null;
  isProductHasReview?: boolean | null;
  materialGroup?: string | null;
  metaDescription?: string | null;
  metaKeywords?: string | null;
  metaTitle?: string | null;
  mccValue?: string | null;
  name?: string | null;
  nameEN?: string | null;
  oneSize?: boolean | null;
  ppcPDPTemplate?: string | null;
  programDescription?: string | null;
  programEN?: string | null;
  programTitle?: string | null;
  purchasable?: boolean | null;
  salesDiscountsEndDate?: string | null;
  seoName?: string | null;
  shouldRequestSizeMeasurements?: boolean | null;
  shouldUseDarkBackgroundImgs?: boolean | null;
  showStoreStockLocator?: boolean | null;
  sizeChartUrl?: string | null;
  staticCategoryPath?: string | null;
  staticCategoryPathIds?: string | null;
  staticCategoryPathSeo?: string | null;
  stockNotificationLink?: string | null;
  stockNotificationMessageEN?: string | null;
  styleDescription?: string | null;
  styleFamilyEN?: string | null;
  subTargetAudience?: string | null;
  supplierReference?: string | null;
  url?: string | null;
  useExternalUrl?: boolean | null;
  availableStyleVariantsSorted?: Array<{
    __typename?: 'StyleVariant';
    code?: string | null;
    color?: string | null;
    facetColorStyle?: string | null;
    formattedBasePrice?: string | null;
    formattedFromPrice?: string | null;
    formattedIntermediaryPrice?: string | null;
    formattedLowestPrice?: string | null;
    isProductHasReviews?: boolean | null;
    mainColor?: string | null;
    name?: string | null;
    productAppUrl?: string | null;
    productFiberRankingJson?: string | null;
    productUrl?: string | null;
    unformattedBasePrice?: string | null;
    unformattedFromPrice?: string | null;
    unformattedIntermediaryPrice?: string | null;
    images?: {
      __typename?: 'StyleVariantImages';
      large?: string | null;
      primaryImage?: string | null;
      swatch?: string | null;
      thumbnail?: string | null;
      thumbnailAltText?: string | null;
    } | null;
    signing?: {
      __typename?: 'StyleVariantSigning';
      backgroundColour?: string | null;
      cssClasses?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      textColour?: string | null;
    } | null;
  } | null> | null;
  breadcrumbs?: Array<{
    __typename?: 'Breadcrumb';
    name?: string | null;
    url?: string | null;
  } | null> | null;
  color?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  deliveryOptions?: {
    __typename?: 'DeliveryOptions';
    deliveryOptions?: Array<{
      __typename?: 'DeliveryOption';
      deliveryCost?: number | null;
      icon?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
  explodingView?: {
    __typename?: 'ExplodingView';
    explodingViewDescription?: string | null;
    explodingViewSvgUrl?: string | null;
    explodingViewTitle?: string | null;
  } | null;
  fiberRanking?: {
    __typename?: 'FiberRanking';
    productAudience?: string | null;
    fiberRanking?: {
      __typename?: 'FiberRankingData';
      genericFiberRankingData?: {
        __typename?: 'GenericFibers';
        fibers?: Array<{
          __typename?: 'Fiber';
          code?: string | null;
          descriptionLong?: string | null;
          descriptionShort?: string | null;
          percentage?: number | null;
          ranking?: string | null;
          score?: number | null;
          type?: string | null;
        } | null> | null;
      } | null;
      productFiberRankingData?: {
        __typename?: 'ProductFibers';
        ranking?: string | null;
        score?: number | null;
        fibers?: Array<{
          __typename?: 'Fiber';
          code?: string | null;
          descriptionLong?: string | null;
          descriptionShort?: string | null;
          percentage?: number | null;
          ranking?: string | null;
          score?: number | null;
          type?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  fromPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  intermediaryPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  mainColor?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  mannequin?: {
    __typename?: 'Mannequin';
    imperialMessage?: string | null;
    metricMessage?: string | null;
    additionalSizeInfo?: string | null;
  } | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  returnFee?: {
    __typename?: 'ReturnFee';
    amount?: string | null;
    color?: string | null;
    message?: string | null;
    returnType?: ReturnFeeType | null;
  } | null;
  signings?: Array<{
    __typename?: 'Signing';
    code?: string | null;
    discountSigning?: boolean | null;
    label?: string | null;
    labelCssClasses?: string | null;
    labelHtmlSafe?: string | null;
    plpPositionClass?: string | null;
    priority?: number | null;
    productSigningBackgroundColour?: string | null;
    productSigningTextColour?: string | null;
    renderAsText?: boolean | null;
    showOnPdp?: boolean | null;
    showOnPlp?: boolean | null;
    image?: { __typename?: 'SigningImage'; url?: string | null } | null;
  } | null> | null;
  simplifiedImages?: {
    __typename?: 'SimplifiedImageEntry';
    entry?: Array<{
      __typename?: 'ImageEntry';
      key?: string | null;
      value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
    } | null> | null;
  } | null;
  size?: {
    __typename?: 'VariantSize';
    formattedValue?: string | null;
    value?: string | null;
  } | null;
  sizeInformation?: {
    __typename?: 'SizeInformation';
    sizeType?: SizeType | null;
    groupedSizes?: Array<{
      __typename?: 'GroupedSize';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null> | null;
    sizes?: Array<{
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null> | null;
  } | null;
  stockInformation?: {
    __typename?: 'StockLevel';
    stockLevel?: StockLevelMessageKey | null;
    stockMessage?: string | null;
    stockQuantity?: number | null;
    stockLevelMessages?: Array<{
      __typename?: 'StockLevelMessage';
      key?: StockLevelMessageKey | null;
      value?: string | null;
    } | null> | null;
    stockMapping?: Array<{
      __typename?: 'StockMapping';
      key?: string | null;
      value?: {
        __typename?: 'StockMappingValue';
        thresholdsLeft?: Array<{
          __typename?: 'StockValue';
          stockLevel?: StockLevelMessageKey | null;
          stockQuantity?: number | null;
        } | null> | null;
        thresholdsRight?: Array<{
          __typename?: 'StockValues';
          key?: string | null;
          value?: {
            __typename?: 'StockValue';
            stockLevel?: StockLevelMessageKey | null;
            stockQuantity?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  sustainability?: {
    __typename?: 'Sustainability';
    cradleToCradle?: string | null;
    presenceOfPlasticMicroFiber?: boolean | null;
    madeToLast?: boolean | null;
  } | null;
  traceability?: {
    __typename?: 'Traceability';
    supplierName?: string | null;
    garmentSupplierID?: number | null;
    fabricSupplierID?: number | null;
    garmentManufacturing?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    garmentProcessingDye?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    garmentProcessingEmbroidery?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    garmentProcessingPrint?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    garmentProcessingWash?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    fabricManufacturingWeavingKnitting?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    fabricProcessingDye?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    fabricProcessingEmbroidery?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
    fabricProcessingPrint?: {
      __typename?: 'Factory';
      id?: string | null;
      name?: string | null;
      country?: string | null;
    } | null;
  } | null;
  videos?: Array<{
    __typename?: 'Video';
    id?: string | null;
    mode?: VideoMode | null;
    type?: string | null;
  } | null> | null;
  washingLabels?: Array<{
    __typename?: 'WashingLabel';
    iconCssClass?: string | null;
    label?: string | null;
  } | null> | null;
};

export type SustainabilityFieldsFragment = {
  __typename?: 'Sustainability';
  cradleToCradle?: string | null;
  presenceOfPlasticMicroFiber?: boolean | null;
  madeToLast?: boolean | null;
};

export type TraceabilityFieldsFragment = {
  __typename?: 'Traceability';
  supplierName?: string | null;
  garmentSupplierID?: number | null;
  fabricSupplierID?: number | null;
  garmentManufacturing?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  garmentProcessingDye?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  garmentProcessingEmbroidery?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  garmentProcessingPrint?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  garmentProcessingWash?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  fabricManufacturingWeavingKnitting?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  fabricProcessingDye?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  fabricProcessingEmbroidery?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
  fabricProcessingPrint?: {
    __typename?: 'Factory';
    id?: string | null;
    name?: string | null;
    country?: string | null;
  } | null;
};

export type FactoryFieldsFragment = {
  __typename?: 'Factory';
  id?: string | null;
  name?: string | null;
  country?: string | null;
};

export type ProductsProductFieldsFragment = {
  __typename?: 'ProductsProduct';
  algoliaObjectId?: string | null;
  baseProduct?: string | null;
  featuresIntro?: string | null;
  formattedLowestPrice?: string | null;
  name?: string | null;
  nameEN?: string | null;
  url?: string | null;
  ean?: string | null;
  code?: string | null;
  fitEN?: string | null;
  materialGroup?: string | null;
  programEN?: string | null;
  staticCategoryPath?: string | null;
  staticCategoryPathIds?: string | null;
  subTargetAudience?: string | null;
  baseProductStockNotificationMessageEN?: string | null;
  styleFamilyEN?: string | null;
  oneSize?: boolean | null;
  isGiftCard?: boolean | null;
  availableStyleVariantsSorted?: Array<{
    __typename?: 'StyleVariant';
    code?: string | null;
    color?: string | null;
    facetColorStyle?: string | null;
    formattedBasePrice?: string | null;
    formattedFromPrice?: string | null;
    formattedIntermediaryPrice?: string | null;
    formattedLowestPrice?: string | null;
    isProductHasReviews?: boolean | null;
    mainColor?: string | null;
    name?: string | null;
    productAppUrl?: string | null;
    productFiberRankingJson?: string | null;
    productUrl?: string | null;
    unformattedBasePrice?: string | null;
    unformattedFromPrice?: string | null;
    unformattedIntermediaryPrice?: string | null;
    images?: {
      __typename?: 'StyleVariantImages';
      large?: string | null;
      primaryImage?: string | null;
      swatch?: string | null;
      thumbnail?: string | null;
      thumbnailAltText?: string | null;
    } | null;
    signing?: {
      __typename?: 'StyleVariantSigning';
      backgroundColour?: string | null;
      cssClasses?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      textColour?: string | null;
    } | null;
  } | null> | null;
  color?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  fiberRanking?: {
    __typename?: 'FiberRanking';
    productAudience?: string | null;
    fiberRanking?: {
      __typename?: 'FiberRankingData';
      genericFiberRankingData?: {
        __typename?: 'GenericFibers';
        fibers?: Array<{
          __typename?: 'Fiber';
          code?: string | null;
          descriptionLong?: string | null;
          descriptionShort?: string | null;
          percentage?: number | null;
          ranking?: string | null;
          score?: number | null;
          type?: string | null;
        } | null> | null;
      } | null;
      productFiberRankingData?: {
        __typename?: 'ProductFibers';
        ranking?: string | null;
        score?: number | null;
        fibers?: Array<{
          __typename?: 'Fiber';
          code?: string | null;
          descriptionLong?: string | null;
          descriptionShort?: string | null;
          percentage?: number | null;
          ranking?: string | null;
          score?: number | null;
          type?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  fromPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  intermediaryPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  mainColor?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  price?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  simplifiedImages?: {
    __typename?: 'SimplifiedImageEntry';
    entry?: Array<{
      __typename?: 'ImageEntry';
      key?: string | null;
      value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
    } | null> | null;
  } | null;
  size?: {
    __typename?: 'Size';
    formattedValue?: string | null;
    purchasable?: boolean | null;
    value?: string | null;
    code?: string | null;
    ean?: string | null;
    gridValue1?: any | null;
    gridValue2?: any | null;
    notPurchasableDueToStock?: boolean | null;
    stockNotificationMessageEN?: string | null;
    willBeAvailable?: boolean | null;
  } | null;
  sizeInformation?: {
    __typename?: 'SizeInformation';
    sizeType?: SizeType | null;
    groupedSizes?: Array<{
      __typename?: 'GroupedSize';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null> | null;
    sizes?: Array<{
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null> | null;
  } | null;
  signings?: Array<{
    __typename?: 'Signing';
    code?: string | null;
    discountSigning?: boolean | null;
    label?: string | null;
    labelCssClasses?: string | null;
    labelHtmlSafe?: string | null;
    plpPositionClass?: string | null;
    priority?: number | null;
    productSigningBackgroundColour?: string | null;
    productSigningTextColour?: string | null;
    renderAsText?: boolean | null;
    showOnPdp?: boolean | null;
    showOnPlp?: boolean | null;
    image?: { __typename?: 'SigningImage'; url?: string | null } | null;
  } | null> | null;
};

export type VideoFieldsFragment = {
  __typename?: 'Video';
  id?: string | null;
  mode?: VideoMode | null;
  type?: string | null;
};

export type PostalCodeFieldsFragment = {
  __typename?: 'PostalCode';
  description?: string | null;
  postalCode?: string | null;
};

export type PostalCodeRangeFieldsFragment = {
  __typename?: 'PostalCodeRange';
  description?: string | null;
  postalCodeRangeHigh?: number | null;
  postalCodeRangeLow?: number | null;
};

export type ValidationRuleFieldsFragment = {
  __typename?: 'ValidationRule';
  field?: string | null;
  maxLength?: number | null;
  minLength?: number | null;
  regex?: string | null;
  required?: boolean | null;
  validationType?: string | null;
  inputRestriction?: boolean | null;
  phoneNumberEnforceInternational?: boolean | null;
  phoneNumberEnforceSameRegion?: boolean | null;
  blockedPostalCodeRanges?: {
    __typename?: 'PostalCodeRange';
    description?: string | null;
    postalCodeRangeHigh?: number | null;
    postalCodeRangeLow?: number | null;
  } | null;
  blockedPostalCodes?: {
    __typename?: 'PostalCode';
    description?: string | null;
    postalCode?: string | null;
  } | null;
};

export type ValidationsFieldsFragment = {
  __typename?: 'Validations';
  validations?: Array<{
    __typename?: 'ValidationRule';
    field?: string | null;
    maxLength?: number | null;
    minLength?: number | null;
    regex?: string | null;
    required?: boolean | null;
    validationType?: string | null;
    inputRestriction?: boolean | null;
    phoneNumberEnforceInternational?: boolean | null;
    phoneNumberEnforceSameRegion?: boolean | null;
    blockedPostalCodeRanges?: {
      __typename?: 'PostalCodeRange';
      description?: string | null;
      postalCodeRangeHigh?: number | null;
      postalCodeRangeLow?: number | null;
    } | null;
    blockedPostalCodes?: {
      __typename?: 'PostalCode';
      description?: string | null;
      postalCode?: string | null;
    } | null;
  } | null> | null;
};

export type SigningFieldsFragment = {
  __typename?: 'Signing';
  code?: string | null;
  discountSigning?: boolean | null;
  label?: string | null;
  labelCssClasses?: string | null;
  labelHtmlSafe?: string | null;
  plpPositionClass?: string | null;
  priority?: number | null;
  productSigningBackgroundColour?: string | null;
  productSigningTextColour?: string | null;
  renderAsText?: boolean | null;
  showOnPdp?: boolean | null;
  showOnPlp?: boolean | null;
  image?: { __typename?: 'SigningImage'; url?: string | null } | null;
};

export type LanguageFieldsFragment = {
  __typename?: 'Language';
  active?: boolean | null;
  isocode?: string | null;
  name?: string | null;
  nativeName?: string | null;
};

export type CountryFieldsFragment = {
  __typename?: 'Country';
  addressStreetFormat?: string | null;
  isocode: any;
  name: string;
  phoneCode?: string | null;
  supportedLanguages?: Array<{
    __typename?: 'Language';
    active?: boolean | null;
    isocode?: string | null;
    name?: string | null;
    nativeName?: string | null;
  } | null> | null;
};

export type AddressConfigurationFieldsFragment = {
  __typename?: 'AddressConfiguration';
  enabled?: boolean | null;
  service?: string | null;
};

export type FacetDisplayDataListFieldsFragment = {
  __typename?: 'FacetDisplayDataList';
  code?: string | null;
  displayName?: string | null;
  translated?: boolean | null;
};

export type SortingOptionsFieldsFragment = {
  __typename?: 'SortingOptions';
  code: string;
  name: string;
  nameEN: string;
};

export type ProductTileConfigurationFieldsFragment = {
  __typename?: 'ProductTileConfiguration';
  showHoverImageOnPlp?: boolean | null;
  showHoverImageOnRecommendations?: boolean | null;
  showHoverImageOnRvp?: boolean | null;
  showQuickViewOnPlp?: boolean | null;
  showQuickViewOnRecommendations?: boolean | null;
  showQuickViewOnRvp?: boolean | null;
  showStyleVariantsOnPlp?: boolean | null;
  showStyleVariantsOnRecommendations?: boolean | null;
  showStyleVariantsOnRvp?: boolean | null;
};

export type ColorStylesFieldsFragment = {
  __typename?: 'FacetColorStyleItem';
  key?: string | null;
  value?: string | null;
};

export type ConfigurationFieldsFragment = {
  __typename?: 'Configuration';
  id?: string | null;
  activateNewMyAccount?: boolean | null;
  activeApp?: boolean | null;
  adyenUseSavedPayment?: boolean | null;
  adyenWebDropinEnabled?: boolean | null;
  agreeToPrivacyVisibleOnLogin?: boolean | null;
  agreeToPrivacyVisibleOnLoginCheckout?: boolean | null;
  agreeToPrivacyVisibleOnRegistration?: boolean | null;
  algoliaAppId?: string | null;
  algoliaInsightsApiKey?: string | null;
  algoliaProductPrimaryIndexName?: string | null;
  allowGiftCards?: boolean | null;
  applePayEnabled?: boolean | null;
  applePaySessionTimeout?: number | null;
  applePayVersion?: number | null;
  appStoreId?: string | null;
  appStoreName?: string | null;
  captchaInvisibleSiteKey?: string | null;
  captchaSiteKey?: string | null;
  checkoutV2?: boolean | null;
  cookieWallVersion?: CookieWallVersion | null;
  currentCurrencySymbol?: string | null;
  currentCurrencyNumberFormat?: string | null;
  currentCurrencyIsoCode?: string | null;
  currentCurrencyDigits?: number | null;
  debounceTimeout?: number | null;
  defaultCurrency?: string | null;
  displayFiberRankingOnProductTiles?: boolean | null;
  displayFiberRankingBadgeOnPDP?: boolean | null;
  displayFiberRankingInfoOnPDP?: boolean | null;
  emptyPageLayoutSlotDeliveryKey?: string | null;
  enableAudioEye?: boolean | null;
  enableBodyOnlyWebview?: boolean | null;
  enableCookieSettings?: boolean | null;
  enableCookieWall?: boolean | null;
  enableCookieWallV2?: boolean | null;
  enableCartItemsNotReservedMessage?: boolean | null;
  enableDarkBgImages?: boolean | null;
  enableExponea?: boolean | null;
  enableFitAnalytics?: boolean | null;
  enableHuman?: boolean | null;
  enableKidswearNewsletter?: boolean | null;
  enableNewsletterSubscrOverlayABTest?: boolean | null;
  enablePDPBreadcrumbs?: boolean | null;
  enablePdpChargedReturnsMessage?: boolean | null;
  enablePdpClosestStoreDeliveryOption?: boolean | null;
  enablePdpDeliveryOptions?: boolean | null;
  enablePdpFreeReturnsMessage?: boolean | null;
  enablePdpLocalStoreStockSizeAvailability?: boolean | null;
  enablePdpShippingEstimate?: boolean | null;
  enablePdpShippingEstimateDisclaimer?: boolean | null;
  enablePLPLoadMore?: boolean | null;
  enableQubit?: boolean | null;
  enableQubitPDC?: boolean | null;
  enableSizeGuideProductMeasurements?: boolean | null;
  enableUniversalLinks?: boolean | null;
  enableUPSMyChoiceSite?: boolean | null;
  enableUspScroll?: boolean | null;
  enableWishlist?: boolean | null;
  errorPageLayoutSlotDeliveryKey?: string | null;
  exponeaPushId?: string | null;
  exponeaPushNotificationDelay?: number | null;
  exponeaPushNotificationEnabled?: boolean | null;
  exponeaPushNotificationPageViewNumber?: number | null;
  exponeaPushNotificationRequestPerSession?: number | null;
  exponeaSubDomain?: string | null;
  exponeaTokenId?: string | null;
  exponeaTagExecution?: string | null;
  exponeaSpaOnHashChange?: boolean | null;
  exponeaSpaBanners?: boolean | null;
  exponeaSpaTags?: boolean | null;
  externalTaxEnabled?: boolean | null;
  lowestPriceDisplay?: LowestPriceDisplay | null;
  showLowestPriceText?: boolean | null;
  fiberRanking?: boolean | null;
  giftCardGroupLength?: number | null;
  giftCardGroupSeparator?: string | null;
  giftCardPrefixes?: Array<string | null> | null;
  hasDoubleNewsletterOptIn?: boolean | null;
  hasImplicitTermsAndConditions?: boolean | null;
  hasMyAccount?: boolean | null;
  homepageLayoutSlotDeliveryKey?: string | null;
  isBodyOnlyView?: boolean | null;
  isChangeCountryInBillingAddress?: boolean | null;
  isEnableQrCode?: boolean | null;
  isEnableShareCartEmail?: boolean | null;
  isLineSession?: boolean | null;
  klarnaHighOnlineReturnsSegment?: string | null;
  localeFallback?: string | null;
  loyaltyPointSystem?: string | null;
  masterLayoutSlotDeliveryKey?: string | null;
  myAccountPointHistoryCount?: number | null;
  navigationType?: NavigationType | null;
  net?: boolean | null;
  paymentMethods?: Array<string | null> | null;
  payPalExpressEnabled?: boolean | null;
  plpV2?: boolean | null;
  productBasePriceColor?: string | null;
  qubitEventsEnabled?: boolean | null;
  qubitSmartServeUrl?: string | null;
  qubitTagExecution?: string | null;
  qubitTrackingId?: string | null;
  quickViewOverlayDeliveryKey?: string | null;
  repoId?: string | null;
  requestPdpUspsSFCSizeLevel?: boolean | null;
  showMessageBoxNotifications?: boolean | null;
  siteHasPhysicalStores?: boolean | null;
  siteNameDisplay?: string | null;
  slice?: string | null;
  upsMyChoiceDefaultChecked?: boolean | null;
  verticalFacetNavigation?: boolean | null;
  voucherFormDefaultShown?: boolean | null;
  showStrikethroughPrices?: boolean | null;
  sizeGuideUnit?: SizeGuideUnit | null;
  staticFiltersQuery?: string | null;
  supportedFitType?: SupportedFitType | null;
  enableCLTAnalytics?: boolean | null;
  enableReturnFeeMDDC?: boolean | null;
  salesDiscountsEndDate?: string | null;
  plpSystemTextsDeliveryKey?: string | null;
  forterSiteId?: string | null;
  plpLayoutSlotDeliveryKey?: string | null;
  srpLayoutSlotDeliveryKey?: string | null;
  searchV2?: boolean | null;
  addressAutoCompleteConfiguration?: {
    __typename?: 'AddressConfiguration';
    enabled?: boolean | null;
    service?: string | null;
  } | null;
  addressSuggestionConfiguration?: {
    __typename?: 'AddressConfiguration';
    enabled?: boolean | null;
    service?: string | null;
  } | null;
  facetDisplayDataList?: Array<{
    __typename?: 'FacetDisplayDataList';
    code?: string | null;
    displayName?: string | null;
    translated?: boolean | null;
  } | null> | null;
  sortingOptions?: Array<{
    __typename?: 'SortingOptions';
    code: string;
    name: string;
    nameEN: string;
  }> | null;
  productTileConfiguration?: {
    __typename?: 'ProductTileConfiguration';
    showHoverImageOnPlp?: boolean | null;
    showHoverImageOnRecommendations?: boolean | null;
    showHoverImageOnRvp?: boolean | null;
    showQuickViewOnPlp?: boolean | null;
    showQuickViewOnRecommendations?: boolean | null;
    showQuickViewOnRvp?: boolean | null;
    showStyleVariantsOnPlp?: boolean | null;
    showStyleVariantsOnRecommendations?: boolean | null;
    showStyleVariantsOnRvp?: boolean | null;
  } | null;
  colorStyles?: Array<{
    __typename?: 'FacetColorStyleItem';
    key?: string | null;
    value?: string | null;
  }> | null;
};

export type PhoneDetailsFieldsFragment = {
  __typename?: 'PhoneDetails';
  countryCodeIso?: string | null;
  prefix?: string | null;
};

export type AddressFieldsFragment = {
  __typename?: 'Address';
  verified?: boolean | null;
  phoneDetails?: {
    __typename?: 'PhoneDetails';
    countryCodeIso?: string | null;
    prefix?: string | null;
  } | null;
};

export type AmplienceDeliveryKeyFieldsFragment = {
  __typename?: 'AmplienceDeliveryKey';
  defaultPDPTemplate?: string | null;
  ppcPDPTemplate?: string | null;
};

export type CartCustomerFieldsFragment = {
  __typename?: 'CartCustomer';
  customerId?: string | null;
  customerType?: CustomerType | null;
  email?: string | null;
  loyaltySubscribed?: boolean | null;
};

export type PersonalisedDiscountFieldsFragment = {
  __typename?: 'PersonalisedDiscount';
  formattedValue?: string | null;
  expirationDate?: string | null;
  applicableToDiscountedProducts?: boolean | null;
  trafficSource?: string | null;
};

export type UserFieldsFragment = {
  __typename?: 'User';
  type?: string | null;
  name?: string | null;
  uid: string;
  analyticsCustomerType?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  displayUid?: string | null;
  email?: string | null;
  firstName?: string | null;
  hashedUserId?: string | null;
  hashedUserIp?: string | null;
  internal?: boolean | null;
  lastName?: string | null;
  loyaltySubscribed?: boolean | null;
  memberCard?: string | null;
  thresholdCustomerType?: string | null;
  currency?: {
    __typename?: 'Currency';
    active?: boolean | null;
    isocode?: string | null;
    name?: string | null;
    symbol?: string | null;
    digits?: number | null;
  } | null;
  language?: {
    __typename?: 'Language';
    active?: boolean | null;
    isocode?: string | null;
    name?: string | null;
    nativeName?: string | null;
  } | null;
  personalisedDiscount?: {
    __typename?: 'PersonalisedDiscount';
    formattedValue?: string | null;
    expirationDate?: string | null;
    applicableToDiscountedProducts?: boolean | null;
    trafficSource?: string | null;
  } | null;
};

export type PromotionDetailsFieldsFragment = {
  __typename?: 'PromotionDetails';
  code?: string | null;
  description?: string | null;
  promotionType?: string | null;
  name?: string | null;
};

export type PromotionFieldsFragment = {
  __typename?: 'Promotion';
  description?: string | null;
  discountPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  promotion?: {
    __typename?: 'PromotionDetails';
    code?: string | null;
    description?: string | null;
    promotionType?: string | null;
    name?: string | null;
  } | null;
};

export type TransitDaysFieldsFragment = {
  __typename?: 'TransitDays';
  highestDeliveryDate?: string | null;
  lowerBound?: number | null;
  lowestDeliveryDate?: string | null;
  maxDeliveryDateFormatted?: string | null;
  minDeliveryDateFormatted?: string | null;
  upperBound?: number | null;
};

export type DeliveryModeFieldsFragment = {
  __typename?: 'DeliveryMode';
  code?: string | null;
  deliveryMethod?: string | null;
  icon?: string | null;
  name?: string | null;
  omsDeliveryMethod?: string | null;
  requestCarrierCustomerId?: boolean | null;
  shipper?: string | null;
  description?: string | null;
  deliveryCost?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  transitDays?: {
    __typename?: 'TransitDays';
    highestDeliveryDate?: string | null;
    lowerBound?: number | null;
    lowestDeliveryDate?: string | null;
    maxDeliveryDateFormatted?: string | null;
    minDeliveryDateFormatted?: string | null;
    upperBound?: number | null;
  } | null;
};

export type CartEntryFieldsFragment = {
  __typename?: 'CartEntry';
  entryNumber?: number | null;
  gift?: boolean | null;
  quantity?: number | null;
  removable?: boolean | null;
  updateable?: boolean | null;
  basePrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  fromPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  product?: {
    __typename?: 'CartProduct';
    code?: any | null;
    baseProduct?: string | null;
    ean?: string | null;
    fitEN?: string | null;
    isGiftCard?: boolean | null;
    materialGroup?: string | null;
    name?: string | null;
    nameEN?: string | null;
    oneSize?: boolean | null;
    programEN?: string | null;
    shouldUseDarkBackgroundImgs?: boolean | null;
    staticCategoryPath?: string | null;
    staticCategoryPathIds?: string | null;
    stockNotificationMessageEN?: string | null;
    styleFamilyEN?: string | null;
    subTargetAudience?: string | null;
    url?: string | null;
    simplifiedImages?: Array<{
      __typename?: 'ImageEntry';
      key?: string | null;
      value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
    } | null> | null;
    size?: {
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    mainColor?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    color?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
  } | null;
  taxAmount?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalPriceWithoutDiscount?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

export type CartFieldsFragment = {
  __typename?: 'Cart';
  adyenAmount?: number | null;
  assistedSale?: boolean | null;
  isGsTaxCalculated?: boolean | null;
  isPayPalExpressPayment?: boolean | null;
  validationStep?: string | null;
  code?: string | null;
  externalTaxEnabled?: boolean | null;
  guid?: string | null;
  net?: boolean | null;
  totalItems?: number | null;
  appliedOrderPromotions?: Array<{
    __typename?: 'Promotion';
    description?: string | null;
    discountPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    promotion?: {
      __typename?: 'PromotionDetails';
      code?: string | null;
      description?: string | null;
      promotionType?: string | null;
      name?: string | null;
    } | null;
  } | null> | null;
  potentialOrderPromotions?: Array<{
    __typename?: 'Promotion';
    description?: string | null;
    discountPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    promotion?: {
      __typename?: 'PromotionDetails';
      code?: string | null;
      description?: string | null;
      promotionType?: string | null;
      name?: string | null;
    } | null;
  } | null> | null;
  billingAddress?: {
    __typename?: 'Address';
    verified?: boolean | null;
    phoneDetails?: {
      __typename?: 'PhoneDetails';
      countryCodeIso?: string | null;
      prefix?: string | null;
    } | null;
  } | null;
  customer?: {
    __typename?: 'CartCustomer';
    customerId?: string | null;
    customerType?: CustomerType | null;
    email?: string | null;
    loyaltySubscribed?: boolean | null;
  } | null;
  deliveryAddress?: {
    __typename?: 'Address';
    verified?: boolean | null;
    phoneDetails?: {
      __typename?: 'PhoneDetails';
      countryCodeIso?: string | null;
      prefix?: string | null;
    } | null;
  } | null;
  deliveryCost?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  deliveryMode?: {
    __typename?: 'DeliveryMode';
    code?: string | null;
    deliveryMethod?: string | null;
    icon?: string | null;
    name?: string | null;
    omsDeliveryMethod?: string | null;
    requestCarrierCustomerId?: boolean | null;
    shipper?: string | null;
    description?: string | null;
    deliveryCost?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    transitDays?: {
      __typename?: 'TransitDays';
      highestDeliveryDate?: string | null;
      lowerBound?: number | null;
      lowestDeliveryDate?: string | null;
      maxDeliveryDateFormatted?: string | null;
      minDeliveryDateFormatted?: string | null;
      upperBound?: number | null;
    } | null;
  } | null;
  deliveryTotalTax?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  entries?: Array<{
    __typename?: 'CartEntry';
    entryNumber?: number | null;
    gift?: boolean | null;
    quantity?: number | null;
    removable?: boolean | null;
    updateable?: boolean | null;
    basePrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    product?: {
      __typename?: 'CartProduct';
      code?: any | null;
      baseProduct?: string | null;
      ean?: string | null;
      fitEN?: string | null;
      isGiftCard?: boolean | null;
      materialGroup?: string | null;
      name?: string | null;
      nameEN?: string | null;
      oneSize?: boolean | null;
      programEN?: string | null;
      shouldUseDarkBackgroundImgs?: boolean | null;
      staticCategoryPath?: string | null;
      staticCategoryPathIds?: string | null;
      stockNotificationMessageEN?: string | null;
      styleFamilyEN?: string | null;
      subTargetAudience?: string | null;
      url?: string | null;
      simplifiedImages?: Array<{
        __typename?: 'ImageEntry';
        key?: string | null;
        value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
      } | null> | null;
      size?: {
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null;
      price?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      mainColor?: {
        __typename?: 'Color';
        description?: string | null;
        descriptionEN?: string | null;
        facetColorStyle?: string | null;
      } | null;
      fromPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      color?: {
        __typename?: 'Color';
        description?: string | null;
        descriptionEN?: string | null;
        facetColorStyle?: string | null;
      } | null;
    } | null;
    taxAmount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceWithoutDiscount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
  } | null> | null;
  grandTotalWGCSGross?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  grandTotalWGCSTax?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  grandTotalWGCSNet?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  orderDiscounts?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  subTotal?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalAfterGiftCards?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalDiscounts?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalPrice?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalPriceNoTaxes?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalPriceWithTax?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  totalTax?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
  voucherTotalDiscount?: {
    __typename?: 'Price';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
  } | null;
};

export type CartModificationFieldsFragment = {
  __typename?: 'CartModification';
  quantity?: number | null;
  quantityAdded?: number | null;
  statusCode?: string | null;
  statusMessage?: string | null;
  entry?: {
    __typename?: 'CartEntry';
    entryNumber?: number | null;
    gift?: boolean | null;
    quantity?: number | null;
    removable?: boolean | null;
    updateable?: boolean | null;
    basePrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    product?: {
      __typename?: 'CartProduct';
      code?: any | null;
      baseProduct?: string | null;
      ean?: string | null;
      fitEN?: string | null;
      isGiftCard?: boolean | null;
      materialGroup?: string | null;
      name?: string | null;
      nameEN?: string | null;
      oneSize?: boolean | null;
      programEN?: string | null;
      shouldUseDarkBackgroundImgs?: boolean | null;
      staticCategoryPath?: string | null;
      staticCategoryPathIds?: string | null;
      stockNotificationMessageEN?: string | null;
      styleFamilyEN?: string | null;
      subTargetAudience?: string | null;
      url?: string | null;
      simplifiedImages?: Array<{
        __typename?: 'ImageEntry';
        key?: string | null;
        value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
      } | null> | null;
      size?: {
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null;
      price?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      mainColor?: {
        __typename?: 'Color';
        description?: string | null;
        descriptionEN?: string | null;
        facetColorStyle?: string | null;
      } | null;
      fromPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      color?: {
        __typename?: 'Color';
        description?: string | null;
        descriptionEN?: string | null;
        facetColorStyle?: string | null;
      } | null;
    } | null;
    taxAmount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceWithoutDiscount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
  } | null;
};

export type CartMutationFieldsFragment = {
  __typename?: 'CartMutation';
  type?: string | null;
  cartModifications?: Array<{
    __typename?: 'CartModification';
    quantity?: number | null;
    quantityAdded?: number | null;
    statusCode?: string | null;
    statusMessage?: string | null;
    entry?: {
      __typename?: 'CartEntry';
      entryNumber?: number | null;
      gift?: boolean | null;
      quantity?: number | null;
      removable?: boolean | null;
      updateable?: boolean | null;
      basePrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      fromPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      product?: {
        __typename?: 'CartProduct';
        code?: any | null;
        baseProduct?: string | null;
        ean?: string | null;
        fitEN?: string | null;
        isGiftCard?: boolean | null;
        materialGroup?: string | null;
        name?: string | null;
        nameEN?: string | null;
        oneSize?: boolean | null;
        programEN?: string | null;
        shouldUseDarkBackgroundImgs?: boolean | null;
        staticCategoryPath?: string | null;
        staticCategoryPathIds?: string | null;
        stockNotificationMessageEN?: string | null;
        styleFamilyEN?: string | null;
        subTargetAudience?: string | null;
        url?: string | null;
        simplifiedImages?: Array<{
          __typename?: 'ImageEntry';
          key?: string | null;
          value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
        } | null> | null;
        size?: {
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null;
        price?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        mainColor?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        color?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
      } | null;
      taxAmount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithoutDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null;
  } | null> | null;
  cart?: {
    __typename?: 'Cart';
    adyenAmount?: number | null;
    assistedSale?: boolean | null;
    isGsTaxCalculated?: boolean | null;
    isPayPalExpressPayment?: boolean | null;
    validationStep?: string | null;
    code?: string | null;
    externalTaxEnabled?: boolean | null;
    guid?: string | null;
    net?: boolean | null;
    totalItems?: number | null;
    appliedOrderPromotions?: Array<{
      __typename?: 'Promotion';
      description?: string | null;
      discountPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      promotion?: {
        __typename?: 'PromotionDetails';
        code?: string | null;
        description?: string | null;
        promotionType?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    potentialOrderPromotions?: Array<{
      __typename?: 'Promotion';
      description?: string | null;
      discountPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      promotion?: {
        __typename?: 'PromotionDetails';
        code?: string | null;
        description?: string | null;
        promotionType?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    billingAddress?: {
      __typename?: 'Address';
      verified?: boolean | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        countryCodeIso?: string | null;
        prefix?: string | null;
      } | null;
    } | null;
    customer?: {
      __typename?: 'CartCustomer';
      customerId?: string | null;
      customerType?: CustomerType | null;
      email?: string | null;
      loyaltySubscribed?: boolean | null;
    } | null;
    deliveryAddress?: {
      __typename?: 'Address';
      verified?: boolean | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        countryCodeIso?: string | null;
        prefix?: string | null;
      } | null;
    } | null;
    deliveryCost?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    deliveryMode?: {
      __typename?: 'DeliveryMode';
      code?: string | null;
      deliveryMethod?: string | null;
      icon?: string | null;
      name?: string | null;
      omsDeliveryMethod?: string | null;
      requestCarrierCustomerId?: boolean | null;
      shipper?: string | null;
      description?: string | null;
      deliveryCost?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      transitDays?: {
        __typename?: 'TransitDays';
        highestDeliveryDate?: string | null;
        lowerBound?: number | null;
        lowestDeliveryDate?: string | null;
        maxDeliveryDateFormatted?: string | null;
        minDeliveryDateFormatted?: string | null;
        upperBound?: number | null;
      } | null;
    } | null;
    deliveryTotalTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    entries?: Array<{
      __typename?: 'CartEntry';
      entryNumber?: number | null;
      gift?: boolean | null;
      quantity?: number | null;
      removable?: boolean | null;
      updateable?: boolean | null;
      basePrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      fromPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      product?: {
        __typename?: 'CartProduct';
        code?: any | null;
        baseProduct?: string | null;
        ean?: string | null;
        fitEN?: string | null;
        isGiftCard?: boolean | null;
        materialGroup?: string | null;
        name?: string | null;
        nameEN?: string | null;
        oneSize?: boolean | null;
        programEN?: string | null;
        shouldUseDarkBackgroundImgs?: boolean | null;
        staticCategoryPath?: string | null;
        staticCategoryPathIds?: string | null;
        stockNotificationMessageEN?: string | null;
        styleFamilyEN?: string | null;
        subTargetAudience?: string | null;
        url?: string | null;
        simplifiedImages?: Array<{
          __typename?: 'ImageEntry';
          key?: string | null;
          value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
        } | null> | null;
        size?: {
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null;
        price?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        mainColor?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        color?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
      } | null;
      taxAmount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithoutDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null> | null;
    grandTotalWGCSGross?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    grandTotalWGCSTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    grandTotalWGCSNet?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    orderDiscounts?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    subTotal?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalAfterGiftCards?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalDiscounts?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceNoTaxes?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceWithTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    voucherTotalDiscount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
  } | null;
};

export type ErrorMessageFieldsFragment = { __typename?: 'ErrorMessage'; message: string };

export type VoucherApplyResultFieldsFragment = {
  __typename?: 'VoucherApplyResult';
  type?: string | null;
  cart?: {
    __typename?: 'Cart';
    adyenAmount?: number | null;
    assistedSale?: boolean | null;
    isGsTaxCalculated?: boolean | null;
    isPayPalExpressPayment?: boolean | null;
    validationStep?: string | null;
    code?: string | null;
    externalTaxEnabled?: boolean | null;
    guid?: string | null;
    net?: boolean | null;
    totalItems?: number | null;
    appliedOrderPromotions?: Array<{
      __typename?: 'Promotion';
      description?: string | null;
      discountPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      promotion?: {
        __typename?: 'PromotionDetails';
        code?: string | null;
        description?: string | null;
        promotionType?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    potentialOrderPromotions?: Array<{
      __typename?: 'Promotion';
      description?: string | null;
      discountPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      promotion?: {
        __typename?: 'PromotionDetails';
        code?: string | null;
        description?: string | null;
        promotionType?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    billingAddress?: {
      __typename?: 'Address';
      verified?: boolean | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        countryCodeIso?: string | null;
        prefix?: string | null;
      } | null;
    } | null;
    customer?: {
      __typename?: 'CartCustomer';
      customerId?: string | null;
      customerType?: CustomerType | null;
      email?: string | null;
      loyaltySubscribed?: boolean | null;
    } | null;
    deliveryAddress?: {
      __typename?: 'Address';
      verified?: boolean | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        countryCodeIso?: string | null;
        prefix?: string | null;
      } | null;
    } | null;
    deliveryCost?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    deliveryMode?: {
      __typename?: 'DeliveryMode';
      code?: string | null;
      deliveryMethod?: string | null;
      icon?: string | null;
      name?: string | null;
      omsDeliveryMethod?: string | null;
      requestCarrierCustomerId?: boolean | null;
      shipper?: string | null;
      description?: string | null;
      deliveryCost?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      transitDays?: {
        __typename?: 'TransitDays';
        highestDeliveryDate?: string | null;
        lowerBound?: number | null;
        lowestDeliveryDate?: string | null;
        maxDeliveryDateFormatted?: string | null;
        minDeliveryDateFormatted?: string | null;
        upperBound?: number | null;
      } | null;
    } | null;
    deliveryTotalTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    entries?: Array<{
      __typename?: 'CartEntry';
      entryNumber?: number | null;
      gift?: boolean | null;
      quantity?: number | null;
      removable?: boolean | null;
      updateable?: boolean | null;
      basePrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      fromPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      product?: {
        __typename?: 'CartProduct';
        code?: any | null;
        baseProduct?: string | null;
        ean?: string | null;
        fitEN?: string | null;
        isGiftCard?: boolean | null;
        materialGroup?: string | null;
        name?: string | null;
        nameEN?: string | null;
        oneSize?: boolean | null;
        programEN?: string | null;
        shouldUseDarkBackgroundImgs?: boolean | null;
        staticCategoryPath?: string | null;
        staticCategoryPathIds?: string | null;
        stockNotificationMessageEN?: string | null;
        styleFamilyEN?: string | null;
        subTargetAudience?: string | null;
        url?: string | null;
        simplifiedImages?: Array<{
          __typename?: 'ImageEntry';
          key?: string | null;
          value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
        } | null> | null;
        size?: {
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null;
        price?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        mainColor?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        color?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
      } | null;
      taxAmount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithoutDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null> | null;
    grandTotalWGCSGross?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    grandTotalWGCSTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    grandTotalWGCSNet?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    orderDiscounts?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    subTotal?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalAfterGiftCards?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalDiscounts?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceNoTaxes?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceWithTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    voucherTotalDiscount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
  } | null;
  errors?: Array<{ __typename?: 'ErrorMessage'; message: string } | null> | null;
};

export type WishlistSimplifiedImageDataFieldsFragment = {
  __typename?: 'WishlistSimplifiedImageData';
  E01?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  E02?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  F01?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  F02?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M01?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M02?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M03?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M04?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M05?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M06?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M07?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  M08?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z01?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z02?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z03?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z04?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z05?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z06?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z07?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
  Z08?: {
    __typename?: 'WishlistImageData';
    url?: string | null;
    seoDescription?: string | null;
    productImageMediaTypeEnum?: string | null;
    version?: string | null;
  } | null;
};

export type WishlistProductFieldsFragment = {
  __typename?: 'WishlistProduct';
  code?: string | null;
  staticCategoryPathIds?: string | null;
  url?: string | null;
  discountPercentage?: number | null;
  fitEN?: string | null;
  baseProductStockLevelStatus?: string | null;
  subTargetAudience?: string | null;
  materialGroup?: string | null;
  staticCategoryPath?: string | null;
  displayAlternateHoverImage?: boolean | null;
  name?: string | null;
  isDiscontinued?: boolean | null;
  formattedLowestPrice?: string | null;
  formattedLowestPriceDiscountPercentage?: string | null;
  formattedOriginalPriceDiscountPercentage?: string | null;
  color?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
  fromPrice?: {
    __typename?: 'WishlistPrice';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
    currencyData?: {
      __typename?: 'Currency';
      active?: boolean | null;
      isocode?: string | null;
      name?: string | null;
      symbol?: string | null;
      digits?: number | null;
    } | null;
  } | null;
  productFiberRanking?: {
    __typename?: 'ProductFibers';
    ranking?: string | null;
    score?: number | null;
    fibers?: Array<{
      __typename?: 'Fiber';
      code?: string | null;
      descriptionLong?: string | null;
      descriptionShort?: string | null;
      percentage?: number | null;
      ranking?: string | null;
      score?: number | null;
      type?: string | null;
    } | null> | null;
  } | null;
  price?: {
    __typename?: 'WishlistPrice';
    currencyIso?: string | null;
    formattedValue?: string | null;
    formattedValueWithoutCurrency?: string | null;
    nrDigits?: number | null;
    value?: number | null;
    currencyData?: {
      __typename?: 'Currency';
      active?: boolean | null;
      isocode?: string | null;
      name?: string | null;
      symbol?: string | null;
      digits?: number | null;
    } | null;
  } | null;
  signings?: Array<{
    __typename?: 'Signing';
    code?: string | null;
    discountSigning?: boolean | null;
    label?: string | null;
    labelCssClasses?: string | null;
    labelHtmlSafe?: string | null;
    plpPositionClass?: string | null;
    priority?: number | null;
    productSigningBackgroundColour?: string | null;
    productSigningTextColour?: string | null;
    renderAsText?: boolean | null;
    showOnPdp?: boolean | null;
    showOnPlp?: boolean | null;
    image?: { __typename?: 'SigningImage'; url?: string | null } | null;
  } | null> | null;
  simplifiedImageData?: {
    __typename?: 'WishlistSimplifiedImageData';
    E01?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    E02?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    F01?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    F02?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M01?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M02?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M03?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M04?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M05?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M06?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M07?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    M08?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z01?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z02?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z03?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z04?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z05?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z06?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z07?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
    Z08?: {
      __typename?: 'WishlistImageData';
      url?: string | null;
      seoDescription?: string | null;
      productImageMediaTypeEnum?: string | null;
      version?: string | null;
    } | null;
  } | null;
  mainColor?: {
    __typename?: 'Color';
    description?: string | null;
    descriptionEN?: string | null;
    facetColorStyle?: string | null;
  } | null;
};

export type WishlistPageFieldsFragment = {
  __typename?: 'WishlistPage';
  pageSize?: number | null;
  currentPage?: number | null;
  totalNumberOfResults?: number | null;
  numberOfPages?: number | null;
};

export type WishlistFieldsFragment = {
  __typename?: 'Wishlist';
  pagination?: {
    __typename?: 'WishlistPage';
    pageSize?: number | null;
    currentPage?: number | null;
    totalNumberOfResults?: number | null;
    numberOfPages?: number | null;
  } | null;
  results?: Array<{
    __typename?: 'WishlistProduct';
    code?: string | null;
    staticCategoryPathIds?: string | null;
    url?: string | null;
    discountPercentage?: number | null;
    fitEN?: string | null;
    baseProductStockLevelStatus?: string | null;
    subTargetAudience?: string | null;
    materialGroup?: string | null;
    staticCategoryPath?: string | null;
    displayAlternateHoverImage?: boolean | null;
    name?: string | null;
    isDiscontinued?: boolean | null;
    formattedLowestPrice?: string | null;
    formattedLowestPriceDiscountPercentage?: string | null;
    formattedOriginalPriceDiscountPercentage?: string | null;
    color?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    fromPrice?: {
      __typename?: 'WishlistPrice';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
      currencyData?: {
        __typename?: 'Currency';
        active?: boolean | null;
        isocode?: string | null;
        name?: string | null;
        symbol?: string | null;
        digits?: number | null;
      } | null;
    } | null;
    productFiberRanking?: {
      __typename?: 'ProductFibers';
      ranking?: string | null;
      score?: number | null;
      fibers?: Array<{
        __typename?: 'Fiber';
        code?: string | null;
        descriptionLong?: string | null;
        descriptionShort?: string | null;
        percentage?: number | null;
        ranking?: string | null;
        score?: number | null;
        type?: string | null;
      } | null> | null;
    } | null;
    price?: {
      __typename?: 'WishlistPrice';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
      currencyData?: {
        __typename?: 'Currency';
        active?: boolean | null;
        isocode?: string | null;
        name?: string | null;
        symbol?: string | null;
        digits?: number | null;
      } | null;
    } | null;
    signings?: Array<{
      __typename?: 'Signing';
      code?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      labelCssClasses?: string | null;
      labelHtmlSafe?: string | null;
      plpPositionClass?: string | null;
      priority?: number | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      showOnPlp?: boolean | null;
      image?: { __typename?: 'SigningImage'; url?: string | null } | null;
    } | null> | null;
    simplifiedImageData?: {
      __typename?: 'WishlistSimplifiedImageData';
      E01?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      E02?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      F01?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      F02?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M01?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M02?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M03?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M04?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M05?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M06?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M07?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      M08?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z01?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z02?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z03?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z04?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z05?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z06?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z07?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
      Z08?: {
        __typename?: 'WishlistImageData';
        url?: string | null;
        seoDescription?: string | null;
        productImageMediaTypeEnum?: string | null;
        version?: string | null;
      } | null;
    } | null;
    mainColor?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
  } | null> | null;
};

export type CommonDataFieldsFragment = {
  __typename?: 'CommonData';
  internal?: boolean | null;
  gender?: string | null;
  newsletterUser?: boolean | null;
  ampliencePreviewTime?: string | null;
  customerType?: string | null;
  assistedSaleUserType?: string | null;
  customerId?: string | null;
  correlationId?: string | null;
  assistedSaleUserID?: string | null;
  amplienceTSP?: string | null;
  hashedUserId?: string | null;
  amplienceVSE?: string | null;
  numberOfWishListItems?: number | null;
  thresholdCustomerType?: string | null;
  accountType?: string | null;
  asmInstoreLocale?: string | null;
  hashedIp?: string | null;
  ageGroup?: string | null;
  loggedInStatus?: boolean | null;
  firstName?: string | null;
  csrfToken?: any | null;
  msdAbTestingEnabled?: boolean | null;
  assistedSale?: boolean | null;
  isAmpliencePreview?: boolean | null;
  gsOrigin?: string | null;
};

export type SizeGuidesLabelTranslationsFieldsFragment = {
  __typename?: 'SizeGuidesLabelTranslations';
  arm?: string | null;
  chest?: string | null;
  foot?: string | null;
  hip?: string | null;
  inseam?: string | null;
  length?: string | null;
  size?: string | null;
  waist?: string | null;
};

export type SizeGuideMeasuresystemTableItemFieldsFragment = {
  __typename?: 'SizeGuideMeasuresystemTableItem';
  tableHtml?: string | null;
};

export type SizeGuideMeasuresystemFieldsFragment = {
  __typename?: 'SizeGuideMeasuresystem';
  code?: string | null;
  name?: string | null;
  tables?: Array<{
    __typename?: 'SizeGuideMeasuresystemTableItem';
    tableHtml?: string | null;
  } | null> | null;
};

export type SizeGuideCategoryFieldsFragment = {
  __typename?: 'SizeGuideCategory';
  code?: string | null;
  descriptions?: Array<string | null> | null;
  name?: string | null;
  measuresystems?: Array<{
    __typename?: 'SizeGuideMeasuresystem';
    code?: string | null;
    name?: string | null;
    tables?: Array<{
      __typename?: 'SizeGuideMeasuresystemTableItem';
      tableHtml?: string | null;
    } | null> | null;
  } | null> | null;
};

export type SizeGuideFieldsFragment = {
  __typename?: 'SizeGuide';
  code?: string | null;
  gender?: string | null;
  name?: string | null;
  categories?: Array<{
    __typename?: 'SizeGuideCategory';
    code?: string | null;
    descriptions?: Array<string | null> | null;
    name?: string | null;
    measuresystems?: Array<{
      __typename?: 'SizeGuideMeasuresystem';
      code?: string | null;
      name?: string | null;
      tables?: Array<{
        __typename?: 'SizeGuideMeasuresystemTableItem';
        tableHtml?: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SizeGuidesFieldsFragment = {
  __typename?: 'SizeGuides';
  id?: string | null;
  sizeUnit?: SizeGuideUnit | null;
  labelTranslations?: {
    __typename?: 'SizeGuidesLabelTranslations';
    arm?: string | null;
    chest?: string | null;
    foot?: string | null;
    hip?: string | null;
    inseam?: string | null;
    length?: string | null;
    size?: string | null;
    waist?: string | null;
  } | null;
  sizeGuides?: Array<{
    __typename?: 'SizeGuide';
    code?: string | null;
    gender?: string | null;
    name?: string | null;
    categories?: Array<{
      __typename?: 'SizeGuideCategory';
      code?: string | null;
      descriptions?: Array<string | null> | null;
      name?: string | null;
      measuresystems?: Array<{
        __typename?: 'SizeGuideMeasuresystem';
        code?: string | null;
        name?: string | null;
        tables?: Array<{
          __typename?: 'SizeGuideMeasuresystemTableItem';
          tableHtml?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type NavigationFieldsFragment = {
  __typename?: 'Navigation';
  id?: string | null;
  navigation?: Array<{
    __typename?: 'NavigationItem';
    hideForNonLoggedInVisitors?: boolean | null;
    hideForLoggedInVisitors?: boolean | null;
    label?: string | null;
    labelAria?: string | null;
    styleModifier?: string | null;
    target?: LinkTarget | null;
    url?: string | null;
    uid?: string | null;
    children?: Array<{
      __typename?: 'NavigationItem';
      hideForNonLoggedInVisitors?: boolean | null;
      hideForLoggedInVisitors?: boolean | null;
      label?: string | null;
      labelAria?: string | null;
      styleModifier?: string | null;
      target?: LinkTarget | null;
      url?: string | null;
      uid?: string | null;
      children?: Array<{
        __typename?: 'NavigationItem';
        hideForNonLoggedInVisitors?: boolean | null;
        hideForLoggedInVisitors?: boolean | null;
        label?: string | null;
        labelAria?: string | null;
        styleModifier?: string | null;
        target?: LinkTarget | null;
        url?: string | null;
        uid?: string | null;
        children?: Array<{
          __typename?: 'NavigationItem';
          hideForNonLoggedInVisitors?: boolean | null;
          hideForLoggedInVisitors?: boolean | null;
          label?: string | null;
          labelAria?: string | null;
          styleModifier?: string | null;
          target?: LinkTarget | null;
          url?: string | null;
          uid?: string | null;
          children?: Array<{
            __typename?: 'NavigationItem';
            hideForNonLoggedInVisitors?: boolean | null;
            hideForLoggedInVisitors?: boolean | null;
            label?: string | null;
            labelAria?: string | null;
            styleModifier?: string | null;
            target?: LinkTarget | null;
            url?: string | null;
            uid?: string | null;
            children?: Array<{
              __typename?: 'NavigationItem';
              hideForNonLoggedInVisitors?: boolean | null;
              hideForLoggedInVisitors?: boolean | null;
              label?: string | null;
              labelAria?: string | null;
              styleModifier?: string | null;
              target?: LinkTarget | null;
              url?: string | null;
              uid?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type NavigationItemFieldsFragment = {
  __typename?: 'NavigationItem';
  hideForNonLoggedInVisitors?: boolean | null;
  hideForLoggedInVisitors?: boolean | null;
  label?: string | null;
  labelAria?: string | null;
  styleModifier?: string | null;
  target?: LinkTarget | null;
  url?: string | null;
  uid?: string | null;
};

export type NavigationItemRecursiveFieldsFragment = {
  __typename?: 'NavigationItem';
  hideForNonLoggedInVisitors?: boolean | null;
  hideForLoggedInVisitors?: boolean | null;
  label?: string | null;
  labelAria?: string | null;
  styleModifier?: string | null;
  target?: LinkTarget | null;
  url?: string | null;
  uid?: string | null;
  children?: Array<{
    __typename?: 'NavigationItem';
    hideForNonLoggedInVisitors?: boolean | null;
    hideForLoggedInVisitors?: boolean | null;
    label?: string | null;
    labelAria?: string | null;
    styleModifier?: string | null;
    target?: LinkTarget | null;
    url?: string | null;
    uid?: string | null;
    children?: Array<{
      __typename?: 'NavigationItem';
      hideForNonLoggedInVisitors?: boolean | null;
      hideForLoggedInVisitors?: boolean | null;
      label?: string | null;
      labelAria?: string | null;
      styleModifier?: string | null;
      target?: LinkTarget | null;
      url?: string | null;
      uid?: string | null;
      children?: Array<{
        __typename?: 'NavigationItem';
        hideForNonLoggedInVisitors?: boolean | null;
        hideForLoggedInVisitors?: boolean | null;
        label?: string | null;
        labelAria?: string | null;
        styleModifier?: string | null;
        target?: LinkTarget | null;
        url?: string | null;
        uid?: string | null;
        children?: Array<{
          __typename?: 'NavigationItem';
          hideForNonLoggedInVisitors?: boolean | null;
          hideForLoggedInVisitors?: boolean | null;
          label?: string | null;
          labelAria?: string | null;
          styleModifier?: string | null;
          target?: LinkTarget | null;
          url?: string | null;
          uid?: string | null;
          children?: Array<{
            __typename?: 'NavigationItem';
            hideForNonLoggedInVisitors?: boolean | null;
            hideForLoggedInVisitors?: boolean | null;
            label?: string | null;
            labelAria?: string | null;
            styleModifier?: string | null;
            target?: LinkTarget | null;
            url?: string | null;
            uid?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SubscriptionErrorFieldsFragment = {
  __typename?: 'SubscriptionError';
  code?: string | null;
  message: string;
  reason?: string | null;
  subject?: string | null;
  subjectType?: string | null;
  type?: string | null;
};

export type SubscriptionFieldsFragment = {
  __typename?: 'Subscription';
  type: string;
  newsletterSubscribeSuccessType: NewsletterSubscribeSuccessType;
  subscribeStatusCode?: SubscribeStatusCode | null;
  errors?: Array<{
    __typename?: 'SubscriptionError';
    code?: string | null;
    message: string;
    reason?: string | null;
    subject?: string | null;
    subjectType?: string | null;
    type?: string | null;
  } | null> | null;
};

export type ShippingEstimatesFieldsFragment = {
  __typename?: 'ShippingEstimates';
  id?: string | null;
  description?: string | null;
};

export type CategoryPathsFieldsFragment = {
  __typename?: 'CategoryPaths';
  categories?: Array<string | null> | null;
};

export type CategorySortingFieldsFragment = {
  __typename?: 'CategorySorting';
  code?: string | null;
  name?: string | null;
  nameEN?: string | null;
  selected?: boolean | null;
  url?: string | null;
};

export type FacetValueFieldsFragment = {
  __typename?: 'FacetValue';
  code?: string | null;
  count?: number | null;
  defaultValue?: string | null;
  name?: string | null;
  selected?: boolean | null;
  url?: string | null;
};

export type VisibleFacetFieldsFragment = {
  __typename?: 'VisibleFacet';
  code?: string | null;
  name?: string | null;
  removeUrl?: string | null;
  facetValues?: Array<{
    __typename?: 'FacetValue';
    code?: string | null;
    count?: number | null;
    defaultValue?: string | null;
    name?: string | null;
    selected?: boolean | null;
    url?: string | null;
  } | null> | null;
};

export type CategoryDetailsFieldsFragment = {
  __typename?: 'CategoryDetails';
  algoliaIndexName?: string | null;
  algoliaQueryId?: string | null;
  canonicalUrl?: string | null;
  categoryCode?: string | null;
  categoryName?: string | null;
  clearAllUrl?: string | null;
  h1?: string | null;
  metaDescription?: string | null;
  metaKeywords?: string | null;
  pageSize?: number | null;
  pageTitle?: string | null;
  plpAppUrl?: string | null;
  plpCategoryPath?: string | null;
  plpCategoryPathIds?: string | null;
  requestUrl?: string | null;
  selectedFacets?: Array<{
    __typename?: 'VisibleFacet';
    code?: string | null;
    name?: string | null;
    removeUrl?: string | null;
    facetValues?: Array<{
      __typename?: 'FacetValue';
      code?: string | null;
      count?: number | null;
      defaultValue?: string | null;
      name?: string | null;
      selected?: boolean | null;
      url?: string | null;
    } | null> | null;
  } | null> | null;
  sorting?: Array<{
    __typename?: 'CategorySorting';
    code?: string | null;
    name?: string | null;
    nameEN?: string | null;
    selected?: boolean | null;
    url?: string | null;
  } | null> | null;
  visibleFacets?: Array<{
    __typename?: 'VisibleFacet';
    code?: string | null;
    name?: string | null;
    removeUrl?: string | null;
    facetValues?: Array<{
      __typename?: 'FacetValue';
      code?: string | null;
      count?: number | null;
      defaultValue?: string | null;
      name?: string | null;
      selected?: boolean | null;
      url?: string | null;
    } | null> | null;
  } | null> | null;
};

export type ExponeaCustomerAttributesResponseFieldsFragment = {
  __typename?: 'ExponeaCustomerAttributesResponse';
  results?: Array<{
    __typename?: 'ExponeaCustomerAttributes';
    value?: boolean | null;
  } | null> | null;
};

export type ProductQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    algoliaObjectId?: string | null;
    id?: string | null;
    analyticsCategory?: string | null;
    approvalStatus?: ApprovalStatus | null;
    appUrl?: string | null;
    baseProduct?: string | null;
    baseProductStockNotificationMessageEN?: string | null;
    canonicalUrl?: string | null;
    code?: any | null;
    colorFinishDescription?: string | null;
    colorFinishTitle?: string | null;
    colorPLPUrl?: string | null;
    comingSoon?: boolean | null;
    defaultPDPTemplate?: string | null;
    detailsBullets?: Array<string | null> | null;
    detailsTitle?: string | null;
    dimensionsBullets?: Array<string | null> | null;
    ean?: string | null;
    fabricBullets?: Array<string | null> | null;
    fabricDescription?: string | null;
    fabricHeader?: string | null;
    fabricTitle?: string | null;
    factoryId?: string | null;
    featuresIntro?: string | null;
    featuresTitle?: string | null;
    fit?: string | null;
    fitEN?: string | null;
    formattedLowestPrice?: string | null;
    hasLocalStoreStock?: boolean | null;
    isGiftCard?: boolean | null;
    isProductHasReview?: boolean | null;
    materialGroup?: string | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    metaTitle?: string | null;
    mccValue?: string | null;
    name?: string | null;
    nameEN?: string | null;
    oneSize?: boolean | null;
    ppcPDPTemplate?: string | null;
    programDescription?: string | null;
    programEN?: string | null;
    programTitle?: string | null;
    purchasable?: boolean | null;
    salesDiscountsEndDate?: string | null;
    seoName?: string | null;
    shouldRequestSizeMeasurements?: boolean | null;
    shouldUseDarkBackgroundImgs?: boolean | null;
    showStoreStockLocator?: boolean | null;
    sizeChartUrl?: string | null;
    staticCategoryPath?: string | null;
    staticCategoryPathIds?: string | null;
    staticCategoryPathSeo?: string | null;
    stockNotificationLink?: string | null;
    stockNotificationMessageEN?: string | null;
    styleDescription?: string | null;
    styleFamilyEN?: string | null;
    subTargetAudience?: string | null;
    supplierReference?: string | null;
    url?: string | null;
    useExternalUrl?: boolean | null;
    availableStyleVariantsSorted?: Array<{
      __typename?: 'StyleVariant';
      code?: string | null;
      color?: string | null;
      facetColorStyle?: string | null;
      formattedBasePrice?: string | null;
      formattedFromPrice?: string | null;
      formattedIntermediaryPrice?: string | null;
      formattedLowestPrice?: string | null;
      isProductHasReviews?: boolean | null;
      mainColor?: string | null;
      name?: string | null;
      productAppUrl?: string | null;
      productFiberRankingJson?: string | null;
      productUrl?: string | null;
      unformattedBasePrice?: string | null;
      unformattedFromPrice?: string | null;
      unformattedIntermediaryPrice?: string | null;
      images?: {
        __typename?: 'StyleVariantImages';
        large?: string | null;
        primaryImage?: string | null;
        swatch?: string | null;
        thumbnail?: string | null;
        thumbnailAltText?: string | null;
      } | null;
      signing?: {
        __typename?: 'StyleVariantSigning';
        backgroundColour?: string | null;
        cssClasses?: string | null;
        discountSigning?: boolean | null;
        label?: string | null;
        productSigningBackgroundColour?: string | null;
        productSigningTextColour?: string | null;
        renderAsText?: boolean | null;
        showOnPdp?: boolean | null;
        textColour?: string | null;
      } | null;
    } | null> | null;
    breadcrumbs?: Array<{
      __typename?: 'Breadcrumb';
      name?: string | null;
      url?: string | null;
    } | null> | null;
    color?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    deliveryOptions?: {
      __typename?: 'DeliveryOptions';
      deliveryOptions?: Array<{
        __typename?: 'DeliveryOption';
        deliveryCost?: number | null;
        icon?: string | null;
        name?: string | null;
      } | null> | null;
    } | null;
    explodingView?: {
      __typename?: 'ExplodingView';
      explodingViewDescription?: string | null;
      explodingViewSvgUrl?: string | null;
      explodingViewTitle?: string | null;
    } | null;
    fiberRanking?: {
      __typename?: 'FiberRanking';
      productAudience?: string | null;
      fiberRanking?: {
        __typename?: 'FiberRankingData';
        genericFiberRankingData?: {
          __typename?: 'GenericFibers';
          fibers?: Array<{
            __typename?: 'Fiber';
            code?: string | null;
            descriptionLong?: string | null;
            descriptionShort?: string | null;
            percentage?: number | null;
            ranking?: string | null;
            score?: number | null;
            type?: string | null;
          } | null> | null;
        } | null;
        productFiberRankingData?: {
          __typename?: 'ProductFibers';
          ranking?: string | null;
          score?: number | null;
          fibers?: Array<{
            __typename?: 'Fiber';
            code?: string | null;
            descriptionLong?: string | null;
            descriptionShort?: string | null;
            percentage?: number | null;
            ranking?: string | null;
            score?: number | null;
            type?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    intermediaryPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    mainColor?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    mannequin?: {
      __typename?: 'Mannequin';
      imperialMessage?: string | null;
      metricMessage?: string | null;
      additionalSizeInfo?: string | null;
    } | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    returnFee?: {
      __typename?: 'ReturnFee';
      amount?: string | null;
      color?: string | null;
      message?: string | null;
      returnType?: ReturnFeeType | null;
    } | null;
    signings?: Array<{
      __typename?: 'Signing';
      code?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      labelCssClasses?: string | null;
      labelHtmlSafe?: string | null;
      plpPositionClass?: string | null;
      priority?: number | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      showOnPlp?: boolean | null;
      image?: { __typename?: 'SigningImage'; url?: string | null } | null;
    } | null> | null;
    simplifiedImages?: {
      __typename?: 'SimplifiedImageEntry';
      entry?: Array<{
        __typename?: 'ImageEntry';
        key?: string | null;
        value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
      } | null> | null;
    } | null;
    size?: {
      __typename?: 'VariantSize';
      formattedValue?: string | null;
      value?: string | null;
    } | null;
    sizeInformation?: {
      __typename?: 'SizeInformation';
      sizeType?: SizeType | null;
      groupedSizes?: Array<{
        __typename?: 'GroupedSize';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        sizes?: Array<{
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null> | null;
      } | null> | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null;
    stockInformation?: {
      __typename?: 'StockLevel';
      stockLevel?: StockLevelMessageKey | null;
      stockMessage?: string | null;
      stockQuantity?: number | null;
      stockLevelMessages?: Array<{
        __typename?: 'StockLevelMessage';
        key?: StockLevelMessageKey | null;
        value?: string | null;
      } | null> | null;
      stockMapping?: Array<{
        __typename?: 'StockMapping';
        key?: string | null;
        value?: {
          __typename?: 'StockMappingValue';
          thresholdsLeft?: Array<{
            __typename?: 'StockValue';
            stockLevel?: StockLevelMessageKey | null;
            stockQuantity?: number | null;
          } | null> | null;
          thresholdsRight?: Array<{
            __typename?: 'StockValues';
            key?: string | null;
            value?: {
              __typename?: 'StockValue';
              stockLevel?: StockLevelMessageKey | null;
              stockQuantity?: number | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    sustainability?: {
      __typename?: 'Sustainability';
      cradleToCradle?: string | null;
      presenceOfPlasticMicroFiber?: boolean | null;
      madeToLast?: boolean | null;
    } | null;
    traceability?: {
      __typename?: 'Traceability';
      supplierName?: string | null;
      garmentSupplierID?: number | null;
      fabricSupplierID?: number | null;
      garmentManufacturing?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      garmentProcessingDye?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      garmentProcessingEmbroidery?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      garmentProcessingPrint?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      garmentProcessingWash?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      fabricManufacturingWeavingKnitting?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      fabricProcessingDye?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      fabricProcessingEmbroidery?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
      fabricProcessingPrint?: {
        __typename?: 'Factory';
        id?: string | null;
        name?: string | null;
        country?: string | null;
      } | null;
    } | null;
    videos?: Array<{
      __typename?: 'Video';
      id?: string | null;
      mode?: VideoMode | null;
      type?: string | null;
    } | null> | null;
    washingLabels?: Array<{
      __typename?: 'WashingLabel';
      iconCssClass?: string | null;
      label?: string | null;
    } | null> | null;
  };
};

export type StyleVariantsQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type StyleVariantsQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    availableStyleVariantsSorted?: Array<{
      __typename?: 'StyleVariant';
      code?: string | null;
      color?: string | null;
      facetColorStyle?: string | null;
      formattedBasePrice?: string | null;
      formattedFromPrice?: string | null;
      formattedIntermediaryPrice?: string | null;
      formattedLowestPrice?: string | null;
      isProductHasReviews?: boolean | null;
      mainColor?: string | null;
      name?: string | null;
      productAppUrl?: string | null;
      productFiberRankingJson?: string | null;
      productUrl?: string | null;
      unformattedBasePrice?: string | null;
      unformattedFromPrice?: string | null;
      unformattedIntermediaryPrice?: string | null;
      images?: {
        __typename?: 'StyleVariantImages';
        large?: string | null;
        primaryImage?: string | null;
        swatch?: string | null;
        thumbnail?: string | null;
        thumbnailAltText?: string | null;
      } | null;
      signing?: {
        __typename?: 'StyleVariantSigning';
        backgroundColour?: string | null;
        cssClasses?: string | null;
        discountSigning?: boolean | null;
        label?: string | null;
        productSigningBackgroundColour?: string | null;
        productSigningTextColour?: string | null;
        renderAsText?: boolean | null;
        showOnPdp?: boolean | null;
        textColour?: string | null;
      } | null;
    } | null> | null;
  };
};

export type CoreProductInfoQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type CoreProductInfoQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    url?: string | null;
    approvalStatus?: ApprovalStatus | null;
    code?: any | null;
    name?: string | null;
  };
};

export type ProductDynamicDataQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type ProductDynamicDataQuery = {
  __typename?: 'Query';
  productDynamicData: {
    __typename?: 'ProductDynamicData';
    comingSoon?: boolean | null;
    formattedLowestPrice?: string | null;
    purchasable?: boolean | null;
    showStoreStockLocator?: boolean | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    intermediaryPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    sizeInformation?: {
      __typename?: 'SizeInformation';
      sizeType?: SizeType | null;
      groupedSizes?: Array<{
        __typename?: 'GroupedSize';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        sizes?: Array<{
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null> | null;
      } | null> | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null;
    stockInformation?: {
      __typename?: 'StockLevel';
      stockLevel?: StockLevelMessageKey | null;
      stockMessage?: string | null;
      stockQuantity?: number | null;
      stockLevelMessages?: Array<{
        __typename?: 'StockLevelMessage';
        key?: StockLevelMessageKey | null;
        value?: string | null;
      } | null> | null;
      stockMapping?: Array<{
        __typename?: 'StockMapping';
        key?: string | null;
        value?: {
          __typename?: 'StockMappingValue';
          thresholdsLeft?: Array<{
            __typename?: 'StockValue';
            stockLevel?: StockLevelMessageKey | null;
            stockQuantity?: number | null;
          } | null> | null;
          thresholdsRight?: Array<{
            __typename?: 'StockValues';
            key?: string | null;
            value?: {
              __typename?: 'StockValue';
              stockLevel?: StockLevelMessageKey | null;
              stockQuantity?: number | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  };
};

export type CartQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type CartQuery = {
  __typename?: 'Query';
  cart?: {
    __typename?: 'Cart';
    adyenAmount?: number | null;
    assistedSale?: boolean | null;
    isGsTaxCalculated?: boolean | null;
    isPayPalExpressPayment?: boolean | null;
    validationStep?: string | null;
    code?: string | null;
    externalTaxEnabled?: boolean | null;
    guid?: string | null;
    net?: boolean | null;
    totalItems?: number | null;
    appliedOrderPromotions?: Array<{
      __typename?: 'Promotion';
      description?: string | null;
      discountPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      promotion?: {
        __typename?: 'PromotionDetails';
        code?: string | null;
        description?: string | null;
        promotionType?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    potentialOrderPromotions?: Array<{
      __typename?: 'Promotion';
      description?: string | null;
      discountPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      promotion?: {
        __typename?: 'PromotionDetails';
        code?: string | null;
        description?: string | null;
        promotionType?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    billingAddress?: {
      __typename?: 'Address';
      verified?: boolean | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        countryCodeIso?: string | null;
        prefix?: string | null;
      } | null;
    } | null;
    customer?: {
      __typename?: 'CartCustomer';
      customerId?: string | null;
      customerType?: CustomerType | null;
      email?: string | null;
      loyaltySubscribed?: boolean | null;
    } | null;
    deliveryAddress?: {
      __typename?: 'Address';
      verified?: boolean | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        countryCodeIso?: string | null;
        prefix?: string | null;
      } | null;
    } | null;
    deliveryCost?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    deliveryMode?: {
      __typename?: 'DeliveryMode';
      code?: string | null;
      deliveryMethod?: string | null;
      icon?: string | null;
      name?: string | null;
      omsDeliveryMethod?: string | null;
      requestCarrierCustomerId?: boolean | null;
      shipper?: string | null;
      description?: string | null;
      deliveryCost?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      transitDays?: {
        __typename?: 'TransitDays';
        highestDeliveryDate?: string | null;
        lowerBound?: number | null;
        lowestDeliveryDate?: string | null;
        maxDeliveryDateFormatted?: string | null;
        minDeliveryDateFormatted?: string | null;
        upperBound?: number | null;
      } | null;
    } | null;
    deliveryTotalTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    entries?: Array<{
      __typename?: 'CartEntry';
      entryNumber?: number | null;
      gift?: boolean | null;
      quantity?: number | null;
      removable?: boolean | null;
      updateable?: boolean | null;
      basePrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      fromPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      product?: {
        __typename?: 'CartProduct';
        code?: any | null;
        baseProduct?: string | null;
        ean?: string | null;
        fitEN?: string | null;
        isGiftCard?: boolean | null;
        materialGroup?: string | null;
        name?: string | null;
        nameEN?: string | null;
        oneSize?: boolean | null;
        programEN?: string | null;
        shouldUseDarkBackgroundImgs?: boolean | null;
        staticCategoryPath?: string | null;
        staticCategoryPathIds?: string | null;
        stockNotificationMessageEN?: string | null;
        styleFamilyEN?: string | null;
        subTargetAudience?: string | null;
        url?: string | null;
        simplifiedImages?: Array<{
          __typename?: 'ImageEntry';
          key?: string | null;
          value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
        } | null> | null;
        size?: {
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null;
        price?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        mainColor?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        color?: {
          __typename?: 'Color';
          description?: string | null;
          descriptionEN?: string | null;
          facetColorStyle?: string | null;
        } | null;
      } | null;
      taxAmount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithoutDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null> | null;
    grandTotalWGCSGross?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    grandTotalWGCSTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    grandTotalWGCSNet?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    orderDiscounts?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    subTotal?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalAfterGiftCards?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalDiscounts?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceNoTaxes?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalPriceWithTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    totalTax?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    voucherTotalDiscount?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
  } | null;
};

export type WishlistQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type WishlistQuery = {
  __typename?: 'Query';
  wishlist?: {
    __typename?: 'Wishlist';
    pagination?: {
      __typename?: 'WishlistPage';
      pageSize?: number | null;
      currentPage?: number | null;
      totalNumberOfResults?: number | null;
      numberOfPages?: number | null;
    } | null;
    results?: Array<{
      __typename?: 'WishlistProduct';
      code?: string | null;
      staticCategoryPathIds?: string | null;
      url?: string | null;
      discountPercentage?: number | null;
      fitEN?: string | null;
      baseProductStockLevelStatus?: string | null;
      subTargetAudience?: string | null;
      materialGroup?: string | null;
      staticCategoryPath?: string | null;
      displayAlternateHoverImage?: boolean | null;
      name?: string | null;
      isDiscontinued?: boolean | null;
      formattedLowestPrice?: string | null;
      formattedLowestPriceDiscountPercentage?: string | null;
      formattedOriginalPriceDiscountPercentage?: string | null;
      color?: {
        __typename?: 'Color';
        description?: string | null;
        descriptionEN?: string | null;
        facetColorStyle?: string | null;
      } | null;
      fromPrice?: {
        __typename?: 'WishlistPrice';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
        currencyData?: {
          __typename?: 'Currency';
          active?: boolean | null;
          isocode?: string | null;
          name?: string | null;
          symbol?: string | null;
          digits?: number | null;
        } | null;
      } | null;
      productFiberRanking?: {
        __typename?: 'ProductFibers';
        ranking?: string | null;
        score?: number | null;
        fibers?: Array<{
          __typename?: 'Fiber';
          code?: string | null;
          descriptionLong?: string | null;
          descriptionShort?: string | null;
          percentage?: number | null;
          ranking?: string | null;
          score?: number | null;
          type?: string | null;
        } | null> | null;
      } | null;
      price?: {
        __typename?: 'WishlistPrice';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
        currencyData?: {
          __typename?: 'Currency';
          active?: boolean | null;
          isocode?: string | null;
          name?: string | null;
          symbol?: string | null;
          digits?: number | null;
        } | null;
      } | null;
      signings?: Array<{
        __typename?: 'Signing';
        code?: string | null;
        discountSigning?: boolean | null;
        label?: string | null;
        labelCssClasses?: string | null;
        labelHtmlSafe?: string | null;
        plpPositionClass?: string | null;
        priority?: number | null;
        productSigningBackgroundColour?: string | null;
        productSigningTextColour?: string | null;
        renderAsText?: boolean | null;
        showOnPdp?: boolean | null;
        showOnPlp?: boolean | null;
        image?: { __typename?: 'SigningImage'; url?: string | null } | null;
      } | null> | null;
      simplifiedImageData?: {
        __typename?: 'WishlistSimplifiedImageData';
        E01?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        E02?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        F01?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        F02?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M01?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M02?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M03?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M04?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M05?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M06?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M07?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        M08?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z01?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z02?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z03?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z04?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z05?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z06?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z07?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
        Z08?: {
          __typename?: 'WishlistImageData';
          url?: string | null;
          seoDescription?: string | null;
          productImageMediaTypeEnum?: string | null;
          version?: string | null;
        } | null;
      } | null;
      mainColor?: {
        __typename?: 'Color';
        description?: string | null;
        descriptionEN?: string | null;
        facetColorStyle?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ConfigurationQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type ConfigurationQuery = {
  __typename?: 'Query';
  configuration: {
    __typename?: 'Configuration';
    id?: string | null;
    activateNewMyAccount?: boolean | null;
    activeApp?: boolean | null;
    adyenUseSavedPayment?: boolean | null;
    adyenWebDropinEnabled?: boolean | null;
    agreeToPrivacyVisibleOnLogin?: boolean | null;
    agreeToPrivacyVisibleOnLoginCheckout?: boolean | null;
    agreeToPrivacyVisibleOnRegistration?: boolean | null;
    algoliaAppId?: string | null;
    algoliaInsightsApiKey?: string | null;
    algoliaProductPrimaryIndexName?: string | null;
    allowGiftCards?: boolean | null;
    applePayEnabled?: boolean | null;
    applePaySessionTimeout?: number | null;
    applePayVersion?: number | null;
    appStoreId?: string | null;
    appStoreName?: string | null;
    captchaInvisibleSiteKey?: string | null;
    captchaSiteKey?: string | null;
    checkoutV2?: boolean | null;
    cookieWallVersion?: CookieWallVersion | null;
    currentCurrencySymbol?: string | null;
    currentCurrencyNumberFormat?: string | null;
    currentCurrencyIsoCode?: string | null;
    currentCurrencyDigits?: number | null;
    debounceTimeout?: number | null;
    defaultCurrency?: string | null;
    displayFiberRankingOnProductTiles?: boolean | null;
    displayFiberRankingBadgeOnPDP?: boolean | null;
    displayFiberRankingInfoOnPDP?: boolean | null;
    emptyPageLayoutSlotDeliveryKey?: string | null;
    enableAudioEye?: boolean | null;
    enableBodyOnlyWebview?: boolean | null;
    enableCookieSettings?: boolean | null;
    enableCookieWall?: boolean | null;
    enableCookieWallV2?: boolean | null;
    enableCartItemsNotReservedMessage?: boolean | null;
    enableDarkBgImages?: boolean | null;
    enableExponea?: boolean | null;
    enableFitAnalytics?: boolean | null;
    enableHuman?: boolean | null;
    enableKidswearNewsletter?: boolean | null;
    enableNewsletterSubscrOverlayABTest?: boolean | null;
    enablePDPBreadcrumbs?: boolean | null;
    enablePdpChargedReturnsMessage?: boolean | null;
    enablePdpClosestStoreDeliveryOption?: boolean | null;
    enablePdpDeliveryOptions?: boolean | null;
    enablePdpFreeReturnsMessage?: boolean | null;
    enablePdpLocalStoreStockSizeAvailability?: boolean | null;
    enablePdpShippingEstimate?: boolean | null;
    enablePdpShippingEstimateDisclaimer?: boolean | null;
    enablePLPLoadMore?: boolean | null;
    enableQubit?: boolean | null;
    enableQubitPDC?: boolean | null;
    enableSizeGuideProductMeasurements?: boolean | null;
    enableUniversalLinks?: boolean | null;
    enableUPSMyChoiceSite?: boolean | null;
    enableUspScroll?: boolean | null;
    enableWishlist?: boolean | null;
    errorPageLayoutSlotDeliveryKey?: string | null;
    exponeaPushId?: string | null;
    exponeaPushNotificationDelay?: number | null;
    exponeaPushNotificationEnabled?: boolean | null;
    exponeaPushNotificationPageViewNumber?: number | null;
    exponeaPushNotificationRequestPerSession?: number | null;
    exponeaSubDomain?: string | null;
    exponeaTokenId?: string | null;
    exponeaTagExecution?: string | null;
    exponeaSpaOnHashChange?: boolean | null;
    exponeaSpaBanners?: boolean | null;
    exponeaSpaTags?: boolean | null;
    externalTaxEnabled?: boolean | null;
    lowestPriceDisplay?: LowestPriceDisplay | null;
    showLowestPriceText?: boolean | null;
    fiberRanking?: boolean | null;
    giftCardGroupLength?: number | null;
    giftCardGroupSeparator?: string | null;
    giftCardPrefixes?: Array<string | null> | null;
    hasDoubleNewsletterOptIn?: boolean | null;
    hasImplicitTermsAndConditions?: boolean | null;
    hasMyAccount?: boolean | null;
    homepageLayoutSlotDeliveryKey?: string | null;
    isBodyOnlyView?: boolean | null;
    isChangeCountryInBillingAddress?: boolean | null;
    isEnableQrCode?: boolean | null;
    isEnableShareCartEmail?: boolean | null;
    isLineSession?: boolean | null;
    klarnaHighOnlineReturnsSegment?: string | null;
    localeFallback?: string | null;
    loyaltyPointSystem?: string | null;
    masterLayoutSlotDeliveryKey?: string | null;
    myAccountPointHistoryCount?: number | null;
    navigationType?: NavigationType | null;
    net?: boolean | null;
    paymentMethods?: Array<string | null> | null;
    payPalExpressEnabled?: boolean | null;
    plpV2?: boolean | null;
    productBasePriceColor?: string | null;
    qubitEventsEnabled?: boolean | null;
    qubitSmartServeUrl?: string | null;
    qubitTagExecution?: string | null;
    qubitTrackingId?: string | null;
    quickViewOverlayDeliveryKey?: string | null;
    repoId?: string | null;
    requestPdpUspsSFCSizeLevel?: boolean | null;
    showMessageBoxNotifications?: boolean | null;
    siteHasPhysicalStores?: boolean | null;
    siteNameDisplay?: string | null;
    slice?: string | null;
    upsMyChoiceDefaultChecked?: boolean | null;
    verticalFacetNavigation?: boolean | null;
    voucherFormDefaultShown?: boolean | null;
    showStrikethroughPrices?: boolean | null;
    sizeGuideUnit?: SizeGuideUnit | null;
    staticFiltersQuery?: string | null;
    supportedFitType?: SupportedFitType | null;
    enableCLTAnalytics?: boolean | null;
    enableReturnFeeMDDC?: boolean | null;
    salesDiscountsEndDate?: string | null;
    plpSystemTextsDeliveryKey?: string | null;
    forterSiteId?: string | null;
    plpLayoutSlotDeliveryKey?: string | null;
    srpLayoutSlotDeliveryKey?: string | null;
    searchV2?: boolean | null;
    addressAutoCompleteConfiguration?: {
      __typename?: 'AddressConfiguration';
      enabled?: boolean | null;
      service?: string | null;
    } | null;
    addressSuggestionConfiguration?: {
      __typename?: 'AddressConfiguration';
      enabled?: boolean | null;
      service?: string | null;
    } | null;
    facetDisplayDataList?: Array<{
      __typename?: 'FacetDisplayDataList';
      code?: string | null;
      displayName?: string | null;
      translated?: boolean | null;
    } | null> | null;
    sortingOptions?: Array<{
      __typename?: 'SortingOptions';
      code: string;
      name: string;
      nameEN: string;
    }> | null;
    productTileConfiguration?: {
      __typename?: 'ProductTileConfiguration';
      showHoverImageOnPlp?: boolean | null;
      showHoverImageOnRecommendations?: boolean | null;
      showHoverImageOnRvp?: boolean | null;
      showQuickViewOnPlp?: boolean | null;
      showQuickViewOnRecommendations?: boolean | null;
      showQuickViewOnRvp?: boolean | null;
      showStyleVariantsOnPlp?: boolean | null;
      showStyleVariantsOnRecommendations?: boolean | null;
      showStyleVariantsOnRvp?: boolean | null;
    } | null;
    colorStyles?: Array<{
      __typename?: 'FacetColorStyleItem';
      key?: string | null;
      value?: string | null;
    }> | null;
  };
};

export type VisibleProductsQueryVariables = Exact<{
  siteType: SiteType;
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  locale: Scalars['Locale'];
}>;

export type VisibleProductsQuery = {
  __typename?: 'Query';
  visibleProducts?: {
    __typename?: 'Page';
    currentPage?: number | null;
    totalPageCount?: number | null;
    totalProductCount?: number | null;
    products?: Array<{
      __typename?: 'PagedProduct';
      code: string;
      url?: string | null;
    } | null> | null;
  } | null;
};

export type UserQueryVariables = Exact<{
  siteType: SiteType;
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    type?: string | null;
    name?: string | null;
    uid: string;
    analyticsCustomerType?: string | null;
    customerId?: string | null;
    customerType?: CustomerType | null;
    displayUid?: string | null;
    email?: string | null;
    firstName?: string | null;
    hashedUserId?: string | null;
    hashedUserIp?: string | null;
    internal?: boolean | null;
    lastName?: string | null;
    loyaltySubscribed?: boolean | null;
    memberCard?: string | null;
    thresholdCustomerType?: string | null;
    currency?: {
      __typename?: 'Currency';
      active?: boolean | null;
      isocode?: string | null;
      name?: string | null;
      symbol?: string | null;
      digits?: number | null;
    } | null;
    language?: {
      __typename?: 'Language';
      active?: boolean | null;
      isocode?: string | null;
      name?: string | null;
      nativeName?: string | null;
    } | null;
    personalisedDiscount?: {
      __typename?: 'PersonalisedDiscount';
      formattedValue?: string | null;
      expirationDate?: string | null;
      applicableToDiscountedProducts?: boolean | null;
      trafficSource?: string | null;
    } | null;
  } | null;
};

export type CountriesQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type CountriesQuery = {
  __typename?: 'Query';
  countries: Array<{
    __typename?: 'Country';
    addressStreetFormat?: string | null;
    isocode: any;
    name: string;
    phoneCode?: string | null;
    supportedLanguages?: Array<{
      __typename?: 'Language';
      active?: boolean | null;
      isocode?: string | null;
      name?: string | null;
      nativeName?: string | null;
    } | null> | null;
  } | null>;
};

export type CountryQueryVariables = Exact<{
  siteType: SiteType;
  isocode: Scalars['IsoCode'];
  locale: Scalars['Locale'];
}>;

export type CountryQuery = {
  __typename?: 'Query';
  country: {
    __typename?: 'Country';
    addressStreetFormat?: string | null;
    isocode: any;
    name: string;
    phoneCode?: string | null;
    supportedLanguages?: Array<{
      __typename?: 'Language';
      active?: boolean | null;
      isocode?: string | null;
      name?: string | null;
      nativeName?: string | null;
    } | null> | null;
  };
};

export type NavigationQueryVariables = Exact<{
  siteType: SiteType;
  navigationType: NavigationTypeParam;
  locale: Scalars['Locale'];
}>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation?: {
    __typename?: 'Navigation';
    id?: string | null;
    navigation?: Array<{
      __typename?: 'NavigationItem';
      hideForNonLoggedInVisitors?: boolean | null;
      hideForLoggedInVisitors?: boolean | null;
      label?: string | null;
      labelAria?: string | null;
      styleModifier?: string | null;
      target?: LinkTarget | null;
      url?: string | null;
      uid?: string | null;
      children?: Array<{
        __typename?: 'NavigationItem';
        hideForNonLoggedInVisitors?: boolean | null;
        hideForLoggedInVisitors?: boolean | null;
        label?: string | null;
        labelAria?: string | null;
        styleModifier?: string | null;
        target?: LinkTarget | null;
        url?: string | null;
        uid?: string | null;
        children?: Array<{
          __typename?: 'NavigationItem';
          hideForNonLoggedInVisitors?: boolean | null;
          hideForLoggedInVisitors?: boolean | null;
          label?: string | null;
          labelAria?: string | null;
          styleModifier?: string | null;
          target?: LinkTarget | null;
          url?: string | null;
          uid?: string | null;
          children?: Array<{
            __typename?: 'NavigationItem';
            hideForNonLoggedInVisitors?: boolean | null;
            hideForLoggedInVisitors?: boolean | null;
            label?: string | null;
            labelAria?: string | null;
            styleModifier?: string | null;
            target?: LinkTarget | null;
            url?: string | null;
            uid?: string | null;
            children?: Array<{
              __typename?: 'NavigationItem';
              hideForNonLoggedInVisitors?: boolean | null;
              hideForLoggedInVisitors?: boolean | null;
              label?: string | null;
              labelAria?: string | null;
              styleModifier?: string | null;
              target?: LinkTarget | null;
              url?: string | null;
              uid?: string | null;
              children?: Array<{
                __typename?: 'NavigationItem';
                hideForNonLoggedInVisitors?: boolean | null;
                hideForLoggedInVisitors?: boolean | null;
                label?: string | null;
                labelAria?: string | null;
                styleModifier?: string | null;
                target?: LinkTarget | null;
                url?: string | null;
                uid?: string | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SocialMediaChannelsQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type SocialMediaChannelsQuery = {
  __typename?: 'Query';
  socialMediaChannels?: Array<{
    __typename?: 'SocialMediaChannel';
    code?: string | null;
    name?: string | null;
    target?: LinkTarget | null;
    url?: string | null;
  } | null> | null;
};

export type PaymentMethodsQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type PaymentMethodsQuery = {
  __typename?: 'Query';
  paymentMethods?: Array<{
    __typename?: 'PaymentMethods';
    code?: string | null;
    imageCssClass?: string | null;
    imageFooterCssClass?: string | null;
    name?: string | null;
    target?: LinkTarget | null;
    url?: string | null;
  } | null> | null;
};

export type MetaInfoPaymentMethodsQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type MetaInfoPaymentMethodsQuery = {
  __typename?: 'Query';
  paymentMethods?: Array<{
    __typename?: 'PaymentMethods';
    code?: string | null;
    name?: string | null;
    target?: LinkTarget | null;
    url?: string | null;
  } | null> | null;
};

export type UspsQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type UspsQuery = {
  __typename?: 'Query';
  usps?: { __typename?: 'Usp'; html?: string | null } | null;
};

export type SizeMeasurementsQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type SizeMeasurementsQuery = {
  __typename?: 'Query';
  sizeMeasurements: {
    __typename?: 'SizeMeasurements';
    id?: string | null;
    sizeMeasurements?: {
      __typename?: 'SizeMeasurementsObj';
      category?: string | null;
      sizeMeasurements?: Array<{
        __typename?: 'SizeMeasurementsItem';
        hip?: string | null;
        inseam?: string | null;
        legOpening?: string | null;
        size?: string | null;
        thigh?: string | null;
        totalLength?: string | null;
        waist?: string | null;
        acrossShoulder?: string | null;
        back?: string | null;
        sleeve?: string | null;
        centerBackLength?: string | null;
        chest?: string | null;
      } | null> | null;
    } | null;
  };
};

export type SizeGuidesQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type SizeGuidesQuery = {
  __typename?: 'Query';
  sizeGuides: {
    __typename?: 'SizeGuides';
    id?: string | null;
    sizeUnit?: SizeGuideUnit | null;
    labelTranslations?: {
      __typename?: 'SizeGuidesLabelTranslations';
      arm?: string | null;
      chest?: string | null;
      foot?: string | null;
      hip?: string | null;
      inseam?: string | null;
      length?: string | null;
      size?: string | null;
      waist?: string | null;
    } | null;
    sizeGuides?: Array<{
      __typename?: 'SizeGuide';
      code?: string | null;
      gender?: string | null;
      name?: string | null;
      categories?: Array<{
        __typename?: 'SizeGuideCategory';
        code?: string | null;
        descriptions?: Array<string | null> | null;
        name?: string | null;
        measuresystems?: Array<{
          __typename?: 'SizeGuideMeasuresystem';
          code?: string | null;
          name?: string | null;
          tables?: Array<{
            __typename?: 'SizeGuideMeasuresystemTableItem';
            tableHtml?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  };
};

export type LocalStoreStockQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  isocode: Scalars['IsoCode'];
  locale: Scalars['Locale'];
}>;

export type LocalStoreStockQuery = {
  __typename?: 'Query';
  localStoreStock?: Array<{
    __typename?: 'Store';
    contentPageUrl?: string | null;
    saturday?: string | null;
    storeType?: string | null;
    latitude?: string | null;
    thursday?: string | null;
    stockIndication?: string | null;
    sunday?: string | null;
    tuesday?: string | null;
    name?: string | null;
    wednesday?: string | null;
    friday?: string | null;
    sapStoreNr?: string | null;
    longitude?: string | null;
    monday?: string | null;
    address?: {
      __typename?: 'StoreAddress';
      country?: string | null;
      address?: string | null;
      address2?: string | null;
      city?: string | null;
      streetname?: string | null;
      multiLineAddress?: string | null;
      address1Local?: string | null;
      postalCode?: string | null;
      streetnumber?: string | null;
      singleLineAddress?: string | null;
      address2Local?: string | null;
      phone?: string | null;
      state?: string | null;
    } | null;
  } | null> | null;
};

export type CommonDataQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type CommonDataQuery = {
  __typename?: 'Query';
  commonData: {
    __typename?: 'CommonData';
    internal?: boolean | null;
    gender?: string | null;
    newsletterUser?: boolean | null;
    ampliencePreviewTime?: string | null;
    customerType?: string | null;
    assistedSaleUserType?: string | null;
    customerId?: string | null;
    correlationId?: string | null;
    assistedSaleUserID?: string | null;
    amplienceTSP?: string | null;
    hashedUserId?: string | null;
    amplienceVSE?: string | null;
    numberOfWishListItems?: number | null;
    thresholdCustomerType?: string | null;
    accountType?: string | null;
    asmInstoreLocale?: string | null;
    hashedIp?: string | null;
    ageGroup?: string | null;
    loggedInStatus?: boolean | null;
    firstName?: string | null;
    csrfToken?: any | null;
    msdAbTestingEnabled?: boolean | null;
    assistedSale?: boolean | null;
    isAmpliencePreview?: boolean | null;
    gsOrigin?: string | null;
  };
};

export type RecommendationsQueryVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type RecommendationsQuery = {
  __typename?: 'Query';
  recommendations?: {
    __typename?: 'Recommendations';
    productCodes?: string | null;
    analytics?: Array<{
      __typename?: 'AnalyticsData';
      key?: string | null;
      value?: string | null;
    } | null> | null;
    recommendedProducts?: {
      __typename?: 'RecommendedProducts';
      maxMobileProducts?: number | null;
      maxProducts?: number | null;
      maxTabletProducts?: number | null;
      title?: string | null;
      products?: Array<{
        __typename?: 'RecommendedProduct';
        baseProduct?: string | null;
        code: any;
        name?: string | null;
        url?: string | null;
        price?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ProductsQueryVariables = Exact<{
  input?: InputMaybe<ProductsInput>;
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  products?: Array<{
    __typename?: 'ProductsProduct';
    algoliaObjectId?: string | null;
    baseProduct?: string | null;
    featuresIntro?: string | null;
    formattedLowestPrice?: string | null;
    name?: string | null;
    nameEN?: string | null;
    url?: string | null;
    ean?: string | null;
    code?: string | null;
    fitEN?: string | null;
    materialGroup?: string | null;
    programEN?: string | null;
    staticCategoryPath?: string | null;
    staticCategoryPathIds?: string | null;
    subTargetAudience?: string | null;
    baseProductStockNotificationMessageEN?: string | null;
    styleFamilyEN?: string | null;
    oneSize?: boolean | null;
    isGiftCard?: boolean | null;
    availableStyleVariantsSorted?: Array<{
      __typename?: 'StyleVariant';
      code?: string | null;
      color?: string | null;
      facetColorStyle?: string | null;
      formattedBasePrice?: string | null;
      formattedFromPrice?: string | null;
      formattedIntermediaryPrice?: string | null;
      formattedLowestPrice?: string | null;
      isProductHasReviews?: boolean | null;
      mainColor?: string | null;
      name?: string | null;
      productAppUrl?: string | null;
      productFiberRankingJson?: string | null;
      productUrl?: string | null;
      unformattedBasePrice?: string | null;
      unformattedFromPrice?: string | null;
      unformattedIntermediaryPrice?: string | null;
      images?: {
        __typename?: 'StyleVariantImages';
        large?: string | null;
        primaryImage?: string | null;
        swatch?: string | null;
        thumbnail?: string | null;
        thumbnailAltText?: string | null;
      } | null;
      signing?: {
        __typename?: 'StyleVariantSigning';
        backgroundColour?: string | null;
        cssClasses?: string | null;
        discountSigning?: boolean | null;
        label?: string | null;
        productSigningBackgroundColour?: string | null;
        productSigningTextColour?: string | null;
        renderAsText?: boolean | null;
        showOnPdp?: boolean | null;
        textColour?: string | null;
      } | null;
    } | null> | null;
    color?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    fiberRanking?: {
      __typename?: 'FiberRanking';
      productAudience?: string | null;
      fiberRanking?: {
        __typename?: 'FiberRankingData';
        genericFiberRankingData?: {
          __typename?: 'GenericFibers';
          fibers?: Array<{
            __typename?: 'Fiber';
            code?: string | null;
            descriptionLong?: string | null;
            descriptionShort?: string | null;
            percentage?: number | null;
            ranking?: string | null;
            score?: number | null;
            type?: string | null;
          } | null> | null;
        } | null;
        productFiberRankingData?: {
          __typename?: 'ProductFibers';
          ranking?: string | null;
          score?: number | null;
          fibers?: Array<{
            __typename?: 'Fiber';
            code?: string | null;
            descriptionLong?: string | null;
            descriptionShort?: string | null;
            percentage?: number | null;
            ranking?: string | null;
            score?: number | null;
            type?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
    fromPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    intermediaryPrice?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    mainColor?: {
      __typename?: 'Color';
      description?: string | null;
      descriptionEN?: string | null;
      facetColorStyle?: string | null;
    } | null;
    price?: {
      __typename?: 'Price';
      currencyIso?: string | null;
      formattedValue?: string | null;
      formattedValueWithoutCurrency?: string | null;
      nrDigits?: number | null;
      value?: number | null;
    } | null;
    simplifiedImages?: {
      __typename?: 'SimplifiedImageEntry';
      entry?: Array<{
        __typename?: 'ImageEntry';
        key?: string | null;
        value?: { __typename?: 'ImageData'; altText?: string | null; url?: string | null } | null;
      } | null> | null;
    } | null;
    size?: {
      __typename?: 'Size';
      formattedValue?: string | null;
      purchasable?: boolean | null;
      value?: string | null;
      code?: string | null;
      ean?: string | null;
      gridValue1?: any | null;
      gridValue2?: any | null;
      notPurchasableDueToStock?: boolean | null;
      stockNotificationMessageEN?: string | null;
      willBeAvailable?: boolean | null;
    } | null;
    sizeInformation?: {
      __typename?: 'SizeInformation';
      sizeType?: SizeType | null;
      groupedSizes?: Array<{
        __typename?: 'GroupedSize';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        sizes?: Array<{
          __typename?: 'Size';
          formattedValue?: string | null;
          purchasable?: boolean | null;
          value?: string | null;
          code?: string | null;
          ean?: string | null;
          gridValue1?: any | null;
          gridValue2?: any | null;
          notPurchasableDueToStock?: boolean | null;
          stockNotificationMessageEN?: string | null;
          willBeAvailable?: boolean | null;
        } | null> | null;
      } | null> | null;
      sizes?: Array<{
        __typename?: 'Size';
        formattedValue?: string | null;
        purchasable?: boolean | null;
        value?: string | null;
        code?: string | null;
        ean?: string | null;
        gridValue1?: any | null;
        gridValue2?: any | null;
        notPurchasableDueToStock?: boolean | null;
        stockNotificationMessageEN?: string | null;
        willBeAvailable?: boolean | null;
      } | null> | null;
    } | null;
    signings?: Array<{
      __typename?: 'Signing';
      code?: string | null;
      discountSigning?: boolean | null;
      label?: string | null;
      labelCssClasses?: string | null;
      labelHtmlSafe?: string | null;
      plpPositionClass?: string | null;
      priority?: number | null;
      productSigningBackgroundColour?: string | null;
      productSigningTextColour?: string | null;
      renderAsText?: boolean | null;
      showOnPdp?: boolean | null;
      showOnPlp?: boolean | null;
      image?: { __typename?: 'SigningImage'; url?: string | null } | null;
    } | null> | null;
  } | null> | null;
};

export type ShippingEstimatesQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
  code: Scalars['Code'];
  gridValue1?: InputMaybe<Scalars['GridValue']>;
  gridValue2?: InputMaybe<Scalars['GridValue']>;
}>;

export type ShippingEstimatesQuery = {
  __typename?: 'Query';
  shippingEstimates: {
    __typename?: 'ShippingEstimates';
    id?: string | null;
    description?: string | null;
  };
};

export type CategoryPathsQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
}>;

export type CategoryPathsQuery = {
  __typename?: 'Query';
  categoryPaths: { __typename?: 'CategoryPaths'; categories?: Array<string | null> | null };
};

export type CategoryDetailsQueryVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
  path: Scalars['CategoryPath'];
}>;

export type CategoryDetailsQuery = {
  __typename?: 'Query';
  categoryDetails: {
    __typename?: 'CategoryDetails';
    algoliaIndexName?: string | null;
    algoliaQueryId?: string | null;
    canonicalUrl?: string | null;
    categoryCode?: string | null;
    categoryName?: string | null;
    clearAllUrl?: string | null;
    h1?: string | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    pageSize?: number | null;
    pageTitle?: string | null;
    plpAppUrl?: string | null;
    plpCategoryPath?: string | null;
    plpCategoryPathIds?: string | null;
    requestUrl?: string | null;
    selectedFacets?: Array<{
      __typename?: 'VisibleFacet';
      code?: string | null;
      name?: string | null;
      removeUrl?: string | null;
      facetValues?: Array<{
        __typename?: 'FacetValue';
        code?: string | null;
        count?: number | null;
        defaultValue?: string | null;
        name?: string | null;
        selected?: boolean | null;
        url?: string | null;
      } | null> | null;
    } | null> | null;
    sorting?: Array<{
      __typename?: 'CategorySorting';
      code?: string | null;
      name?: string | null;
      nameEN?: string | null;
      selected?: boolean | null;
      url?: string | null;
    } | null> | null;
    visibleFacets?: Array<{
      __typename?: 'VisibleFacet';
      code?: string | null;
      name?: string | null;
      removeUrl?: string | null;
      facetValues?: Array<{
        __typename?: 'FacetValue';
        code?: string | null;
        count?: number | null;
        defaultValue?: string | null;
        name?: string | null;
        selected?: boolean | null;
        url?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type AddToCartMutationVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
}>;

export type AddToCartMutation = {
  __typename?: 'Mutation';
  addToCart?: {
    __typename?: 'CartMutation';
    type?: string | null;
    cartModifications?: Array<{
      __typename?: 'CartModification';
      quantity?: number | null;
      quantityAdded?: number | null;
      statusCode?: string | null;
      statusMessage?: string | null;
      entry?: {
        __typename?: 'CartEntry';
        entryNumber?: number | null;
        gift?: boolean | null;
        quantity?: number | null;
        removable?: boolean | null;
        updateable?: boolean | null;
        basePrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        product?: {
          __typename?: 'CartProduct';
          code?: any | null;
          baseProduct?: string | null;
          ean?: string | null;
          fitEN?: string | null;
          isGiftCard?: boolean | null;
          materialGroup?: string | null;
          name?: string | null;
          nameEN?: string | null;
          oneSize?: boolean | null;
          programEN?: string | null;
          shouldUseDarkBackgroundImgs?: boolean | null;
          staticCategoryPath?: string | null;
          staticCategoryPathIds?: string | null;
          stockNotificationMessageEN?: string | null;
          styleFamilyEN?: string | null;
          subTargetAudience?: string | null;
          url?: string | null;
          simplifiedImages?: Array<{
            __typename?: 'ImageEntry';
            key?: string | null;
            value?: {
              __typename?: 'ImageData';
              altText?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
          size?: {
            __typename?: 'Size';
            formattedValue?: string | null;
            purchasable?: boolean | null;
            value?: string | null;
            code?: string | null;
            ean?: string | null;
            gridValue1?: any | null;
            gridValue2?: any | null;
            notPurchasableDueToStock?: boolean | null;
            stockNotificationMessageEN?: string | null;
            willBeAvailable?: boolean | null;
          } | null;
          price?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          mainColor?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
          fromPrice?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          color?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
        } | null;
        taxAmount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPriceWithoutDiscount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
      } | null;
    } | null> | null;
    cart?: {
      __typename?: 'Cart';
      adyenAmount?: number | null;
      assistedSale?: boolean | null;
      isGsTaxCalculated?: boolean | null;
      isPayPalExpressPayment?: boolean | null;
      validationStep?: string | null;
      code?: string | null;
      externalTaxEnabled?: boolean | null;
      guid?: string | null;
      net?: boolean | null;
      totalItems?: number | null;
      appliedOrderPromotions?: Array<{
        __typename?: 'Promotion';
        description?: string | null;
        discountPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        promotion?: {
          __typename?: 'PromotionDetails';
          code?: string | null;
          description?: string | null;
          promotionType?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      potentialOrderPromotions?: Array<{
        __typename?: 'Promotion';
        description?: string | null;
        discountPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        promotion?: {
          __typename?: 'PromotionDetails';
          code?: string | null;
          description?: string | null;
          promotionType?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      billingAddress?: {
        __typename?: 'Address';
        verified?: boolean | null;
        phoneDetails?: {
          __typename?: 'PhoneDetails';
          countryCodeIso?: string | null;
          prefix?: string | null;
        } | null;
      } | null;
      customer?: {
        __typename?: 'CartCustomer';
        customerId?: string | null;
        customerType?: CustomerType | null;
        email?: string | null;
        loyaltySubscribed?: boolean | null;
      } | null;
      deliveryAddress?: {
        __typename?: 'Address';
        verified?: boolean | null;
        phoneDetails?: {
          __typename?: 'PhoneDetails';
          countryCodeIso?: string | null;
          prefix?: string | null;
        } | null;
      } | null;
      deliveryCost?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      deliveryMode?: {
        __typename?: 'DeliveryMode';
        code?: string | null;
        deliveryMethod?: string | null;
        icon?: string | null;
        name?: string | null;
        omsDeliveryMethod?: string | null;
        requestCarrierCustomerId?: boolean | null;
        shipper?: string | null;
        description?: string | null;
        deliveryCost?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        transitDays?: {
          __typename?: 'TransitDays';
          highestDeliveryDate?: string | null;
          lowerBound?: number | null;
          lowestDeliveryDate?: string | null;
          maxDeliveryDateFormatted?: string | null;
          minDeliveryDateFormatted?: string | null;
          upperBound?: number | null;
        } | null;
      } | null;
      deliveryTotalTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      entries?: Array<{
        __typename?: 'CartEntry';
        entryNumber?: number | null;
        gift?: boolean | null;
        quantity?: number | null;
        removable?: boolean | null;
        updateable?: boolean | null;
        basePrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        product?: {
          __typename?: 'CartProduct';
          code?: any | null;
          baseProduct?: string | null;
          ean?: string | null;
          fitEN?: string | null;
          isGiftCard?: boolean | null;
          materialGroup?: string | null;
          name?: string | null;
          nameEN?: string | null;
          oneSize?: boolean | null;
          programEN?: string | null;
          shouldUseDarkBackgroundImgs?: boolean | null;
          staticCategoryPath?: string | null;
          staticCategoryPathIds?: string | null;
          stockNotificationMessageEN?: string | null;
          styleFamilyEN?: string | null;
          subTargetAudience?: string | null;
          url?: string | null;
          simplifiedImages?: Array<{
            __typename?: 'ImageEntry';
            key?: string | null;
            value?: {
              __typename?: 'ImageData';
              altText?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
          size?: {
            __typename?: 'Size';
            formattedValue?: string | null;
            purchasable?: boolean | null;
            value?: string | null;
            code?: string | null;
            ean?: string | null;
            gridValue1?: any | null;
            gridValue2?: any | null;
            notPurchasableDueToStock?: boolean | null;
            stockNotificationMessageEN?: string | null;
            willBeAvailable?: boolean | null;
          } | null;
          price?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          mainColor?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
          fromPrice?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          color?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
        } | null;
        taxAmount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPriceWithoutDiscount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
      } | null> | null;
      grandTotalWGCSGross?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      grandTotalWGCSTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      grandTotalWGCSNet?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      orderDiscounts?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      subTotal?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalAfterGiftCards?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalDiscounts?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceNoTaxes?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      voucherTotalDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null;
  } | null;
};

export type RemoveFromCartMutationVariables = Exact<{
  siteType: SiteType;
  entryNumber: Scalars['Int'];
  locale: Scalars['Locale'];
}>;

export type RemoveFromCartMutation = {
  __typename?: 'Mutation';
  removeFromCart?: {
    __typename?: 'CartMutation';
    type?: string | null;
    cartModifications?: Array<{
      __typename?: 'CartModification';
      quantity?: number | null;
      quantityAdded?: number | null;
      statusCode?: string | null;
      statusMessage?: string | null;
      entry?: {
        __typename?: 'CartEntry';
        entryNumber?: number | null;
        gift?: boolean | null;
        quantity?: number | null;
        removable?: boolean | null;
        updateable?: boolean | null;
        basePrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        product?: {
          __typename?: 'CartProduct';
          code?: any | null;
          baseProduct?: string | null;
          ean?: string | null;
          fitEN?: string | null;
          isGiftCard?: boolean | null;
          materialGroup?: string | null;
          name?: string | null;
          nameEN?: string | null;
          oneSize?: boolean | null;
          programEN?: string | null;
          shouldUseDarkBackgroundImgs?: boolean | null;
          staticCategoryPath?: string | null;
          staticCategoryPathIds?: string | null;
          stockNotificationMessageEN?: string | null;
          styleFamilyEN?: string | null;
          subTargetAudience?: string | null;
          url?: string | null;
          simplifiedImages?: Array<{
            __typename?: 'ImageEntry';
            key?: string | null;
            value?: {
              __typename?: 'ImageData';
              altText?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
          size?: {
            __typename?: 'Size';
            formattedValue?: string | null;
            purchasable?: boolean | null;
            value?: string | null;
            code?: string | null;
            ean?: string | null;
            gridValue1?: any | null;
            gridValue2?: any | null;
            notPurchasableDueToStock?: boolean | null;
            stockNotificationMessageEN?: string | null;
            willBeAvailable?: boolean | null;
          } | null;
          price?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          mainColor?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
          fromPrice?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          color?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
        } | null;
        taxAmount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPriceWithoutDiscount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
      } | null;
    } | null> | null;
    cart?: {
      __typename?: 'Cart';
      adyenAmount?: number | null;
      assistedSale?: boolean | null;
      isGsTaxCalculated?: boolean | null;
      isPayPalExpressPayment?: boolean | null;
      validationStep?: string | null;
      code?: string | null;
      externalTaxEnabled?: boolean | null;
      guid?: string | null;
      net?: boolean | null;
      totalItems?: number | null;
      appliedOrderPromotions?: Array<{
        __typename?: 'Promotion';
        description?: string | null;
        discountPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        promotion?: {
          __typename?: 'PromotionDetails';
          code?: string | null;
          description?: string | null;
          promotionType?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      potentialOrderPromotions?: Array<{
        __typename?: 'Promotion';
        description?: string | null;
        discountPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        promotion?: {
          __typename?: 'PromotionDetails';
          code?: string | null;
          description?: string | null;
          promotionType?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      billingAddress?: {
        __typename?: 'Address';
        verified?: boolean | null;
        phoneDetails?: {
          __typename?: 'PhoneDetails';
          countryCodeIso?: string | null;
          prefix?: string | null;
        } | null;
      } | null;
      customer?: {
        __typename?: 'CartCustomer';
        customerId?: string | null;
        customerType?: CustomerType | null;
        email?: string | null;
        loyaltySubscribed?: boolean | null;
      } | null;
      deliveryAddress?: {
        __typename?: 'Address';
        verified?: boolean | null;
        phoneDetails?: {
          __typename?: 'PhoneDetails';
          countryCodeIso?: string | null;
          prefix?: string | null;
        } | null;
      } | null;
      deliveryCost?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      deliveryMode?: {
        __typename?: 'DeliveryMode';
        code?: string | null;
        deliveryMethod?: string | null;
        icon?: string | null;
        name?: string | null;
        omsDeliveryMethod?: string | null;
        requestCarrierCustomerId?: boolean | null;
        shipper?: string | null;
        description?: string | null;
        deliveryCost?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        transitDays?: {
          __typename?: 'TransitDays';
          highestDeliveryDate?: string | null;
          lowerBound?: number | null;
          lowestDeliveryDate?: string | null;
          maxDeliveryDateFormatted?: string | null;
          minDeliveryDateFormatted?: string | null;
          upperBound?: number | null;
        } | null;
      } | null;
      deliveryTotalTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      entries?: Array<{
        __typename?: 'CartEntry';
        entryNumber?: number | null;
        gift?: boolean | null;
        quantity?: number | null;
        removable?: boolean | null;
        updateable?: boolean | null;
        basePrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        product?: {
          __typename?: 'CartProduct';
          code?: any | null;
          baseProduct?: string | null;
          ean?: string | null;
          fitEN?: string | null;
          isGiftCard?: boolean | null;
          materialGroup?: string | null;
          name?: string | null;
          nameEN?: string | null;
          oneSize?: boolean | null;
          programEN?: string | null;
          shouldUseDarkBackgroundImgs?: boolean | null;
          staticCategoryPath?: string | null;
          staticCategoryPathIds?: string | null;
          stockNotificationMessageEN?: string | null;
          styleFamilyEN?: string | null;
          subTargetAudience?: string | null;
          url?: string | null;
          simplifiedImages?: Array<{
            __typename?: 'ImageEntry';
            key?: string | null;
            value?: {
              __typename?: 'ImageData';
              altText?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
          size?: {
            __typename?: 'Size';
            formattedValue?: string | null;
            purchasable?: boolean | null;
            value?: string | null;
            code?: string | null;
            ean?: string | null;
            gridValue1?: any | null;
            gridValue2?: any | null;
            notPurchasableDueToStock?: boolean | null;
            stockNotificationMessageEN?: string | null;
            willBeAvailable?: boolean | null;
          } | null;
          price?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          mainColor?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
          fromPrice?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          color?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
        } | null;
        taxAmount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPriceWithoutDiscount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
      } | null> | null;
      grandTotalWGCSGross?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      grandTotalWGCSTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      grandTotalWGCSNet?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      orderDiscounts?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      subTotal?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalAfterGiftCards?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalDiscounts?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceNoTaxes?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      voucherTotalDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null;
  } | null;
};

export type AddToWishlistMutationVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  csrfToken: Scalars['CSRFToken'];
}>;

export type AddToWishlistMutation = { __typename?: 'Mutation'; addToWishlist?: boolean | null };

export type RemoveFromWishlistMutationVariables = Exact<{
  siteType: SiteType;
  code: Scalars['Code'];
  locale: Scalars['Locale'];
  csrfToken: Scalars['CSRFToken'];
}>;

export type RemoveFromWishlistMutation = {
  __typename?: 'Mutation';
  removeFromWishlist?: boolean | null;
};

export type ApplyVoucherMutationVariables = Exact<{
  siteType: SiteType;
  voucher: Scalars['String'];
  locale: Scalars['Locale'];
  utmSource?: InputMaybe<Scalars['String']>;
}>;

export type ApplyVoucherMutation = {
  __typename?: 'Mutation';
  applyVoucher?: {
    __typename?: 'VoucherApplyResult';
    type?: string | null;
    cart?: {
      __typename?: 'Cart';
      adyenAmount?: number | null;
      assistedSale?: boolean | null;
      isGsTaxCalculated?: boolean | null;
      isPayPalExpressPayment?: boolean | null;
      validationStep?: string | null;
      code?: string | null;
      externalTaxEnabled?: boolean | null;
      guid?: string | null;
      net?: boolean | null;
      totalItems?: number | null;
      appliedOrderPromotions?: Array<{
        __typename?: 'Promotion';
        description?: string | null;
        discountPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        promotion?: {
          __typename?: 'PromotionDetails';
          code?: string | null;
          description?: string | null;
          promotionType?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      potentialOrderPromotions?: Array<{
        __typename?: 'Promotion';
        description?: string | null;
        discountPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        promotion?: {
          __typename?: 'PromotionDetails';
          code?: string | null;
          description?: string | null;
          promotionType?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      billingAddress?: {
        __typename?: 'Address';
        verified?: boolean | null;
        phoneDetails?: {
          __typename?: 'PhoneDetails';
          countryCodeIso?: string | null;
          prefix?: string | null;
        } | null;
      } | null;
      customer?: {
        __typename?: 'CartCustomer';
        customerId?: string | null;
        customerType?: CustomerType | null;
        email?: string | null;
        loyaltySubscribed?: boolean | null;
      } | null;
      deliveryAddress?: {
        __typename?: 'Address';
        verified?: boolean | null;
        phoneDetails?: {
          __typename?: 'PhoneDetails';
          countryCodeIso?: string | null;
          prefix?: string | null;
        } | null;
      } | null;
      deliveryCost?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      deliveryMode?: {
        __typename?: 'DeliveryMode';
        code?: string | null;
        deliveryMethod?: string | null;
        icon?: string | null;
        name?: string | null;
        omsDeliveryMethod?: string | null;
        requestCarrierCustomerId?: boolean | null;
        shipper?: string | null;
        description?: string | null;
        deliveryCost?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        transitDays?: {
          __typename?: 'TransitDays';
          highestDeliveryDate?: string | null;
          lowerBound?: number | null;
          lowestDeliveryDate?: string | null;
          maxDeliveryDateFormatted?: string | null;
          minDeliveryDateFormatted?: string | null;
          upperBound?: number | null;
        } | null;
      } | null;
      deliveryTotalTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      entries?: Array<{
        __typename?: 'CartEntry';
        entryNumber?: number | null;
        gift?: boolean | null;
        quantity?: number | null;
        removable?: boolean | null;
        updateable?: boolean | null;
        basePrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        fromPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        product?: {
          __typename?: 'CartProduct';
          code?: any | null;
          baseProduct?: string | null;
          ean?: string | null;
          fitEN?: string | null;
          isGiftCard?: boolean | null;
          materialGroup?: string | null;
          name?: string | null;
          nameEN?: string | null;
          oneSize?: boolean | null;
          programEN?: string | null;
          shouldUseDarkBackgroundImgs?: boolean | null;
          staticCategoryPath?: string | null;
          staticCategoryPathIds?: string | null;
          stockNotificationMessageEN?: string | null;
          styleFamilyEN?: string | null;
          subTargetAudience?: string | null;
          url?: string | null;
          simplifiedImages?: Array<{
            __typename?: 'ImageEntry';
            key?: string | null;
            value?: {
              __typename?: 'ImageData';
              altText?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
          size?: {
            __typename?: 'Size';
            formattedValue?: string | null;
            purchasable?: boolean | null;
            value?: string | null;
            code?: string | null;
            ean?: string | null;
            gridValue1?: any | null;
            gridValue2?: any | null;
            notPurchasableDueToStock?: boolean | null;
            stockNotificationMessageEN?: string | null;
            willBeAvailable?: boolean | null;
          } | null;
          price?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          mainColor?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
          fromPrice?: {
            __typename?: 'Price';
            currencyIso?: string | null;
            formattedValue?: string | null;
            formattedValueWithoutCurrency?: string | null;
            nrDigits?: number | null;
            value?: number | null;
          } | null;
          color?: {
            __typename?: 'Color';
            description?: string | null;
            descriptionEN?: string | null;
            facetColorStyle?: string | null;
          } | null;
        } | null;
        taxAmount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPrice?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
        totalPriceWithoutDiscount?: {
          __typename?: 'Price';
          currencyIso?: string | null;
          formattedValue?: string | null;
          formattedValueWithoutCurrency?: string | null;
          nrDigits?: number | null;
          value?: number | null;
        } | null;
      } | null> | null;
      grandTotalWGCSGross?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      grandTotalWGCSTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      grandTotalWGCSNet?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      orderDiscounts?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      subTotal?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalAfterGiftCards?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalDiscounts?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPrice?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceNoTaxes?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalPriceWithTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      totalTax?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
      voucherTotalDiscount?: {
        __typename?: 'Price';
        currencyIso?: string | null;
        formattedValue?: string | null;
        formattedValueWithoutCurrency?: string | null;
        nrDigits?: number | null;
        value?: number | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string } | null> | null;
  } | null;
};

export type ExponeaConsentEventMutationVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
  cookie: Scalars['String'];
  subscriberKey?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  action: ExponeaConsentAction;
}>;

export type ExponeaConsentEventMutation = {
  __typename?: 'Mutation';
  exponeaConsentEvent?: string | null;
};

export type ExponeaCustomerAttributesMutationVariables = Exact<{
  siteType: SiteType;
  locale: Scalars['Locale'];
  cookie: Scalars['String'];
  subscriberKey?: InputMaybe<Scalars['String']>;
  type: ExponeaTypeAttribute;
  category: ExponeaCategoryAttribute;
}>;

export type ExponeaCustomerAttributesMutation = {
  __typename?: 'Mutation';
  exponeaCustomerAttributes?: {
    __typename?: 'ExponeaCustomerAttributesResponse';
    results?: Array<{
      __typename?: 'ExponeaCustomerAttributes';
      value?: boolean | null;
    } | null> | null;
  } | null;
};

export const PriceFieldsFragmentDoc = gql`
  fragment PriceFields on Price {
    currencyIso
    formattedValue
    formattedValueWithoutCurrency
    nrDigits
    value
  }
`;
export const ColorFieldsFragmentDoc = gql`
  fragment ColorFields on Color {
    description
    descriptionEN
    facetColorStyle
  }
`;
export const SigningImageFieldsFragmentDoc = gql`
  fragment SigningImageFields on SigningImage {
    url
  }
`;
export const SigningFieldsFragmentDoc = gql`
  fragment SigningFields on Signing {
    code
    discountSigning
    image {
      ...SigningImageFields
    }
    label
    labelCssClasses
    labelHtmlSafe
    plpPositionClass
    priority
    productSigningBackgroundColour
    productSigningTextColour
    renderAsText
    showOnPdp
    showOnPlp
  }
  ${SigningImageFieldsFragmentDoc}
`;
export const ImageDataFieldsFragmentDoc = gql`
  fragment ImageDataFields on ImageData {
    altText
    url
  }
`;
export const ImageEntryFieldsFragmentDoc = gql`
  fragment ImageEntryFields on ImageEntry {
    key
    value {
      ...ImageDataFields
    }
  }
  ${ImageDataFieldsFragmentDoc}
`;
export const SimplifiedImageEntryFieldsFragmentDoc = gql`
  fragment SimplifiedImageEntryFields on SimplifiedImageEntry {
    entry {
      ...ImageEntryFields
    }
  }
  ${ImageEntryFieldsFragmentDoc}
`;
export const SizeFieldsFragmentDoc = gql`
  fragment SizeFields on Size {
    formattedValue
    purchasable
    value
    code
    ean
    gridValue1
    gridValue2
    notPurchasableDueToStock
    stockNotificationMessageEN
    willBeAvailable
  }
`;
export const GroupedSizeFieldsFragmentDoc = gql`
  fragment GroupedSizeFields on GroupedSize {
    formattedValue
    purchasable
    value
    sizes {
      ...SizeFields
    }
  }
  ${SizeFieldsFragmentDoc}
`;
export const SizeInformationFieldsFragmentDoc = gql`
  fragment SizeInformationFields on SizeInformation {
    groupedSizes {
      ...GroupedSizeFields
    }
    sizeType
    sizes {
      ...SizeFields
    }
  }
  ${GroupedSizeFieldsFragmentDoc}
  ${SizeFieldsFragmentDoc}
`;
export const LookbookProductsProductFieldsFragmentDoc = gql`
  fragment LookbookProductsProductFields on ProductsProduct {
    name
    code
    url
    baseProduct
    formattedLowestPrice
    fromPrice {
      ...PriceFields
    }
    mainColor {
      ...ColorFields
    }
    price {
      ...PriceFields
    }
    signings {
      ...SigningFields
    }
    simplifiedImages {
      ...SimplifiedImageEntryFields
    }
    size {
      ...SizeFields
    }
    sizeInformation {
      ...SizeInformationFields
    }
  }
  ${PriceFieldsFragmentDoc}
  ${ColorFieldsFragmentDoc}
  ${SigningFieldsFragmentDoc}
  ${SimplifiedImageEntryFieldsFragmentDoc}
  ${SizeFieldsFragmentDoc}
  ${SizeInformationFieldsFragmentDoc}
`;
export const VariantSizeFieldsFragmentDoc = gql`
  fragment VariantSizeFields on VariantSize {
    formattedValue
    value
  }
`;
export const LookbookProductFieldsFragmentDoc = gql`
  fragment LookbookProductFields on Product {
    name
    code
    url
    baseProduct
    formattedLowestPrice
    fromPrice {
      ...PriceFields
    }
    mainColor {
      ...ColorFields
    }
    price {
      ...PriceFields
    }
    signings {
      ...SigningFields
    }
    simplifiedImages {
      ...SimplifiedImageEntryFields
    }
    size {
      ...VariantSizeFields
    }
    sizeInformation {
      ...SizeInformationFields
    }
    availableStyleVariantsSorted {
      code
      productAppUrl
    }
  }
  ${PriceFieldsFragmentDoc}
  ${ColorFieldsFragmentDoc}
  ${SigningFieldsFragmentDoc}
  ${SimplifiedImageEntryFieldsFragmentDoc}
  ${VariantSizeFieldsFragmentDoc}
  ${SizeInformationFieldsFragmentDoc}
`;
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on Image {
    url
  }
`;
export const BaseSizeFieldsFragmentDoc = gql`
  fragment BaseSizeFields on BaseSize {
    formattedValue
    value
  }
`;
export const SizeMeasurementFieldsFragmentDoc = gql`
  fragment SizeMeasurementFields on SizeMeasurementsItem {
    hip
    inseam
    legOpening
    size
    thigh
    totalLength
    waist
    acrossShoulder
    back
    sleeve
    centerBackLength
    chest
  }
`;
export const SizeMeasurementsObjFieldsFragmentDoc = gql`
  fragment SizeMeasurementsObjFields on SizeMeasurementsObj {
    category
    sizeMeasurements {
      ...SizeMeasurementFields
    }
  }
  ${SizeMeasurementFieldsFragmentDoc}
`;
export const SizeMeasurementsFieldsFragmentDoc = gql`
  fragment SizeMeasurementsFields on SizeMeasurements {
    id
    sizeMeasurements {
      ...SizeMeasurementsObjFields
    }
  }
  ${SizeMeasurementsObjFieldsFragmentDoc}
`;
export const UspsFieldsFragmentDoc = gql`
  fragment UspsFields on Usp {
    html
  }
`;
export const SocialMediaChannelFieldsFragmentDoc = gql`
  fragment SocialMediaChannelFields on SocialMediaChannel {
    code
    name
    target
    url
  }
`;
export const PaymentMethodsFieldsFragmentDoc = gql`
  fragment PaymentMethodsFields on PaymentMethods {
    code
    imageCssClass
    imageFooterCssClass
    name
    target
    url
  }
`;
export const StoreAddressFieldsFragmentDoc = gql`
  fragment StoreAddressFields on StoreAddress {
    country
    address
    address2
    city
    streetname
    multiLineAddress
    address1Local
    postalCode
    streetnumber
    singleLineAddress
    address2Local
    phone
    state
  }
`;
export const StoreFieldsFragmentDoc = gql`
  fragment StoreFields on Store {
    contentPageUrl
    saturday
    storeType
    address {
      ...StoreAddressFields
    }
    latitude
    thursday
    stockIndication
    sunday
    tuesday
    name
    wednesday
    friday
    sapStoreNr
    longitude
    monday
  }
  ${StoreAddressFieldsFragmentDoc}
`;
export const AnalyticsDataFieldsFragmentDoc = gql`
  fragment AnalyticsDataFields on AnalyticsData {
    key
    value
  }
`;
export const RecommendedProductFieldsFragmentDoc = gql`
  fragment RecommendedProductFields on RecommendedProduct {
    baseProduct
    code
    name
    price {
      ...PriceFields
    }
    url
  }
  ${PriceFieldsFragmentDoc}
`;
export const RecommendedProductsFieldsFragmentDoc = gql`
  fragment RecommendedProductsFields on RecommendedProducts {
    maxMobileProducts
    maxProducts
    maxTabletProducts
    products {
      ...RecommendedProductFields
    }
    title
  }
  ${RecommendedProductFieldsFragmentDoc}
`;
export const RecommendationsFieldsFragmentDoc = gql`
  fragment RecommendationsFields on Recommendations {
    analytics {
      ...AnalyticsDataFields
    }
    productCodes
    recommendedProducts {
      ...RecommendedProductsFields
    }
  }
  ${AnalyticsDataFieldsFragmentDoc}
  ${RecommendedProductsFieldsFragmentDoc}
`;
export const BaseProductFieldsFragmentDoc = gql`
  fragment BaseProductFields on BaseProduct {
    name
    url
    price {
      ...PriceFields
    }
  }
  ${PriceFieldsFragmentDoc}
`;
export const PagedProductFieldsFragmentDoc = gql`
  fragment PagedProductFields on PagedProduct {
    code
    url
  }
`;
export const PageFieldsFragmentDoc = gql`
  fragment PageFields on Page {
    currentPage
    totalPageCount
    totalProductCount
    products {
      ...PagedProductFields
    }
  }
  ${PagedProductFieldsFragmentDoc}
`;
export const MinimumStyleVariantImagesFieldsFragmentDoc = gql`
  fragment MinimumStyleVariantImagesFields on StyleVariantImages {
    large
    primaryImage
    swatch
    thumbnail
    thumbnailAltText
  }
`;
export const StyleVariantSigningFieldsFragmentDoc = gql`
  fragment StyleVariantSigningFields on StyleVariantSigning {
    backgroundColour
    cssClasses
    discountSigning
    label
    productSigningBackgroundColour
    productSigningTextColour
    renderAsText
    showOnPdp
    textColour
  }
`;
export const StyleVariantFieldsFragmentDoc = gql`
  fragment StyleVariantFields on StyleVariant {
    code
    color
    facetColorStyle
    formattedBasePrice
    formattedFromPrice
    formattedIntermediaryPrice
    formattedLowestPrice
    images {
      ...MinimumStyleVariantImagesFields
    }
    isProductHasReviews
    mainColor
    name
    productAppUrl
    productFiberRankingJson
    productUrl
    signing {
      ...StyleVariantSigningFields
    }
    unformattedBasePrice
    unformattedFromPrice
    unformattedIntermediaryPrice
  }
  ${MinimumStyleVariantImagesFieldsFragmentDoc}
  ${StyleVariantSigningFieldsFragmentDoc}
`;
export const BreadCrumbFieldsFragmentDoc = gql`
  fragment BreadCrumbFields on Breadcrumb {
    name
    url
  }
`;
export const DeliveryOptionFieldsFragmentDoc = gql`
  fragment DeliveryOptionFields on DeliveryOption {
    deliveryCost
    icon
    name
  }
`;
export const DeliveryOptionsFieldsFragmentDoc = gql`
  fragment DeliveryOptionsFields on DeliveryOptions {
    deliveryOptions {
      ...DeliveryOptionFields
    }
  }
  ${DeliveryOptionFieldsFragmentDoc}
`;
export const ExplodingViewFieldsFragmentDoc = gql`
  fragment ExplodingViewFields on ExplodingView {
    explodingViewDescription
    explodingViewSvgUrl
    explodingViewTitle
  }
`;
export const FiberFieldsFragmentDoc = gql`
  fragment FiberFields on Fiber {
    code
    descriptionLong
    descriptionShort
    percentage
    ranking
    score
    type
  }
`;
export const GenericFibersFieldsFragmentDoc = gql`
  fragment GenericFibersFields on GenericFibers {
    fibers {
      ...FiberFields
    }
  }
  ${FiberFieldsFragmentDoc}
`;
export const ProductFibersFieldsFragmentDoc = gql`
  fragment ProductFibersFields on ProductFibers {
    fibers {
      ...FiberFields
    }
    ranking
    score
  }
  ${FiberFieldsFragmentDoc}
`;
export const FiberRankingsFragmentDoc = gql`
  fragment FiberRankings on FiberRankingData {
    genericFiberRankingData {
      ...GenericFibersFields
    }
    productFiberRankingData {
      ...ProductFibersFields
    }
  }
  ${GenericFibersFieldsFragmentDoc}
  ${ProductFibersFieldsFragmentDoc}
`;
export const FiberRankingFieldsFragmentDoc = gql`
  fragment FiberRankingFields on FiberRanking {
    fiberRanking {
      ...FiberRankings
    }
    productAudience
  }
  ${FiberRankingsFragmentDoc}
`;
export const MannequinFieldsFragmentDoc = gql`
  fragment MannequinFields on Mannequin {
    imperialMessage
    metricMessage
    additionalSizeInfo
  }
`;
export const ReturnFeeFieldsFragmentDoc = gql`
  fragment ReturnFeeFields on ReturnFee {
    amount
    color
    message
    returnType
  }
`;
export const StockLevelMessageFieldsFragmentDoc = gql`
  fragment StockLevelMessageFields on StockLevelMessage {
    key
    value
  }
`;
export const StockValueFieldsFragmentDoc = gql`
  fragment StockValueFields on StockValue {
    stockLevel
    stockQuantity
  }
`;
export const StockValuesFieldsFragmentDoc = gql`
  fragment StockValuesFields on StockValues {
    key
    value {
      ...StockValueFields
    }
  }
  ${StockValueFieldsFragmentDoc}
`;
export const StockMappingValueFieldsFragmentDoc = gql`
  fragment StockMappingValueFields on StockMappingValue {
    thresholdsLeft {
      ...StockValueFields
    }
    thresholdsRight {
      ...StockValuesFields
    }
  }
  ${StockValueFieldsFragmentDoc}
  ${StockValuesFieldsFragmentDoc}
`;
export const StockMappingFieldsFragmentDoc = gql`
  fragment StockMappingFields on StockMapping {
    key
    value {
      ...StockMappingValueFields
    }
  }
  ${StockMappingValueFieldsFragmentDoc}
`;
export const StockLevelFieldsFragmentDoc = gql`
  fragment StockLevelFields on StockLevel {
    stockLevel
    stockLevelMessages {
      ...StockLevelMessageFields
    }
    stockMapping {
      ...StockMappingFields
    }
    stockMessage
    stockQuantity
  }
  ${StockLevelMessageFieldsFragmentDoc}
  ${StockMappingFieldsFragmentDoc}
`;
export const SustainabilityFieldsFragmentDoc = gql`
  fragment SustainabilityFields on Sustainability {
    cradleToCradle
    presenceOfPlasticMicroFiber
    madeToLast
  }
`;
export const FactoryFieldsFragmentDoc = gql`
  fragment FactoryFields on Factory {
    id
    name
    country
  }
`;
export const TraceabilityFieldsFragmentDoc = gql`
  fragment TraceabilityFields on Traceability {
    supplierName
    garmentSupplierID
    garmentManufacturing {
      ...FactoryFields
    }
    garmentProcessingDye {
      ...FactoryFields
    }
    garmentProcessingEmbroidery {
      ...FactoryFields
    }
    garmentProcessingPrint {
      ...FactoryFields
    }
    garmentProcessingWash {
      ...FactoryFields
    }
    fabricSupplierID
    fabricManufacturingWeavingKnitting {
      ...FactoryFields
    }
    fabricProcessingDye {
      ...FactoryFields
    }
    fabricProcessingEmbroidery {
      ...FactoryFields
    }
    fabricProcessingPrint {
      ...FactoryFields
    }
  }
  ${FactoryFieldsFragmentDoc}
`;
export const VideoFieldsFragmentDoc = gql`
  fragment VideoFields on Video {
    id
    mode
    type
  }
`;
export const WashingLabelFieldsFragmentDoc = gql`
  fragment WashingLabelFields on WashingLabel {
    iconCssClass
    label
  }
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on Product {
    algoliaObjectId
    id
    analyticsCategory
    approvalStatus
    appUrl
    availableStyleVariantsSorted {
      ...StyleVariantFields
    }
    baseProduct
    baseProductStockNotificationMessageEN
    breadcrumbs {
      ...BreadCrumbFields
    }
    canonicalUrl
    code
    color {
      ...ColorFields
    }
    colorFinishDescription
    colorFinishTitle
    colorPLPUrl
    comingSoon
    defaultPDPTemplate
    deliveryOptions {
      ...DeliveryOptionsFields
    }
    detailsBullets
    detailsTitle
    dimensionsBullets
    ean
    explodingView {
      ...ExplodingViewFields
    }
    fabricBullets
    fabricDescription
    fabricHeader
    fabricTitle
    factoryId
    featuresIntro
    featuresTitle
    fiberRanking {
      ...FiberRankingFields
    }
    fit
    fitEN
    fromPrice {
      ...PriceFields
    }
    formattedLowestPrice
    hasLocalStoreStock
    intermediaryPrice {
      ...PriceFields
    }
    isGiftCard
    isProductHasReview
    mainColor {
      ...ColorFields
    }
    mannequin {
      ...MannequinFields
    }
    materialGroup
    metaDescription
    metaKeywords
    metaTitle
    mccValue
    name
    nameEN
    oneSize
    ppcPDPTemplate
    price {
      ...PriceFields
    }
    programDescription
    programEN
    programTitle
    purchasable
    returnFee {
      ...ReturnFeeFields
    }
    salesDiscountsEndDate
    seoName
    shouldRequestSizeMeasurements
    shouldUseDarkBackgroundImgs
    showStoreStockLocator
    signings {
      ...SigningFields
    }
    simplifiedImages {
      ...SimplifiedImageEntryFields
    }
    size {
      ...VariantSizeFields
    }
    sizeChartUrl
    sizeInformation {
      ...SizeInformationFields
    }
    staticCategoryPath
    staticCategoryPathIds
    staticCategoryPathSeo
    stockInformation {
      ...StockLevelFields
    }
    stockNotificationLink
    stockNotificationMessageEN
    styleDescription
    styleFamilyEN
    subTargetAudience
    supplierReference
    sustainability {
      ...SustainabilityFields
    }
    traceability {
      ...TraceabilityFields
    }
    url
    useExternalUrl
    videos {
      ...VideoFields
    }
    washingLabels {
      ...WashingLabelFields
    }
  }
  ${StyleVariantFieldsFragmentDoc}
  ${BreadCrumbFieldsFragmentDoc}
  ${ColorFieldsFragmentDoc}
  ${DeliveryOptionsFieldsFragmentDoc}
  ${ExplodingViewFieldsFragmentDoc}
  ${FiberRankingFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
  ${MannequinFieldsFragmentDoc}
  ${ReturnFeeFieldsFragmentDoc}
  ${SigningFieldsFragmentDoc}
  ${SimplifiedImageEntryFieldsFragmentDoc}
  ${VariantSizeFieldsFragmentDoc}
  ${SizeInformationFieldsFragmentDoc}
  ${StockLevelFieldsFragmentDoc}
  ${SustainabilityFieldsFragmentDoc}
  ${TraceabilityFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${WashingLabelFieldsFragmentDoc}
`;
export const ProductsProductFieldsFragmentDoc = gql`
  fragment ProductsProductFields on ProductsProduct {
    algoliaObjectId
    availableStyleVariantsSorted {
      ...StyleVariantFields
    }
    baseProduct
    color {
      ...ColorFields
    }
    featuresIntro
    fiberRanking {
      ...FiberRankingFields
    }
    fromPrice {
      ...PriceFields
    }
    formattedLowestPrice
    intermediaryPrice {
      ...PriceFields
    }
    mainColor {
      ...ColorFields
    }
    name
    nameEN
    price {
      ...PriceFields
    }
    simplifiedImages {
      ...SimplifiedImageEntryFields
    }
    url
    ean
    code
    fitEN
    materialGroup
    programEN
    size {
      ...SizeFields
    }
    staticCategoryPath
    staticCategoryPathIds
    subTargetAudience
    baseProductStockNotificationMessageEN
    styleFamilyEN
    oneSize
    sizeInformation {
      ...SizeInformationFields
    }
    isGiftCard
    signings {
      ...SigningFields
    }
  }
  ${StyleVariantFieldsFragmentDoc}
  ${ColorFieldsFragmentDoc}
  ${FiberRankingFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
  ${SimplifiedImageEntryFieldsFragmentDoc}
  ${SizeFieldsFragmentDoc}
  ${SizeInformationFieldsFragmentDoc}
  ${SigningFieldsFragmentDoc}
`;
export const PostalCodeRangeFieldsFragmentDoc = gql`
  fragment PostalCodeRangeFields on PostalCodeRange {
    description
    postalCodeRangeHigh
    postalCodeRangeLow
  }
`;
export const PostalCodeFieldsFragmentDoc = gql`
  fragment PostalCodeFields on PostalCode {
    description
    postalCode
  }
`;
export const ValidationRuleFieldsFragmentDoc = gql`
  fragment ValidationRuleFields on ValidationRule {
    field
    maxLength
    minLength
    regex
    required
    validationType
    inputRestriction
    phoneNumberEnforceInternational
    phoneNumberEnforceSameRegion
    blockedPostalCodeRanges {
      ...PostalCodeRangeFields
    }
    blockedPostalCodes {
      ...PostalCodeFields
    }
  }
  ${PostalCodeRangeFieldsFragmentDoc}
  ${PostalCodeFieldsFragmentDoc}
`;
export const ValidationsFieldsFragmentDoc = gql`
  fragment ValidationsFields on Validations {
    validations {
      ...ValidationRuleFields
    }
  }
  ${ValidationRuleFieldsFragmentDoc}
`;
export const LanguageFieldsFragmentDoc = gql`
  fragment LanguageFields on Language {
    active
    isocode
    name
    nativeName
  }
`;
export const CountryFieldsFragmentDoc = gql`
  fragment CountryFields on Country {
    addressStreetFormat
    isocode
    name
    phoneCode
    supportedLanguages {
      ...LanguageFields
    }
  }
  ${LanguageFieldsFragmentDoc}
`;
export const AddressConfigurationFieldsFragmentDoc = gql`
  fragment AddressConfigurationFields on AddressConfiguration {
    enabled
    service
  }
`;
export const FacetDisplayDataListFieldsFragmentDoc = gql`
  fragment FacetDisplayDataListFields on FacetDisplayDataList {
    code
    displayName
    translated
  }
`;
export const SortingOptionsFieldsFragmentDoc = gql`
  fragment SortingOptionsFields on SortingOptions {
    code
    name
    nameEN
  }
`;
export const ProductTileConfigurationFieldsFragmentDoc = gql`
  fragment ProductTileConfigurationFields on ProductTileConfiguration {
    showHoverImageOnPlp
    showHoverImageOnRecommendations
    showHoverImageOnRvp
    showQuickViewOnPlp
    showQuickViewOnRecommendations
    showQuickViewOnRvp
    showStyleVariantsOnPlp
    showStyleVariantsOnRecommendations
    showStyleVariantsOnRvp
  }
`;
export const ColorStylesFieldsFragmentDoc = gql`
  fragment ColorStylesFields on FacetColorStyleItem {
    key
    value
  }
`;
export const ConfigurationFieldsFragmentDoc = gql`
  fragment ConfigurationFields on Configuration {
    id
    activateNewMyAccount
    activeApp
    addressAutoCompleteConfiguration {
      ...AddressConfigurationFields
    }
    addressSuggestionConfiguration {
      ...AddressConfigurationFields
    }
    adyenUseSavedPayment
    adyenWebDropinEnabled
    agreeToPrivacyVisibleOnLogin
    agreeToPrivacyVisibleOnLoginCheckout
    agreeToPrivacyVisibleOnRegistration
    algoliaAppId
    algoliaInsightsApiKey
    algoliaProductPrimaryIndexName
    allowGiftCards
    applePayEnabled
    applePaySessionTimeout
    applePayVersion
    appStoreId
    appStoreName
    captchaInvisibleSiteKey
    captchaSiteKey
    checkoutV2
    cookieWallVersion
    currentCurrencySymbol
    currentCurrencyNumberFormat
    currentCurrencyIsoCode
    currentCurrencyDigits
    debounceTimeout
    defaultCurrency
    displayFiberRankingOnProductTiles
    displayFiberRankingBadgeOnPDP
    displayFiberRankingInfoOnPDP
    emptyPageLayoutSlotDeliveryKey
    enableAudioEye
    enableBodyOnlyWebview
    enableCookieSettings
    enableCookieWall
    enableCookieWallV2
    enableCartItemsNotReservedMessage
    enableDarkBgImages
    enableExponea
    enableFitAnalytics
    enableHuman
    enableKidswearNewsletter
    enableNewsletterSubscrOverlayABTest
    enablePDPBreadcrumbs
    enablePdpChargedReturnsMessage
    enablePdpClosestStoreDeliveryOption
    enablePdpDeliveryOptions
    enablePdpFreeReturnsMessage
    enablePdpLocalStoreStockSizeAvailability
    enablePdpShippingEstimate
    enablePdpShippingEstimateDisclaimer
    enablePLPLoadMore
    enableQubit
    enableQubitPDC
    enableSizeGuideProductMeasurements
    enableUniversalLinks
    enableUPSMyChoiceSite
    enableUspScroll
    enableWishlist
    errorPageLayoutSlotDeliveryKey
    exponeaPushId
    exponeaPushNotificationDelay
    exponeaPushNotificationEnabled
    exponeaPushNotificationPageViewNumber
    exponeaPushNotificationRequestPerSession
    exponeaSubDomain
    exponeaTokenId
    exponeaTagExecution
    exponeaSpaOnHashChange
    exponeaSpaBanners
    exponeaSpaTags
    externalTaxEnabled
    lowestPriceDisplay
    showLowestPriceText
    facetDisplayDataList {
      ...FacetDisplayDataListFields
    }
    sortingOptions {
      ...SortingOptionsFields
    }
    fiberRanking
    giftCardGroupLength
    giftCardGroupSeparator
    giftCardPrefixes
    hasDoubleNewsletterOptIn
    hasImplicitTermsAndConditions
    hasMyAccount
    homepageLayoutSlotDeliveryKey
    isBodyOnlyView
    isChangeCountryInBillingAddress
    isEnableQrCode
    isEnableShareCartEmail
    isLineSession
    klarnaHighOnlineReturnsSegment
    localeFallback
    loyaltyPointSystem
    masterLayoutSlotDeliveryKey
    myAccountPointHistoryCount
    navigationType
    net
    paymentMethods
    payPalExpressEnabled
    plpV2
    productBasePriceColor
    productTileConfiguration {
      ...ProductTileConfigurationFields
    }
    qubitEventsEnabled
    qubitSmartServeUrl
    qubitTagExecution
    qubitTrackingId
    quickViewOverlayDeliveryKey
    repoId
    requestPdpUspsSFCSizeLevel
    showMessageBoxNotifications
    siteHasPhysicalStores
    siteNameDisplay
    slice
    upsMyChoiceDefaultChecked
    verticalFacetNavigation
    voucherFormDefaultShown
    showStrikethroughPrices
    sizeGuideUnit
    staticFiltersQuery
    supportedFitType
    enableCLTAnalytics
    enableReturnFeeMDDC
    salesDiscountsEndDate
    plpSystemTextsDeliveryKey
    forterSiteId
    plpLayoutSlotDeliveryKey
    srpLayoutSlotDeliveryKey
    searchV2
    colorStyles {
      ...ColorStylesFields
    }
  }
  ${AddressConfigurationFieldsFragmentDoc}
  ${FacetDisplayDataListFieldsFragmentDoc}
  ${SortingOptionsFieldsFragmentDoc}
  ${ProductTileConfigurationFieldsFragmentDoc}
  ${ColorStylesFieldsFragmentDoc}
`;
export const AmplienceDeliveryKeyFieldsFragmentDoc = gql`
  fragment AmplienceDeliveryKeyFields on AmplienceDeliveryKey {
    defaultPDPTemplate
    ppcPDPTemplate
  }
`;
export const CurrencyFieldsFragmentDoc = gql`
  fragment CurrencyFields on Currency {
    active
    isocode
    name
    symbol
    digits
  }
`;
export const PersonalisedDiscountFieldsFragmentDoc = gql`
  fragment PersonalisedDiscountFields on PersonalisedDiscount {
    formattedValue
    expirationDate
    applicableToDiscountedProducts
    trafficSource
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    type
    name
    uid
    analyticsCustomerType
    currency {
      ...CurrencyFields
    }
    customerId
    customerType
    displayUid
    email
    firstName
    hashedUserId
    hashedUserIp
    internal
    language {
      ...LanguageFields
    }
    lastName
    loyaltySubscribed
    memberCard
    thresholdCustomerType
    personalisedDiscount {
      ...PersonalisedDiscountFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${LanguageFieldsFragmentDoc}
  ${PersonalisedDiscountFieldsFragmentDoc}
`;
export const CartProductFieldsFragmentDoc = gql`
  fragment CartProductFields on CartProduct {
    code
    simplifiedImages {
      ...ImageEntryFields
    }
    baseProduct
    ean
    fitEN
    isGiftCard
    materialGroup
    name
    nameEN
    oneSize
    programEN
    shouldUseDarkBackgroundImgs
    staticCategoryPath
    staticCategoryPathIds
    stockNotificationMessageEN
    styleFamilyEN
    subTargetAudience
    url
    size {
      ...SizeFields
    }
    price {
      ...PriceFields
    }
    mainColor {
      ...ColorFields
    }
    fromPrice {
      ...PriceFields
    }
    color {
      ...ColorFields
    }
  }
  ${ImageEntryFieldsFragmentDoc}
  ${SizeFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
  ${ColorFieldsFragmentDoc}
`;
export const CartEntryFieldsFragmentDoc = gql`
  fragment CartEntryFields on CartEntry {
    basePrice {
      ...PriceFields
    }
    entryNumber
    fromPrice {
      ...PriceFields
    }
    gift
    product {
      ...CartProductFields
    }
    quantity
    removable
    taxAmount {
      ...PriceFields
    }
    totalPrice {
      ...PriceFields
    }
    totalPriceWithoutDiscount {
      ...PriceFields
    }
    updateable
  }
  ${PriceFieldsFragmentDoc}
  ${CartProductFieldsFragmentDoc}
`;
export const CartModificationFieldsFragmentDoc = gql`
  fragment CartModificationFields on CartModification {
    entry {
      ...CartEntryFields
    }
    quantity
    quantityAdded
    statusCode
    statusMessage
  }
  ${CartEntryFieldsFragmentDoc}
`;
export const PromotionDetailsFieldsFragmentDoc = gql`
  fragment PromotionDetailsFields on PromotionDetails {
    code
    description
    promotionType
    name
  }
`;
export const PromotionFieldsFragmentDoc = gql`
  fragment PromotionFields on Promotion {
    description
    discountPrice {
      ...PriceFields
    }
    promotion {
      ...PromotionDetailsFields
    }
  }
  ${PriceFieldsFragmentDoc}
  ${PromotionDetailsFieldsFragmentDoc}
`;
export const PhoneDetailsFieldsFragmentDoc = gql`
  fragment PhoneDetailsFields on PhoneDetails {
    countryCodeIso
    prefix
  }
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    phoneDetails {
      ...PhoneDetailsFields
    }
    verified
  }
  ${PhoneDetailsFieldsFragmentDoc}
`;
export const CartCustomerFieldsFragmentDoc = gql`
  fragment CartCustomerFields on CartCustomer {
    customerId
    customerType
    email
    loyaltySubscribed
  }
`;
export const TransitDaysFieldsFragmentDoc = gql`
  fragment TransitDaysFields on TransitDays {
    highestDeliveryDate
    lowerBound
    lowestDeliveryDate
    maxDeliveryDateFormatted
    minDeliveryDateFormatted
    upperBound
  }
`;
export const DeliveryModeFieldsFragmentDoc = gql`
  fragment DeliveryModeFields on DeliveryMode {
    code
    deliveryCost {
      ...PriceFields
    }
    deliveryMethod
    icon
    name
    omsDeliveryMethod
    requestCarrierCustomerId
    shipper
    transitDays {
      ...TransitDaysFields
    }
    description
  }
  ${PriceFieldsFragmentDoc}
  ${TransitDaysFieldsFragmentDoc}
`;
export const CartFieldsFragmentDoc = gql`
  fragment CartFields on Cart {
    adyenAmount
    appliedOrderPromotions {
      ...PromotionFields
    }
    assistedSale
    isGsTaxCalculated
    isPayPalExpressPayment
    potentialOrderPromotions {
      ...PromotionFields
    }
    validationStep
    billingAddress {
      ...AddressFields
    }
    code
    customer {
      ...CartCustomerFields
    }
    deliveryAddress {
      ...AddressFields
    }
    deliveryCost {
      ...PriceFields
    }
    deliveryMode {
      ...DeliveryModeFields
    }
    deliveryTotalTax {
      ...PriceFields
    }
    entries {
      ...CartEntryFields
    }
    externalTaxEnabled
    grandTotalWGCSGross {
      ...PriceFields
    }
    grandTotalWGCSTax {
      ...PriceFields
    }
    grandTotalWGCSNet {
      ...PriceFields
    }
    guid
    net
    orderDiscounts {
      ...PriceFields
    }
    subTotal {
      ...PriceFields
    }
    totalAfterGiftCards {
      ...PriceFields
    }
    totalDiscounts {
      ...PriceFields
    }
    totalItems
    totalPrice {
      ...PriceFields
    }
    totalPriceNoTaxes {
      ...PriceFields
    }
    totalPriceWithTax {
      ...PriceFields
    }
    totalTax {
      ...PriceFields
    }
    voucherTotalDiscount {
      ...PriceFields
    }
  }
  ${PromotionFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${CartCustomerFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
  ${DeliveryModeFieldsFragmentDoc}
  ${CartEntryFieldsFragmentDoc}
`;
export const CartMutationFieldsFragmentDoc = gql`
  fragment CartMutationFields on CartMutation {
    type
    cartModifications {
      ...CartModificationFields
    }
    cart {
      ...CartFields
    }
  }
  ${CartModificationFieldsFragmentDoc}
  ${CartFieldsFragmentDoc}
`;
export const ErrorMessageFieldsFragmentDoc = gql`
  fragment ErrorMessageFields on ErrorMessage {
    message
  }
`;
export const VoucherApplyResultFieldsFragmentDoc = gql`
  fragment VoucherApplyResultFields on VoucherApplyResult {
    type
    cart {
      ...CartFields
    }
    errors {
      ...ErrorMessageFields
    }
  }
  ${CartFieldsFragmentDoc}
  ${ErrorMessageFieldsFragmentDoc}
`;
export const WishlistPageFieldsFragmentDoc = gql`
  fragment WishlistPageFields on WishlistPage {
    pageSize
    currentPage
    totalNumberOfResults
    numberOfPages
  }
`;
export const WishlistPriceFieldsFragmentDoc = gql`
  fragment WishlistPriceFields on WishlistPrice {
    currencyIso
    formattedValue
    formattedValueWithoutCurrency
    nrDigits
    value
    currencyData {
      ...CurrencyFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
`;
export const WishlistImageDataFieldsFragmentDoc = gql`
  fragment WishlistImageDataFields on WishlistImageData {
    url
    seoDescription
    productImageMediaTypeEnum
    version
  }
`;
export const WishlistSimplifiedImageDataFieldsFragmentDoc = gql`
  fragment WishlistSimplifiedImageDataFields on WishlistSimplifiedImageData {
    E01 {
      ...WishlistImageDataFields
    }
    E02 {
      ...WishlistImageDataFields
    }
    F01 {
      ...WishlistImageDataFields
    }
    F02 {
      ...WishlistImageDataFields
    }
    M01 {
      ...WishlistImageDataFields
    }
    M02 {
      ...WishlistImageDataFields
    }
    M03 {
      ...WishlistImageDataFields
    }
    M04 {
      ...WishlistImageDataFields
    }
    M05 {
      ...WishlistImageDataFields
    }
    M06 {
      ...WishlistImageDataFields
    }
    M07 {
      ...WishlistImageDataFields
    }
    M08 {
      ...WishlistImageDataFields
    }
    Z01 {
      ...WishlistImageDataFields
    }
    Z02 {
      ...WishlistImageDataFields
    }
    Z03 {
      ...WishlistImageDataFields
    }
    Z04 {
      ...WishlistImageDataFields
    }
    Z05 {
      ...WishlistImageDataFields
    }
    Z06 {
      ...WishlistImageDataFields
    }
    Z07 {
      ...WishlistImageDataFields
    }
    Z08 {
      ...WishlistImageDataFields
    }
  }
  ${WishlistImageDataFieldsFragmentDoc}
`;
export const WishlistProductFieldsFragmentDoc = gql`
  fragment WishlistProductFields on WishlistProduct {
    code
    staticCategoryPathIds
    color {
      ...ColorFields
    }
    fromPrice {
      ...WishlistPriceFields
    }
    url
    productFiberRanking {
      ...ProductFibersFields
    }
    discountPercentage
    fitEN
    baseProductStockLevelStatus
    subTargetAudience
    price {
      ...WishlistPriceFields
    }
    signings {
      ...SigningFields
    }
    simplifiedImageData {
      ...WishlistSimplifiedImageDataFields
    }
    mainColor {
      ...ColorFields
    }
    materialGroup
    staticCategoryPath
    displayAlternateHoverImage
    name
    isDiscontinued
    formattedLowestPrice
    formattedLowestPriceDiscountPercentage
    formattedOriginalPriceDiscountPercentage
  }
  ${ColorFieldsFragmentDoc}
  ${WishlistPriceFieldsFragmentDoc}
  ${ProductFibersFieldsFragmentDoc}
  ${SigningFieldsFragmentDoc}
  ${WishlistSimplifiedImageDataFieldsFragmentDoc}
`;
export const WishlistFieldsFragmentDoc = gql`
  fragment WishlistFields on Wishlist {
    pagination {
      ...WishlistPageFields
    }
    results {
      ...WishlistProductFields
    }
  }
  ${WishlistPageFieldsFragmentDoc}
  ${WishlistProductFieldsFragmentDoc}
`;
export const CommonDataFieldsFragmentDoc = gql`
  fragment CommonDataFields on CommonData {
    internal
    gender
    newsletterUser
    ampliencePreviewTime
    customerType
    assistedSaleUserType
    customerId
    correlationId
    assistedSaleUserID
    amplienceTSP
    hashedUserId
    amplienceVSE
    numberOfWishListItems
    thresholdCustomerType
    accountType
    asmInstoreLocale
    hashedIp
    ageGroup
    loggedInStatus
    firstName
    csrfToken
    msdAbTestingEnabled
    assistedSale
    isAmpliencePreview
    gsOrigin
  }
`;
export const SizeGuidesLabelTranslationsFieldsFragmentDoc = gql`
  fragment SizeGuidesLabelTranslationsFields on SizeGuidesLabelTranslations {
    arm
    chest
    foot
    hip
    inseam
    length
    size
    waist
  }
`;
export const SizeGuideMeasuresystemTableItemFieldsFragmentDoc = gql`
  fragment SizeGuideMeasuresystemTableItemFields on SizeGuideMeasuresystemTableItem {
    tableHtml
  }
`;
export const SizeGuideMeasuresystemFieldsFragmentDoc = gql`
  fragment SizeGuideMeasuresystemFields on SizeGuideMeasuresystem {
    code
    name
    tables {
      ...SizeGuideMeasuresystemTableItemFields
    }
  }
  ${SizeGuideMeasuresystemTableItemFieldsFragmentDoc}
`;
export const SizeGuideCategoryFieldsFragmentDoc = gql`
  fragment SizeGuideCategoryFields on SizeGuideCategory {
    code
    descriptions
    measuresystems {
      ...SizeGuideMeasuresystemFields
    }
    name
  }
  ${SizeGuideMeasuresystemFieldsFragmentDoc}
`;
export const SizeGuideFieldsFragmentDoc = gql`
  fragment SizeGuideFields on SizeGuide {
    categories {
      ...SizeGuideCategoryFields
    }
    code
    gender
    name
  }
  ${SizeGuideCategoryFieldsFragmentDoc}
`;
export const SizeGuidesFieldsFragmentDoc = gql`
  fragment SizeGuidesFields on SizeGuides {
    id
    labelTranslations {
      ...SizeGuidesLabelTranslationsFields
    }
    sizeGuides {
      ...SizeGuideFields
    }
    sizeUnit
  }
  ${SizeGuidesLabelTranslationsFieldsFragmentDoc}
  ${SizeGuideFieldsFragmentDoc}
`;
export const NavigationItemFieldsFragmentDoc = gql`
  fragment NavigationItemFields on NavigationItem {
    hideForNonLoggedInVisitors
    hideForLoggedInVisitors
    label
    labelAria
    styleModifier
    target
    url
    uid
  }
`;
export const NavigationItemRecursiveFieldsFragmentDoc = gql`
  fragment NavigationItemRecursiveFields on NavigationItem {
    ...NavigationItemFields
    children {
      ...NavigationItemFields
      children {
        ...NavigationItemFields
        children {
          ...NavigationItemFields
          children {
            ...NavigationItemFields
            children {
              ...NavigationItemFields
            }
          }
        }
      }
    }
  }
  ${NavigationItemFieldsFragmentDoc}
`;
export const NavigationFieldsFragmentDoc = gql`
  fragment NavigationFields on Navigation {
    id
    navigation {
      ...NavigationItemRecursiveFields
    }
  }
  ${NavigationItemRecursiveFieldsFragmentDoc}
`;
export const SubscriptionErrorFieldsFragmentDoc = gql`
  fragment SubscriptionErrorFields on SubscriptionError {
    code
    message
    reason
    subject
    subjectType
    type
  }
`;
export const SubscriptionFieldsFragmentDoc = gql`
  fragment SubscriptionFields on Subscription {
    type
    newsletterSubscribeSuccessType
    errors {
      ...SubscriptionErrorFields
    }
    subscribeStatusCode
  }
  ${SubscriptionErrorFieldsFragmentDoc}
`;
export const ShippingEstimatesFieldsFragmentDoc = gql`
  fragment ShippingEstimatesFields on ShippingEstimates {
    id
    description
  }
`;
export const CategoryPathsFieldsFragmentDoc = gql`
  fragment CategoryPathsFields on CategoryPaths {
    categories
  }
`;
export const FacetValueFieldsFragmentDoc = gql`
  fragment FacetValueFields on FacetValue {
    code
    count
    defaultValue
    name
    selected
    url
  }
`;
export const VisibleFacetFieldsFragmentDoc = gql`
  fragment VisibleFacetFields on VisibleFacet {
    code
    facetValues {
      ...FacetValueFields
    }
    name
    removeUrl
  }
  ${FacetValueFieldsFragmentDoc}
`;
export const CategorySortingFieldsFragmentDoc = gql`
  fragment CategorySortingFields on CategorySorting {
    code
    name
    nameEN
    selected
    url
  }
`;
export const CategoryDetailsFieldsFragmentDoc = gql`
  fragment CategoryDetailsFields on CategoryDetails {
    algoliaIndexName
    algoliaQueryId
    canonicalUrl
    categoryCode
    categoryName
    clearAllUrl
    h1
    metaDescription
    metaKeywords
    pageSize
    pageTitle
    plpAppUrl
    plpCategoryPath
    plpCategoryPathIds
    requestUrl
    selectedFacets {
      ...VisibleFacetFields
    }
    sorting {
      ...CategorySortingFields
    }
    visibleFacets {
      ...VisibleFacetFields
    }
  }
  ${VisibleFacetFieldsFragmentDoc}
  ${CategorySortingFieldsFragmentDoc}
`;
export const ExponeaCustomerAttributesResponseFieldsFragmentDoc = gql`
  fragment ExponeaCustomerAttributesResponseFields on ExponeaCustomerAttributesResponse {
    results {
      value
    }
  }
`;
export const LookbookProductsDocument = gql`
  query LookbookProducts($input: ProductsInput) {
    products(input: $input) {
      ...LookbookProductsProductFields
      availableStyleVariantsSorted {
        code
        productAppUrl
      }
    }
  }
  ${LookbookProductsProductFieldsFragmentDoc}
`;

/**
 * __useLookbookProductsQuery__
 *
 * To run a query within a React component, call `useLookbookProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookbookProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookbookProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLookbookProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<LookbookProductsQuery, LookbookProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LookbookProductsQuery, LookbookProductsQueryVariables>(
    LookbookProductsDocument,
    options
  );
}
export function useLookbookProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LookbookProductsQuery, LookbookProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LookbookProductsQuery, LookbookProductsQueryVariables>(
    LookbookProductsDocument,
    options
  );
}
export type LookbookProductsQueryHookResult = ReturnType<typeof useLookbookProductsQuery>;
export type LookbookProductsLazyQueryHookResult = ReturnType<typeof useLookbookProductsLazyQuery>;
export type LookbookProductsQueryResult = Apollo.QueryResult<
  LookbookProductsQuery,
  LookbookProductsQueryVariables
>;
export const LookbookProductDocument = gql`
  query LookbookProduct($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    product(siteType: $siteType, code: $code, locale: $locale) {
      ...LookbookProductFields
    }
  }
  ${LookbookProductFieldsFragmentDoc}
`;

/**
 * __useLookbookProductQuery__
 *
 * To run a query within a React component, call `useLookbookProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookbookProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookbookProductQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLookbookProductQuery(
  baseOptions: Apollo.QueryHookOptions<LookbookProductQuery, LookbookProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LookbookProductQuery, LookbookProductQueryVariables>(
    LookbookProductDocument,
    options
  );
}
export function useLookbookProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LookbookProductQuery, LookbookProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LookbookProductQuery, LookbookProductQueryVariables>(
    LookbookProductDocument,
    options
  );
}
export type LookbookProductQueryHookResult = ReturnType<typeof useLookbookProductQuery>;
export type LookbookProductLazyQueryHookResult = ReturnType<typeof useLookbookProductLazyQuery>;
export type LookbookProductQueryResult = Apollo.QueryResult<
  LookbookProductQuery,
  LookbookProductQueryVariables
>;
export const ProductDocument = gql`
  query Product($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    product(siteType: $siteType, code: $code, locale: $locale) {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const StyleVariantsDocument = gql`
  query StyleVariants($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    product(siteType: $siteType, code: $code, locale: $locale) {
      availableStyleVariantsSorted {
        ...StyleVariantFields
      }
    }
  }
  ${StyleVariantFieldsFragmentDoc}
`;

/**
 * __useStyleVariantsQuery__
 *
 * To run a query within a React component, call `useStyleVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStyleVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStyleVariantsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useStyleVariantsQuery(
  baseOptions: Apollo.QueryHookOptions<StyleVariantsQuery, StyleVariantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StyleVariantsQuery, StyleVariantsQueryVariables>(
    StyleVariantsDocument,
    options
  );
}
export function useStyleVariantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StyleVariantsQuery, StyleVariantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StyleVariantsQuery, StyleVariantsQueryVariables>(
    StyleVariantsDocument,
    options
  );
}
export type StyleVariantsQueryHookResult = ReturnType<typeof useStyleVariantsQuery>;
export type StyleVariantsLazyQueryHookResult = ReturnType<typeof useStyleVariantsLazyQuery>;
export type StyleVariantsQueryResult = Apollo.QueryResult<
  StyleVariantsQuery,
  StyleVariantsQueryVariables
>;
export const CoreProductInfoDocument = gql`
  query CoreProductInfo($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    product(siteType: $siteType, code: $code, locale: $locale) {
      url
      approvalStatus
      code
      name
    }
  }
`;

/**
 * __useCoreProductInfoQuery__
 *
 * To run a query within a React component, call `useCoreProductInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreProductInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreProductInfoQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCoreProductInfoQuery(
  baseOptions: Apollo.QueryHookOptions<CoreProductInfoQuery, CoreProductInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoreProductInfoQuery, CoreProductInfoQueryVariables>(
    CoreProductInfoDocument,
    options
  );
}
export function useCoreProductInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoreProductInfoQuery, CoreProductInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoreProductInfoQuery, CoreProductInfoQueryVariables>(
    CoreProductInfoDocument,
    options
  );
}
export type CoreProductInfoQueryHookResult = ReturnType<typeof useCoreProductInfoQuery>;
export type CoreProductInfoLazyQueryHookResult = ReturnType<typeof useCoreProductInfoLazyQuery>;
export type CoreProductInfoQueryResult = Apollo.QueryResult<
  CoreProductInfoQuery,
  CoreProductInfoQueryVariables
>;
export const ProductDynamicDataDocument = gql`
  query ProductDynamicData($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    productDynamicData(siteType: $siteType, code: $code, locale: $locale) {
      comingSoon
      fromPrice {
        ...PriceFields
      }
      price {
        ...PriceFields
      }
      intermediaryPrice {
        ...PriceFields
      }
      formattedLowestPrice
      purchasable
      showStoreStockLocator
      sizeInformation {
        ...SizeInformationFields
      }
      stockInformation {
        ...StockLevelFields
      }
    }
  }
  ${PriceFieldsFragmentDoc}
  ${SizeInformationFieldsFragmentDoc}
  ${StockLevelFieldsFragmentDoc}
`;

/**
 * __useProductDynamicDataQuery__
 *
 * To run a query within a React component, call `useProductDynamicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDynamicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDynamicDataQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useProductDynamicDataQuery(
  baseOptions: Apollo.QueryHookOptions<ProductDynamicDataQuery, ProductDynamicDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductDynamicDataQuery, ProductDynamicDataQueryVariables>(
    ProductDynamicDataDocument,
    options
  );
}
export function useProductDynamicDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductDynamicDataQuery,
    ProductDynamicDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductDynamicDataQuery, ProductDynamicDataQueryVariables>(
    ProductDynamicDataDocument,
    options
  );
}
export type ProductDynamicDataQueryHookResult = ReturnType<typeof useProductDynamicDataQuery>;
export type ProductDynamicDataLazyQueryHookResult = ReturnType<
  typeof useProductDynamicDataLazyQuery
>;
export type ProductDynamicDataQueryResult = Apollo.QueryResult<
  ProductDynamicDataQuery,
  ProductDynamicDataQueryVariables
>;
export const CartDocument = gql`
  query Cart($siteType: SiteType!, $locale: Locale!) {
    cart(siteType: $siteType, locale: $locale) {
      ...CartFields
    }
  }
  ${CartFieldsFragmentDoc}
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCartQuery(baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const WishlistDocument = gql`
  query Wishlist($siteType: SiteType!, $locale: Locale!) {
    wishlist(siteType: $siteType, locale: $locale) {
      ...WishlistFields
    }
  }
  ${WishlistFieldsFragmentDoc}
`;

/**
 * __useWishlistQuery__
 *
 * To run a query within a React component, call `useWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useWishlistQuery(
  baseOptions: Apollo.QueryHookOptions<WishlistQuery, WishlistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WishlistQuery, WishlistQueryVariables>(WishlistDocument, options);
}
export function useWishlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WishlistQuery, WishlistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WishlistQuery, WishlistQueryVariables>(WishlistDocument, options);
}
export type WishlistQueryHookResult = ReturnType<typeof useWishlistQuery>;
export type WishlistLazyQueryHookResult = ReturnType<typeof useWishlistLazyQuery>;
export type WishlistQueryResult = Apollo.QueryResult<WishlistQuery, WishlistQueryVariables>;
export const ConfigurationDocument = gql`
  query Configuration($siteType: SiteType!, $locale: Locale!) {
    configuration(siteType: $siteType, locale: $locale) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;

/**
 * __useConfigurationQuery__
 *
 * To run a query within a React component, call `useConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfigurationQuery, ConfigurationQueryVariables>(
    ConfigurationDocument,
    options
  );
}
export function useConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfigurationQuery, ConfigurationQueryVariables>(
    ConfigurationDocument,
    options
  );
}
export type ConfigurationQueryHookResult = ReturnType<typeof useConfigurationQuery>;
export type ConfigurationLazyQueryHookResult = ReturnType<typeof useConfigurationLazyQuery>;
export type ConfigurationQueryResult = Apollo.QueryResult<
  ConfigurationQuery,
  ConfigurationQueryVariables
>;
export const VisibleProductsDocument = gql`
  query VisibleProducts($siteType: SiteType!, $pageSize: Int!, $page: Int!, $locale: Locale!) {
    visibleProducts(siteType: $siteType, pageSize: $pageSize, page: $page, locale: $locale) {
      currentPage
      totalPageCount
      totalProductCount
      products {
        code
        url
      }
    }
  }
`;

/**
 * __useVisibleProductsQuery__
 *
 * To run a query within a React component, call `useVisibleProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisibleProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibleProductsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useVisibleProductsQuery(
  baseOptions: Apollo.QueryHookOptions<VisibleProductsQuery, VisibleProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VisibleProductsQuery, VisibleProductsQueryVariables>(
    VisibleProductsDocument,
    options
  );
}
export function useVisibleProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VisibleProductsQuery, VisibleProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VisibleProductsQuery, VisibleProductsQueryVariables>(
    VisibleProductsDocument,
    options
  );
}
export type VisibleProductsQueryHookResult = ReturnType<typeof useVisibleProductsQuery>;
export type VisibleProductsLazyQueryHookResult = ReturnType<typeof useVisibleProductsLazyQuery>;
export type VisibleProductsQueryResult = Apollo.QueryResult<
  VisibleProductsQuery,
  VisibleProductsQueryVariables
>;
export const UserDocument = gql`
  query User($siteType: SiteType!) {
    user(siteType: $siteType) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CountriesDocument = gql`
  query Countries($siteType: SiteType!, $locale: Locale!) {
    countries(siteType: $siteType, locale: $locale) {
      ...CountryFields
    }
  }
  ${CountryFieldsFragmentDoc}
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CountryDocument = gql`
  query Country($siteType: SiteType!, $isocode: IsoCode!, $locale: Locale!) {
    country(siteType: $siteType, isocode: $isocode, locale: $locale) {
      ...CountryFields
    }
  }
  ${CountryFieldsFragmentDoc}
`;

/**
 * __useCountryQuery__
 *
 * To run a query within a React component, call `useCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      isocode: // value for 'isocode'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCountryQuery(
  baseOptions: Apollo.QueryHookOptions<CountryQuery, CountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryQuery, CountryQueryVariables>(CountryDocument, options);
}
export function useCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountryQuery, CountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryQuery, CountryQueryVariables>(CountryDocument, options);
}
export type CountryQueryHookResult = ReturnType<typeof useCountryQuery>;
export type CountryLazyQueryHookResult = ReturnType<typeof useCountryLazyQuery>;
export type CountryQueryResult = Apollo.QueryResult<CountryQuery, CountryQueryVariables>;
export const NavigationDocument = gql`
  query Navigation($siteType: SiteType!, $navigationType: NavigationTypeParam!, $locale: Locale!) {
    navigation(siteType: $siteType, navigationType: $navigationType, locale: $locale) {
      ...NavigationFields
    }
  }
  ${NavigationFieldsFragmentDoc}
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      navigationType: // value for 'navigationType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const SocialMediaChannelsDocument = gql`
  query SocialMediaChannels($siteType: SiteType!, $locale: Locale!) {
    socialMediaChannels(siteType: $siteType, locale: $locale) {
      ...SocialMediaChannelFields
    }
  }
  ${SocialMediaChannelFieldsFragmentDoc}
`;

/**
 * __useSocialMediaChannelsQuery__
 *
 * To run a query within a React component, call `useSocialMediaChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialMediaChannelsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSocialMediaChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<SocialMediaChannelsQuery, SocialMediaChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SocialMediaChannelsQuery, SocialMediaChannelsQueryVariables>(
    SocialMediaChannelsDocument,
    options
  );
}
export function useSocialMediaChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialMediaChannelsQuery,
    SocialMediaChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SocialMediaChannelsQuery, SocialMediaChannelsQueryVariables>(
    SocialMediaChannelsDocument,
    options
  );
}
export type SocialMediaChannelsQueryHookResult = ReturnType<typeof useSocialMediaChannelsQuery>;
export type SocialMediaChannelsLazyQueryHookResult = ReturnType<
  typeof useSocialMediaChannelsLazyQuery
>;
export type SocialMediaChannelsQueryResult = Apollo.QueryResult<
  SocialMediaChannelsQuery,
  SocialMediaChannelsQueryVariables
>;
export const PaymentMethodsDocument = gql`
  query PaymentMethods($siteType: SiteType!, $locale: Locale!) {
    paymentMethods(siteType: $siteType, locale: $locale) {
      ...PaymentMethodsFields
    }
  }
  ${PaymentMethodsFieldsFragmentDoc}
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options
  );
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options
  );
}
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const MetaInfoPaymentMethodsDocument = gql`
  query MetaInfoPaymentMethods($siteType: SiteType!, $locale: Locale!) {
    paymentMethods(siteType: $siteType, locale: $locale) {
      code
      name
      target
      url
    }
  }
`;

/**
 * __useMetaInfoPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useMetaInfoPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaInfoPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaInfoPaymentMethodsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMetaInfoPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MetaInfoPaymentMethodsQuery,
    MetaInfoPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetaInfoPaymentMethodsQuery, MetaInfoPaymentMethodsQueryVariables>(
    MetaInfoPaymentMethodsDocument,
    options
  );
}
export function useMetaInfoPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetaInfoPaymentMethodsQuery,
    MetaInfoPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetaInfoPaymentMethodsQuery, MetaInfoPaymentMethodsQueryVariables>(
    MetaInfoPaymentMethodsDocument,
    options
  );
}
export type MetaInfoPaymentMethodsQueryHookResult = ReturnType<
  typeof useMetaInfoPaymentMethodsQuery
>;
export type MetaInfoPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useMetaInfoPaymentMethodsLazyQuery
>;
export type MetaInfoPaymentMethodsQueryResult = Apollo.QueryResult<
  MetaInfoPaymentMethodsQuery,
  MetaInfoPaymentMethodsQueryVariables
>;
export const UspsDocument = gql`
  query Usps($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    usps(siteType: $siteType, code: $code, locale: $locale) {
      ...UspsFields
    }
  }
  ${UspsFieldsFragmentDoc}
`;

/**
 * __useUspsQuery__
 *
 * To run a query within a React component, call `useUspsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUspsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUspsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUspsQuery(baseOptions: Apollo.QueryHookOptions<UspsQuery, UspsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UspsQuery, UspsQueryVariables>(UspsDocument, options);
}
export function useUspsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UspsQuery, UspsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UspsQuery, UspsQueryVariables>(UspsDocument, options);
}
export type UspsQueryHookResult = ReturnType<typeof useUspsQuery>;
export type UspsLazyQueryHookResult = ReturnType<typeof useUspsLazyQuery>;
export type UspsQueryResult = Apollo.QueryResult<UspsQuery, UspsQueryVariables>;
export const SizeMeasurementsDocument = gql`
  query SizeMeasurements($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    sizeMeasurements(siteType: $siteType, code: $code, locale: $locale) {
      ...SizeMeasurementsFields
    }
  }
  ${SizeMeasurementsFieldsFragmentDoc}
`;

/**
 * __useSizeMeasurementsQuery__
 *
 * To run a query within a React component, call `useSizeMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSizeMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSizeMeasurementsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSizeMeasurementsQuery(
  baseOptions: Apollo.QueryHookOptions<SizeMeasurementsQuery, SizeMeasurementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SizeMeasurementsQuery, SizeMeasurementsQueryVariables>(
    SizeMeasurementsDocument,
    options
  );
}
export function useSizeMeasurementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SizeMeasurementsQuery, SizeMeasurementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SizeMeasurementsQuery, SizeMeasurementsQueryVariables>(
    SizeMeasurementsDocument,
    options
  );
}
export type SizeMeasurementsQueryHookResult = ReturnType<typeof useSizeMeasurementsQuery>;
export type SizeMeasurementsLazyQueryHookResult = ReturnType<typeof useSizeMeasurementsLazyQuery>;
export type SizeMeasurementsQueryResult = Apollo.QueryResult<
  SizeMeasurementsQuery,
  SizeMeasurementsQueryVariables
>;
export const SizeGuidesDocument = gql`
  query SizeGuides($siteType: SiteType!, $locale: Locale!) {
    sizeGuides(siteType: $siteType, locale: $locale) {
      ...SizeGuidesFields
    }
  }
  ${SizeGuidesFieldsFragmentDoc}
`;

/**
 * __useSizeGuidesQuery__
 *
 * To run a query within a React component, call `useSizeGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSizeGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSizeGuidesQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSizeGuidesQuery(
  baseOptions: Apollo.QueryHookOptions<SizeGuidesQuery, SizeGuidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SizeGuidesQuery, SizeGuidesQueryVariables>(SizeGuidesDocument, options);
}
export function useSizeGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SizeGuidesQuery, SizeGuidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SizeGuidesQuery, SizeGuidesQueryVariables>(
    SizeGuidesDocument,
    options
  );
}
export type SizeGuidesQueryHookResult = ReturnType<typeof useSizeGuidesQuery>;
export type SizeGuidesLazyQueryHookResult = ReturnType<typeof useSizeGuidesLazyQuery>;
export type SizeGuidesQueryResult = Apollo.QueryResult<SizeGuidesQuery, SizeGuidesQueryVariables>;
export const LocalStoreStockDocument = gql`
  query LocalStoreStock($siteType: SiteType!, $code: Code!, $isocode: IsoCode!, $locale: Locale!) {
    localStoreStock(siteType: $siteType, code: $code, isocode: $isocode, locale: $locale) {
      ...StoreFields
    }
  }
  ${StoreFieldsFragmentDoc}
`;

/**
 * __useLocalStoreStockQuery__
 *
 * To run a query within a React component, call `useLocalStoreStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalStoreStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalStoreStockQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      isocode: // value for 'isocode'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLocalStoreStockQuery(
  baseOptions: Apollo.QueryHookOptions<LocalStoreStockQuery, LocalStoreStockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocalStoreStockQuery, LocalStoreStockQueryVariables>(
    LocalStoreStockDocument,
    options
  );
}
export function useLocalStoreStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocalStoreStockQuery, LocalStoreStockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocalStoreStockQuery, LocalStoreStockQueryVariables>(
    LocalStoreStockDocument,
    options
  );
}
export type LocalStoreStockQueryHookResult = ReturnType<typeof useLocalStoreStockQuery>;
export type LocalStoreStockLazyQueryHookResult = ReturnType<typeof useLocalStoreStockLazyQuery>;
export type LocalStoreStockQueryResult = Apollo.QueryResult<
  LocalStoreStockQuery,
  LocalStoreStockQueryVariables
>;
export const CommonDataDocument = gql`
  query CommonData($siteType: SiteType!, $locale: Locale!) {
    commonData(siteType: $siteType, locale: $locale) {
      ...CommonDataFields
    }
  }
  ${CommonDataFieldsFragmentDoc}
`;

/**
 * __useCommonDataQuery__
 *
 * To run a query within a React component, call `useCommonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonDataQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCommonDataQuery(
  baseOptions: Apollo.QueryHookOptions<CommonDataQuery, CommonDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommonDataQuery, CommonDataQueryVariables>(CommonDataDocument, options);
}
export function useCommonDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommonDataQuery, CommonDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommonDataQuery, CommonDataQueryVariables>(
    CommonDataDocument,
    options
  );
}
export type CommonDataQueryHookResult = ReturnType<typeof useCommonDataQuery>;
export type CommonDataLazyQueryHookResult = ReturnType<typeof useCommonDataLazyQuery>;
export type CommonDataQueryResult = Apollo.QueryResult<CommonDataQuery, CommonDataQueryVariables>;
export const RecommendationsDocument = gql`
  query Recommendations($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    recommendations(siteType: $siteType, code: $code, locale: $locale) {
      ...RecommendationsFields
    }
  }
  ${RecommendationsFieldsFragmentDoc}
`;

/**
 * __useRecommendationsQuery__
 *
 * To run a query within a React component, call `useRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<RecommendationsQuery, RecommendationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecommendationsQuery, RecommendationsQueryVariables>(
    RecommendationsDocument,
    options
  );
}
export function useRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecommendationsQuery, RecommendationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecommendationsQuery, RecommendationsQueryVariables>(
    RecommendationsDocument,
    options
  );
}
export type RecommendationsQueryHookResult = ReturnType<typeof useRecommendationsQuery>;
export type RecommendationsLazyQueryHookResult = ReturnType<typeof useRecommendationsLazyQuery>;
export type RecommendationsQueryResult = Apollo.QueryResult<
  RecommendationsQuery,
  RecommendationsQueryVariables
>;
export const ProductsDocument = gql`
  query Products($input: ProductsInput) {
    products(input: $input) {
      ...ProductsProductFields
    }
  }
  ${ProductsProductFieldsFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ShippingEstimatesDocument = gql`
  query ShippingEstimates(
    $siteType: SiteType!
    $locale: Locale!
    $code: Code!
    $gridValue1: GridValue
    $gridValue2: GridValue
  ) {
    shippingEstimates(
      siteType: $siteType
      locale: $locale
      code: $code
      gridValue1: $gridValue1
      gridValue2: $gridValue2
    ) {
      ...ShippingEstimatesFields
    }
  }
  ${ShippingEstimatesFieldsFragmentDoc}
`;

/**
 * __useShippingEstimatesQuery__
 *
 * To run a query within a React component, call `useShippingEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingEstimatesQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *      code: // value for 'code'
 *      gridValue1: // value for 'gridValue1'
 *      gridValue2: // value for 'gridValue2'
 *   },
 * });
 */
export function useShippingEstimatesQuery(
  baseOptions: Apollo.QueryHookOptions<ShippingEstimatesQuery, ShippingEstimatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShippingEstimatesQuery, ShippingEstimatesQueryVariables>(
    ShippingEstimatesDocument,
    options
  );
}
export function useShippingEstimatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShippingEstimatesQuery, ShippingEstimatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShippingEstimatesQuery, ShippingEstimatesQueryVariables>(
    ShippingEstimatesDocument,
    options
  );
}
export type ShippingEstimatesQueryHookResult = ReturnType<typeof useShippingEstimatesQuery>;
export type ShippingEstimatesLazyQueryHookResult = ReturnType<typeof useShippingEstimatesLazyQuery>;
export type ShippingEstimatesQueryResult = Apollo.QueryResult<
  ShippingEstimatesQuery,
  ShippingEstimatesQueryVariables
>;
export const CategoryPathsDocument = gql`
  query CategoryPaths($siteType: SiteType!, $locale: Locale!) {
    categoryPaths(siteType: $siteType, locale: $locale) {
      ...CategoryPathsFields
    }
  }
  ${CategoryPathsFieldsFragmentDoc}
`;

/**
 * __useCategoryPathsQuery__
 *
 * To run a query within a React component, call `useCategoryPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryPathsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCategoryPathsQuery(
  baseOptions: Apollo.QueryHookOptions<CategoryPathsQuery, CategoryPathsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryPathsQuery, CategoryPathsQueryVariables>(
    CategoryPathsDocument,
    options
  );
}
export function useCategoryPathsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoryPathsQuery, CategoryPathsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryPathsQuery, CategoryPathsQueryVariables>(
    CategoryPathsDocument,
    options
  );
}
export type CategoryPathsQueryHookResult = ReturnType<typeof useCategoryPathsQuery>;
export type CategoryPathsLazyQueryHookResult = ReturnType<typeof useCategoryPathsLazyQuery>;
export type CategoryPathsQueryResult = Apollo.QueryResult<
  CategoryPathsQuery,
  CategoryPathsQueryVariables
>;
export const CategoryDetailsDocument = gql`
  query CategoryDetails($siteType: SiteType!, $locale: Locale!, $path: CategoryPath!) {
    categoryDetails(siteType: $siteType, locale: $locale, path: $path) {
      ...CategoryDetailsFields
    }
  }
  ${CategoryDetailsFieldsFragmentDoc}
`;

/**
 * __useCategoryDetailsQuery__
 *
 * To run a query within a React component, call `useCategoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryDetailsQuery({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useCategoryDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<CategoryDetailsQuery, CategoryDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryDetailsQuery, CategoryDetailsQueryVariables>(
    CategoryDetailsDocument,
    options
  );
}
export function useCategoryDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoryDetailsQuery, CategoryDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryDetailsQuery, CategoryDetailsQueryVariables>(
    CategoryDetailsDocument,
    options
  );
}
export type CategoryDetailsQueryHookResult = ReturnType<typeof useCategoryDetailsQuery>;
export type CategoryDetailsLazyQueryHookResult = ReturnType<typeof useCategoryDetailsLazyQuery>;
export type CategoryDetailsQueryResult = Apollo.QueryResult<
  CategoryDetailsQuery,
  CategoryDetailsQueryVariables
>;
export const AddToCartDocument = gql`
  mutation AddToCart($siteType: SiteType!, $code: Code!, $locale: Locale!) {
    addToCart(siteType: $siteType, code: $code, locale: $locale) {
      ...CartMutationFields
    }
  }
  ${CartMutationFieldsFragmentDoc}
`;
export type AddToCartMutationFn = Apollo.MutationFunction<
  AddToCartMutation,
  AddToCartMutationVariables
>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAddToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(
    AddToCartDocument,
    options
  );
}
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<
  AddToCartMutation,
  AddToCartMutationVariables
>;
export const RemoveFromCartDocument = gql`
  mutation RemoveFromCart($siteType: SiteType!, $entryNumber: Int!, $locale: Locale!) {
    removeFromCart(siteType: $siteType, entryNumber: $entryNumber, locale: $locale) {
      ...CartMutationFields
    }
  }
  ${CartMutationFieldsFragmentDoc}
`;
export type RemoveFromCartMutationFn = Apollo.MutationFunction<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>;

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      entryNumber: // value for 'entryNumber'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useRemoveFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(
    RemoveFromCartDocument,
    options
  );
}
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>;
export type RemoveFromCartMutationResult = Apollo.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>;
export const AddToWishlistDocument = gql`
  mutation AddToWishlist(
    $siteType: SiteType!
    $code: Code!
    $locale: Locale!
    $csrfToken: CSRFToken!
  ) {
    addToWishlist(siteType: $siteType, code: $code, locale: $locale, csrfToken: $csrfToken)
  }
`;
export type AddToWishlistMutationFn = Apollo.MutationFunction<
  AddToWishlistMutation,
  AddToWishlistMutationVariables
>;

/**
 * __useAddToWishlistMutation__
 *
 * To run a mutation, you first call `useAddToWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWishlistMutation, { data, loading, error }] = useAddToWishlistMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *      csrfToken: // value for 'csrfToken'
 *   },
 * });
 */
export function useAddToWishlistMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToWishlistMutation, AddToWishlistMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToWishlistMutation, AddToWishlistMutationVariables>(
    AddToWishlistDocument,
    options
  );
}
export type AddToWishlistMutationHookResult = ReturnType<typeof useAddToWishlistMutation>;
export type AddToWishlistMutationResult = Apollo.MutationResult<AddToWishlistMutation>;
export type AddToWishlistMutationOptions = Apollo.BaseMutationOptions<
  AddToWishlistMutation,
  AddToWishlistMutationVariables
>;
export const RemoveFromWishlistDocument = gql`
  mutation RemoveFromWishlist(
    $siteType: SiteType!
    $code: Code!
    $locale: Locale!
    $csrfToken: CSRFToken!
  ) {
    removeFromWishlist(siteType: $siteType, code: $code, locale: $locale, csrfToken: $csrfToken)
  }
`;
export type RemoveFromWishlistMutationFn = Apollo.MutationFunction<
  RemoveFromWishlistMutation,
  RemoveFromWishlistMutationVariables
>;

/**
 * __useRemoveFromWishlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWishlistMutation, { data, loading, error }] = useRemoveFromWishlistMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      code: // value for 'code'
 *      locale: // value for 'locale'
 *      csrfToken: // value for 'csrfToken'
 *   },
 * });
 */
export function useRemoveFromWishlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromWishlistMutation,
    RemoveFromWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromWishlistMutation, RemoveFromWishlistMutationVariables>(
    RemoveFromWishlistDocument,
    options
  );
}
export type RemoveFromWishlistMutationHookResult = ReturnType<typeof useRemoveFromWishlistMutation>;
export type RemoveFromWishlistMutationResult = Apollo.MutationResult<RemoveFromWishlistMutation>;
export type RemoveFromWishlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromWishlistMutation,
  RemoveFromWishlistMutationVariables
>;
export const ApplyVoucherDocument = gql`
  mutation ApplyVoucher(
    $siteType: SiteType!
    $voucher: String!
    $locale: Locale!
    $utmSource: String
  ) {
    applyVoucher(siteType: $siteType, voucher: $voucher, locale: $locale, utmSource: $utmSource) {
      ...VoucherApplyResultFields
    }
  }
  ${VoucherApplyResultFieldsFragmentDoc}
`;
export type ApplyVoucherMutationFn = Apollo.MutationFunction<
  ApplyVoucherMutation,
  ApplyVoucherMutationVariables
>;

/**
 * __useApplyVoucherMutation__
 *
 * To run a mutation, you first call `useApplyVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyVoucherMutation, { data, loading, error }] = useApplyVoucherMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      voucher: // value for 'voucher'
 *      locale: // value for 'locale'
 *      utmSource: // value for 'utmSource'
 *   },
 * });
 */
export function useApplyVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<ApplyVoucherMutation, ApplyVoucherMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyVoucherMutation, ApplyVoucherMutationVariables>(
    ApplyVoucherDocument,
    options
  );
}
export type ApplyVoucherMutationHookResult = ReturnType<typeof useApplyVoucherMutation>;
export type ApplyVoucherMutationResult = Apollo.MutationResult<ApplyVoucherMutation>;
export type ApplyVoucherMutationOptions = Apollo.BaseMutationOptions<
  ApplyVoucherMutation,
  ApplyVoucherMutationVariables
>;
export const ExponeaConsentEventDocument = gql`
  mutation ExponeaConsentEvent(
    $siteType: SiteType!
    $locale: Locale!
    $cookie: String!
    $subscriberKey: String
    $message: String!
    $action: ExponeaConsentAction!
  ) {
    exponeaConsentEvent(
      siteType: $siteType
      locale: $locale
      cookie: $cookie
      subscriberKey: $subscriberKey
      message: $message
      action: $action
    )
  }
`;
export type ExponeaConsentEventMutationFn = Apollo.MutationFunction<
  ExponeaConsentEventMutation,
  ExponeaConsentEventMutationVariables
>;

/**
 * __useExponeaConsentEventMutation__
 *
 * To run a mutation, you first call `useExponeaConsentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExponeaConsentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exponeaConsentEventMutation, { data, loading, error }] = useExponeaConsentEventMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *      cookie: // value for 'cookie'
 *      subscriberKey: // value for 'subscriberKey'
 *      message: // value for 'message'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useExponeaConsentEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExponeaConsentEventMutation,
    ExponeaConsentEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExponeaConsentEventMutation, ExponeaConsentEventMutationVariables>(
    ExponeaConsentEventDocument,
    options
  );
}
export type ExponeaConsentEventMutationHookResult = ReturnType<
  typeof useExponeaConsentEventMutation
>;
export type ExponeaConsentEventMutationResult = Apollo.MutationResult<ExponeaConsentEventMutation>;
export type ExponeaConsentEventMutationOptions = Apollo.BaseMutationOptions<
  ExponeaConsentEventMutation,
  ExponeaConsentEventMutationVariables
>;
export const ExponeaCustomerAttributesDocument = gql`
  mutation ExponeaCustomerAttributes(
    $siteType: SiteType!
    $locale: Locale!
    $cookie: String!
    $subscriberKey: String
    $type: ExponeaTypeAttribute!
    $category: ExponeaCategoryAttribute!
  ) {
    exponeaCustomerAttributes(
      siteType: $siteType
      locale: $locale
      cookie: $cookie
      subscriberKey: $subscriberKey
      type: $type
      category: $category
    ) {
      ...ExponeaCustomerAttributesResponseFields
    }
  }
  ${ExponeaCustomerAttributesResponseFieldsFragmentDoc}
`;
export type ExponeaCustomerAttributesMutationFn = Apollo.MutationFunction<
  ExponeaCustomerAttributesMutation,
  ExponeaCustomerAttributesMutationVariables
>;

/**
 * __useExponeaCustomerAttributesMutation__
 *
 * To run a mutation, you first call `useExponeaCustomerAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExponeaCustomerAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exponeaCustomerAttributesMutation, { data, loading, error }] = useExponeaCustomerAttributesMutation({
 *   variables: {
 *      siteType: // value for 'siteType'
 *      locale: // value for 'locale'
 *      cookie: // value for 'cookie'
 *      subscriberKey: // value for 'subscriberKey'
 *      type: // value for 'type'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useExponeaCustomerAttributesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExponeaCustomerAttributesMutation,
    ExponeaCustomerAttributesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExponeaCustomerAttributesMutation,
    ExponeaCustomerAttributesMutationVariables
  >(ExponeaCustomerAttributesDocument, options);
}
export type ExponeaCustomerAttributesMutationHookResult = ReturnType<
  typeof useExponeaCustomerAttributesMutation
>;
export type ExponeaCustomerAttributesMutationResult =
  Apollo.MutationResult<ExponeaCustomerAttributesMutation>;
export type ExponeaCustomerAttributesMutationOptions = Apollo.BaseMutationOptions<
  ExponeaCustomerAttributesMutation,
  ExponeaCustomerAttributesMutationVariables
>;
